:root {
  --gold-color: #B8860B;
  --light-gold-color: #af9a64;
  --brown-color: #8b4513;
}

.js-select-2 {
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 16px;
  font-weight: 400;
  color: var(--paragraph-color);
  border: 1px solid var(--input-color);
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.01);
          box-shadow: 0 0 10px rgba(255, 255, 255, 0.01);
  -webkit-transition: 300ms;
  transition: 300ms;
  padding: 0 30px 0 15px;
}

.text-gold {
  color: #B8860B;
}


.hide-scrollbar{
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-hover {
  pointer-events: none;
}

.search-container {
  display: flex;
  align-items: center;
}

.label {
  /* margin-right: 15px; */
  color: var(--brown-color); /* Brownish color */
}

.custom-search-box {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #2f2f2f;
  border-radius: 25px;
  padding: 5px 10px;
  background-color: white;
  width: 300px;
  overflow: hidden;
}

.custom-search-box input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 5px;
  font-size: 14px;
}

.custom-search-box .search-btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px;
}

.custom-search-box .search-btn i {
  font-size: 14px;
  color: black;
}

.custom-search-box-icon {
  position: absolute;
  right: 1rem;
  top: 0.6rem;
}
/* Optional for font-awesome search icon */


.custom-input-style {
  border: 1px solid var(--brown-color);
}


.custom-input-style::placeholder {
  color: var(--light-gold-color);
}


.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  width: 100%;
  margin: 20px auto;
}

.profile-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 15px;
}

.profile-name {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.profile-description {
  font-family: "vardana", sans-serif;
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

.custom-slick-dots.custom-dots {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  display: flex !important;
  justify-content: center;
  transform: translateX(-50%);
  padding: 0;
  margin: 0;
}

.custom-dot {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  display: flex !important;
}

.custom-slick-dots.custom-dots li.slick-active .custom-dot {
  background-color: #8b4512;
}

.custom-slick-dots.custom-dots li {
  margin: 20px 5px;
  list-style: none;
}

/* --------------------hotel---------------- */

.hotel-btn-wrapper {
  color: var(--paragraph-color);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--body-font);
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  line-height: 34px;
  padding: 7px 35px;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}

.hotel-btn {
  background: #fff;
  color: black;
  padding: 5px 15px;
  border-radius: 4rem;
  width: 10rem;
}

.hotel-btn:hover {
  color: var(--btn-txt-color);
  border: 1px solid var(--btn-txt-color);
}

.hotel-btn.active {
  color: var(--btn-txt-color);
  border: 1px solid var(--btn-txt-color);
}


.custom-details-contents-header-thumb {
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding: 0.5rem;
}
.custom-details-contents-header-thumb img {
  border-radius: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  object-fit: cover;
}


.custom-slick-height{
  height: 24rem;
}


.custom-text {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #343434 !important;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .custom-text {
    color: white !important;
  }

  .hotel-btn-wrapper{
    padding: 0px 5px !important;
    font-size: 14px;
  }


  .hotel-btn{
    padding: 3px 5px;
    width:8rem;
  }
}

@media screen and (max-width: 440px) {

  .hotel-btn{
    padding: 3px 5px;
    width:3rem;
  }

  .hotel-btn.active{
    border: none;
    text-decoration: underline;
  }
}


/* ------------------- HOTEL LANDING PAGE BANNER CSS --------------------- */
.eco-hotel-container {
  font-family: Arial, sans-serif;
}

.eco-hotel-hero {
  display: flex;
  align-items: center;
  background: #f4f4f4;
}

.scale-up {
  scale: 1.02;
}

.eco-hotel-image {
  width: 70%;
  position: relative;
}

.eco-hotel-image img {
  max-width: 100%;
  height: auto;
}

.eco-hotel-content {
  width: 30%;
  padding-left: 20px;
}

.eco-hotel-content h1 {
  font-size: 40px;
  color: #8B4513;
}

.eco-hotel-content p {
  font-size: 16px;
  margin: 10px 0;
}

.eco-hotel-search {
  background-color: #fff;
  padding: 20px 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 1100px;
}

.search-item {
  display: flex;
  align-items: center;
  gap:5px;
  font-size: 16px;
  background-color: #f9f9f9;
  border-radius: 25px;
  padding: 10px 20px;
  white-space: nowrap;
}

.divider {
  width: 2px;
  height: 50px;
  background-color: #ccc;
  margin: 0 20px;
}

.search-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #fff;
  border: 2px solid #8B4513;
  border-radius: 25px;
  padding: 10px 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #8B4513;
  color: #fff;
}

/* Tablet */
@media (max-width: 1024px) {
  .eco-hotel-hero {
    flex-direction: column;
    text-align: center;
  }

  .eco-hotel-image, .eco-hotel-content {
    width: 100%;
    padding-left: 0;
  }

  .eco-hotel-content h1 {
    font-size: 32px;
  }

  .eco-hotel-content p {
    font-size: 14px;
  }

  .eco-hotel-search {
    padding: 20px;
  }

  .search-bar {
    flex-direction: column;
  }

  .search-item {
    width: 100%;
    margin: 5px 0;
  }

  .divider {
    display: none;
  }

  .search-button {
    width: 100%;
  }
}

/* Mobile */
@media (max-width: 1024px) {
  .eco-hotel-hero {
    flex-direction: column;
    text-align: center;
    position: relative;
  }

  .eco-hotel-image, .eco-hotel-content {
    width: 100%;
  }

  /* Make image cover the entire container */
  .eco-hotel-image img {
    width: 100%;
    height: 15rem;
  }

  /* Overlay dark background on the image */
  .eco-hotel-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 1;
  }

  /* Ensure text is displayed over the image */
  .eco-hotel-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: #fff;
  }

  .eco-hotel-content h1 {
    margin-top: 3rem;
    font-size: 28px;
    color:#cc6820;
  }

  .eco-hotel-content p {
    font-size: 12px;
  }

  .eco-hotel-search {
    padding: 15px;
  }

  .search-bar {
    flex-direction: column;

  }

  .search-item {
    width: 100%;
    margin: 5px 0;
  }

  .search-button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }
}
/* ------------------- HOTEL LANDING PAGE BANNER CSS --------------------- */



.select-container {
  position: relative;
}

.select-container select {
  width: 100%;
  padding-right: 30px; /* Adjust based on icon size */
}

.select-container .select-icon {
  position: absolute;
  right: 10px; /* Adjust based on icon size */
  top: 50%;
  transform: translateY(-50%);
  scale: 1.2;
  pointer-events: none; /* Prevents the icon from blocking select interactions */
}

.select-container select {
  -webkit-appearance: none; /* For Chrome */
  -moz-appearance: none; /* For Firefox */
  appearance: none; /* For other browsers */
}

.img-fixed-size {
  width: 100%;
  height: 200px; /* Adjust the height as needed */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}


.nearby-attraction-thumb {
  width: 100%;        /* Take the full width of the parent */
  height: 20rem;       /* Fixed height of 5rem */
  overflow: hidden;   /* Hide any overflow of the image */
  border-radius: 1rem 1rem 0rem 0rem;
}

.image-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;   /* Ensures the image covers the div entirely */
  object-position: center;  /* Centers the image within the div */
}


.custom-bg-color {
  background-color: var(--section-bg-2);
}

.position-absolute-right-top {
  position: absolute !important;
  right: 0;
  top: -40px;
}


.popup-description {
  display: block;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  z-index: 10;
}

.popup-description p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}


@media screen and (max-width: 768px) {
  .custom-bg-color {
    background-color: white;
  }

  .position-absolute-right-top {
    top: -10px;
  }

  .popup-description{
    left: -5rem !important;
    min-width:350px;
  }
}




/* custom.css */
.active-link {
  color: #FFD700 !important; /* Gold color for active nav */
}

/* Additional styles for links if needed */
.navbar-nav .nav-link {
  color: inherit;
  transition: color 0.3s ease;
}





/* ---------------------- Payment Success ---------------------- */
.payment-success-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to right, #60a5fa, #a78bfa);
}

.payment-success-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  margin: 1rem;
  max-width: 24rem;
  width: 100%;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.icon-wrapper {
  background-color: #d1fae5;
  border-radius: 9999px;
  padding: 1rem;
}

.success-icon {
  color: #10b981;
  width: 4rem;
  height: 4rem;
}

.failure-icon {
  color: #e00000;
  width: 4rem;
  height: 4rem;
}


.title {
  font-size: 1.875rem;
  font-weight: bold;
  color: #1f2937;
}

.message {
  text-align: center;
  color: #4b5563;
}

.sub-message {
  font-size: 0.875rem;
  color: #6b7280;
}

.primary-button {
  background-color: #3b82f6;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  transition: all 0.3s ease-in-out;
}

.primary-button:hover {
  background-color: #2563eb;
  transform: scale(1.05);
}

.secondary-button {
  color: #3b82f6;
  font-weight: 600;
  transition: color 0.3s ease-in-out;
}

.secondary-button:hover {
  color: #2563eb;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.animate-fade-in-up {
  animation: fadeInUp 0.6s ease-out;
}

.animate-scale-in {
  animation: scaleIn 0.5s ease-out 0.3s both;
}


.label-light {
  /* margin-right: 15px; */
  color: #b57c54; /* Brownish color */
}

/* ---------------------- Custom Buttons ---------------------- */
.custom-small-btn {
  padding: 4px 10px;
  font-size: 14px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid #8b4513;
  color: #8b4513;
  &:hover {
    background-color: #8a4513;
    color:white;
  }
}
