/*
Theme Name: Newspaper Magazine Bussiness
Theme URI: 
Author: 
Author URI: 
Description: Newspaper Magazine is a business website template
Version: 1.0.0
*/
/*  */
/* Media Queries */
/*--------------------------------------------------------------
    Google Fonts
--------------------------------------------------------------*/
/*  */
/* Media Queries */
/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
:root {
  --main-color-one: #8b4513;
  --main-color-one-rgb: 30, 132, 254;
  --secondary-color: #8b4513;
  --sun-color-one:#afd127;
  --btn-txt2-color:#FFD700;
  --btn-txt-color:#B8860b;
  --sun-color-two:#1E84FE;
  --secondary-color-rgb: 255, 140, 50;
  --banner-color-one: #347AB6;
  --main-color-two-rgb:52, 122, 182;
  --section-bg-1: #F6F7F8;
  --section-bg-2: #F2F4F7;
  --footer-bg-1: #27282B;
  --footer-bg-2: #8b4513;
  --copyright-bg-1: #323336;
  --heading-color: #1D2635;
  --nav-color:#F2F4F7;
  --body-color: #667085;
  --gray-color: #f8f8f8;
  --input-color: #EAECF0;
  --paragraph-color: #7f3224;
  --light-color: #999;
  --success-color: #00C897;
  --success-color-rgb: 0, 200, 151;
  --heading-font: "Plus Jakarta Sans", sans-serif;
  --body-font: "Manrope", sans-serif;
  --jakarta-font: "Plus Jakarta Sans", sans-serif;
  --manrope-font: "Manrope", sans-serif;
  --verdana-font: "Verdana", sans-serif;
}


.jakarta {
  font-family: var(--jakarta-font) !important;
}

.verdana {
  font-family: var(--verdana-font) !important;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}

body {
  margin: 0;
  color: var(--body-color);
  overflow-x: hidden;
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
}
@media only screen and (max-width: 480px) {
  body {
    font-size: 16px;
  }
}
@media only screen and (max-width: 375px) {
  body {
    font-size: 16px;
  }
}

h1 {
  font-size: 60px;
  line-height: 1.0833333333;
}

h2 {
  font-size: 36px;
  line-height: 1.4444444444;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  h2 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 575.98px) {
  h2 {
    font-size: 27px;
  }
}
@media only screen and (max-width: 480px) {
  h2 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 375px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-size: 28px;
  line-height: 1.0833333333;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  h3 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 575.98px) {
  h3 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 480px) {
  h3 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 375px) {
  h3 {
    font-size: 22px;
  }
}

h4 {
  font-size: 24px;
  line-height: 1.0833333333;
}
@media only screen and (max-width: 575.98px) {
  h4 {
    font-size: 23px;
  }
}
@media only screen and (max-width: 375px) {
  h4 {
    font-size: 21px;
  }
}

h5 {
  font-size: 20px;
  line-height: 1.2380952381;
}
@media only screen and (max-width: 375px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-size: 18px;
  line-height: 1.2380952381;
}
@media only screen and (max-width: 375px) {
  h6 {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
  margin: 0;
  -webkit-transition: 300ms;
  transition: 300ms;
}

p {
  /* color: var(--light-color); */
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-bottom: 0;
  line-height: 26px;
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

span {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  color: var(--extra-light-color);
  -webkit-transition: 300ms;
  transition: 300ms;
  font-family: var(--verdana-font);
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: inherit;
}

pre {
  word-break: break-word;
}

a i {
  padding: 0 2px;
  font-family: var(--verdana-font);
}

img {
  max-width: 100%;
}

.logo{
  max-width:300px;
}

.list-style-none {
  margin: 0;
  padding: 0;
  list-style: none;
}

ol {
  counter-reset: counter;
  padding-left: 0;
}

ol li:before {
  counter-increment: counter;
  content: counter(counter);
  font-weight: 500;
  margin-right: 10px;
}

span {
  display: inline-block;
}

.desktop-center {
  text-align: center;
}

.row-destination {

  margin-right: calc(5* var(--bs-gutter-x));
  margin-left: calc(5* var(--bs-gutter-x));
}

.form--control {
  width: 100%;
}

/* Common Style */
.margin-0 {
  margin: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.radius-parcent-50 {
  border-radius: 50%;
}

.radius-50 {
  border-radius: 50px;
}

.radius-40 {
  border-radius: 40px;
}

.radius-35 {
  border-radius: 35px;
}

.radius-30 {
  border-radius: 30px;
}

.radius-20 {
  border-radius: 20px;
}

.radius-15 {
  border-radius: 15px;
}

.radius-10 {
  border-radius: 10px;
}

.radius-5 {
  border-radius: 5px;
}

.radius-0 {
  border-radius: 0px !important;
}

.no-shadow {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.single-border-top {
  border-top: 1px solid rgba(221, 221, 221, 0.3);
}

.single-border {
  border: 1px solid rgba(221, 221, 221, 0.3);
}

.color-light {
  color: var(--light-color) !important;
}

.color-extra-light {
  color: var(--extra-light-color) !important;
}

.color-heading {
  color: var(--heading-color);
}

.bg-gray {
  background-color: var(--gray-color);
}

.section-bg-1 {
  background-color: var(--section-bg-1);
}

.section-bg-2 {
  background-color: var(--section-bg-2);
}

.footer-bg-1 {
  background-color: var(--footer-bg-1);
}

.footer-bg-2 {
  background-color: var(--footer-bg-2);
}

.copyright-bg-1 {
  background-color: var(--copyright-bg-1);
}

.color-one {
  color: var(--main-color-one);
}

.bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.no-margin {
  margin: 0;
}

.lazyloads {
  display: block;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.tab-content-item {
  display: none;
}

.tab-content-item.active {
  display: block;
  -webkit-animation: 1s fade-effects;
          animation: 1s fade-effects;
}

@-webkit-keyframes fade-effects {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes fade-effects {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
/*--------------------------------------------------------------
# Global
--------------------------------------------------------------*/
.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 16px !important;
}

.fs-15 {
  font-size: 16px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-27 {
  font-size: 27px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-46 {
  font-size: 46px !important;
}

.fs-65 {
  font-size: 65px !important;
}

/*--------------------------------------------------------------
# Margin and Padding
--------------------------------------------------------------*/
/*-- Margin Top --*/
.mat-5 {
  margin-top: 5px;
}

.mat-10 {
  margin-top: 10px;
}

.mat-15 {
  margin-top: 15px;
}

.mat-20 {
  margin-top: 20px;
}

.mat-25 {
  margin-top: 25px;
}

.mat-30 {
  margin-top: 30px;
}

.mat-35 {
  margin-top: 35px;
}

.mat-40 {
  margin-top: 40px;
}

.mat-45 {
  margin-top: 45px;
}

.mat-50 {
  margin-top: 50px;
}

.mat-55 {
  margin-top: 55px;
}

.mat-60 {
  margin-top: 60px;
}

.mat-65 {
  margin-top: 65px;
}

.mat-70 {
  margin-top: 70px;
}

.mat-75 {
  margin-top: 75px;
}

.mat-80 {
  margin-top: 80px;
}

.mat-85 {
  margin-top: 85px;
}

.mat-90 {
  margin-top: 90px;
}

.mat-95 {
  margin-top: 95px;
}

.mat-100 {
  margin-top: 100px;
}

.mat-105 {
  margin-top: 105px;
}

.mat-110 {
  margin-top: 110px;
}

.mat-115 {
  margin-top: 115px;
}

.mat-120 {
  margin-top: 120px;
}

.mat-125 {
  margin-top: 125px;
}

.mat-130 {
  margin-top: 130px;
}

.mat-135 {
  margin-top: 135px;
}

.mat-140 {
  margin-top: 140px;
}

.mat-145 {
  margin-top: 145px;
}

.mat-150 {
  margin-top: 150px;
}

.mat-155 {
  margin-top: 155px;
}

.mat-160 {
  margin-top: 160px;
}

.mat-165 {
  margin-top: 165px;
}

.mat-170 {
  margin-top: 170px;
}

.mat-175 {
  margin-top: 175px;
}

.mat-180 {
  margin-top: 180px;
}

.mat-185 {
  margin-top: 185px;
}

.mat-190 {
  margin-top: 190px;
}

.mat-195 {
  margin-top: 195px;
}

.mat-200 {
  margin-top: 200px;
}

.mat-205 {
  margin-top: 205px;
}

.mat-210 {
  margin-top: 210px;
}

.mat-215 {
  margin-top: 215px;
}

.mat-220 {
  margin-top: 220px;
}

.mat-225 {
  margin-top: 225px;
}

.mat-230 {
  margin-top: 230px;
}

.mat-235 {
  margin-top: 235px;
}

.mat-240 {
  margin-top: 240px;
}

.mat-245 {
  margin-top: 245px;
}

.mat-250 {
  margin-top: 250px;
}

.mat-255 {
  margin-top: 255px;
}

.mat-260 {
  margin-top: 260px;
}

.mat-265 {
  margin-top: 265px;
}

.mat-270 {
  margin-top: 270px;
}

.mat-275 {
  margin-top: 275px;
}

.mat-280 {
  margin-top: 280px;
}

.mat-285 {
  margin-top: 285px;
}

.mat-290 {
  margin-top: 290px;
}

.mat-295 {
  margin-top: 295px;
}

.mat-300 {
  margin-top: 300px;
}

.mat-305 {
  margin-top: 305px;
}

.mat-310 {
  margin-top: 310px;
}

.mat-315 {
  margin-top: 315px;
}

.mat-320 {
  margin-top: 320px;
}

.mat-325 {
  margin-top: 325px;
}

.mat-330 {
  margin-top: 330px;
}

.mat-335 {
  margin-top: 335px;
}

.mat-340 {
  margin-top: 340px;
}

.mat-345 {
  margin-top: 345px;
}

.mat-350 {
  margin-top: 350px;
}

.mat-355 {
  margin-top: 355px;
}

.mat-360 {
  margin-top: 360px;
}

.mat-365 {
  margin-top: 365px;
}

.mat-370 {
  margin-top: 370px;
}

.mat-375 {
  margin-top: 375px;
}

.mat-380 {
  margin-top: 380px;
}

.mat-385 {
  margin-top: 385px;
}

.mat-390 {
  margin-top: 390px;
}

.mat-395 {
  margin-top: 395px;
}

.mat-400 {
  margin-top: 400px;
}

.mat-405 {
  margin-top: 405px;
}

.mat-410 {
  margin-top: 410px;
}

.mat-415 {
  margin-top: 415px;
}

.mat-420 {
  margin-top: 420px;
}

.mat-425 {
  margin-top: 425px;
}

.mat-430 {
  margin-top: 430px;
}

.mat-435 {
  margin-top: 435px;
}

.mat-440 {
  margin-top: 440px;
}

.mat-445 {
  margin-top: 445px;
}

.mat-450 {
  margin-top: 450px;
}

.mat-455 {
  margin-top: 455px;
}

.mat-460 {
  margin-top: 460px;
}

.mat-465 {
  margin-top: 465px;
}

.mat-470 {
  margin-top: 470px;
}

.mat-475 {
  margin-top: 475px;
}

.mat-480 {
  margin-top: 480px;
}

.mat-485 {
  margin-top: 485px;
}

.mat-490 {
  margin-top: 490px;
}

.mat-495 {
  margin-top: 495px;
}

.mat-500 {
  margin-top: 500px;
}

.mat-505 {
  margin-top: 505px;
}

.mat-510 {
  margin-top: 510px;
}

.mat-515 {
  margin-top: 515px;
}

.mat-520 {
  margin-top: 520px;
}

.mat-525 {
  margin-top: 525px;
}

.mat-530 {
  margin-top: 530px;
}

.mat-535 {
  margin-top: 535px;
}

.mat-540 {
  margin-top: 540px;
}

.mat-545 {
  margin-top: 545px;
}

.mat-550 {
  margin-top: 550px;
}

.mat-555 {
  margin-top: 555px;
}

.mat-560 {
  margin-top: 560px;
}

.mat-565 {
  margin-top: 565px;
}

.mat-570 {
  margin-top: 570px;
}

.mat-575 {
  margin-top: 575px;
}

.mat-580 {
  margin-top: 580px;
}

.mat-585 {
  margin-top: 585px;
}

.mat-590 {
  margin-top: 590px;
}

.mat-595 {
  margin-top: 595px;
}

.mat-600 {
  margin-top: 600px;
}

.mat-605 {
  margin-top: 605px;
}

.mat-610 {
  margin-top: 610px;
}

.mat-615 {
  margin-top: 615px;
}

.mat-620 {
  margin-top: 620px;
}

.mat-625 {
  margin-top: 625px;
}

.mat-630 {
  margin-top: 630px;
}

.mat-635 {
  margin-top: 635px;
}

.mat-640 {
  margin-top: 640px;
}

.mat-645 {
  margin-top: 645px;
}

.mat-650 {
  margin-top: 650px;
}

.mat-655 {
  margin-top: 655px;
}

.mat-660 {
  margin-top: 660px;
}

.mat-665 {
  margin-top: 665px;
}

.mat-670 {
  margin-top: 670px;
}

.mat-675 {
  margin-top: 675px;
}

.mat-680 {
  margin-top: 680px;
}

.mat-685 {
  margin-top: 685px;
}

.mat-690 {
  margin-top: 690px;
}

.mat-695 {
  margin-top: 695px;
}

.mat-700 {
  margin-top: 700px;
}

.mat-705 {
  margin-top: 705px;
}

.mat-710 {
  margin-top: 710px;
}

.mat-715 {
  margin-top: 715px;
}

.mat-720 {
  margin-top: 720px;
}

.mat-725 {
  margin-top: 725px;
}

.mat-730 {
  margin-top: 730px;
}

.mat-735 {
  margin-top: 735px;
}

.mat-740 {
  margin-top: 740px;
}

.mat-745 {
  margin-top: 745px;
}

.mat-750 {
  margin-top: 750px;
}

/*-- Margin Bottom --*/
.mab-5 {
  margin-bottom: 5px;
}

.mab-10 {
  margin-bottom: 10px;
}

.mab-15 {
  margin-bottom: 15px;
}

.mab-20 {
  margin-bottom: 20px;
}

.mab-25 {
  margin-bottom: 25px;
}

.mab-30 {
  margin-bottom: 30px;
}

.mab-35 {
  margin-bottom: 35px;
}

.mab-40 {
  margin-bottom: 40px;
}

.mab-45 {
  margin-bottom: 45px;
}

.mab-50 {
  margin-bottom: 50px;
}

.mab-55 {
  margin-bottom: 55px;
}

.mab-60 {
  margin-bottom: 60px;
}

.mab-65 {
  margin-bottom: 65px;
}

.mab-70 {
  margin-bottom: 70px;
}

.mab-75 {
  margin-bottom: 75px;
}

.mab-80 {
  margin-bottom: 80px;
}

.mab-85 {
  margin-bottom: 85px;
}

.mab-90 {
  margin-bottom: 90px;
}

.mab-95 {
  margin-bottom: 95px;
}

.mab-100 {
  margin-bottom: 100px;
}

.mab-105 {
  margin-bottom: 105px;
}

.mab-110 {
  margin-bottom: 110px;
}

.mab-115 {
  margin-bottom: 115px;
}

.mab-120 {
  margin-bottom: 120px;
}

.mab-125 {
  margin-bottom: 125px;
}

.mab-130 {
  margin-bottom: 130px;
}

.mab-135 {
  margin-bottom: 135px;
}

.mab-140 {
  margin-bottom: 140px;
}

.mab-145 {
  margin-bottom: 145px;
}

.mab-150 {
  margin-bottom: 150px;
}

.mab-155 {
  margin-bottom: 155px;
}

.mab-160 {
  margin-bottom: 160px;
}

.mab-165 {
  margin-bottom: 165px;
}

.mab-170 {
  margin-bottom: 170px;
}

.mab-175 {
  margin-bottom: 175px;
}

.mab-180 {
  margin-bottom: 180px;
}

.mab-185 {
  margin-bottom: 185px;
}

.mab-190 {
  margin-bottom: 190px;
}

.mab-195 {
  margin-bottom: 195px;
}

.mab-200 {
  margin-bottom: 200px;
}

/*-- Margin Left --*/
.mal-5 {
  margin-left: 5px;
}

.mal-10 {
  margin-left: 10px;
}

.mal-15 {
  margin-left: 15px;
}

.mal-20 {
  margin-left: 20px;
}

.mal-25 {
  margin-left: 25px;
}

.mal-30 {
  margin-left: 30px;
}

.mal-35 {
  margin-left: 35px;
}

.mal-40 {
  margin-left: 40px;
}

.mal-45 {
  margin-left: 45px;
}

.mal-50 {
  margin-left: 50px;
}

.mal-55 {
  margin-left: 55px;
}

.mal-60 {
  margin-left: 60px;
}

.mal-65 {
  margin-left: 65px;
}

.mal-70 {
  margin-left: 70px;
}

.mal-75 {
  margin-left: 75px;
}

.mal-80 {
  margin-left: 80px;
}

.mal-85 {
  margin-left: 85px;
}

.mal-90 {
  margin-left: 90px;
}

.mal-95 {
  margin-left: 95px;
}

.mal-100 {
  margin-left: 100px;
}

.mal-105 {
  margin-left: 105px;
}

.mal-110 {
  margin-left: 110px;
}

.mal-115 {
  margin-left: 115px;
}

.mal-120 {
  margin-left: 120px;
}

.mal-125 {
  margin-left: 125px;
}

.mal-130 {
  margin-left: 130px;
}

.mal-135 {
  margin-left: 135px;
}

.mal-140 {
  margin-left: 140px;
}

.mal-145 {
  margin-left: 145px;
}

.mal-150 {
  margin-left: 150px;
}

.mal-155 {
  margin-left: 155px;
}

.mal-160 {
  margin-left: 160px;
}

.mal-165 {
  margin-left: 165px;
}

.mal-170 {
  margin-left: 170px;
}

.mal-175 {
  margin-left: 175px;
}

.mal-180 {
  margin-left: 180px;
}

.mal-185 {
  margin-left: 185px;
}

.mal-190 {
  margin-left: 190px;
}

.mal-195 {
  margin-left: 195px;
}

.mal-200 {
  margin-left: 200px;
}

.mal-205 {
  margin-left: 205px;
}

.mal-210 {
  margin-left: 210px;
}

.mal-215 {
  margin-left: 215px;
}

.mal-220 {
  margin-left: 220px;
}

.mal-225 {
  margin-left: 225px;
}

.mal-230 {
  margin-left: 230px;
}

.mal-235 {
  margin-left: 235px;
}

.mal-240 {
  margin-left: 240px;
}

.mal-245 {
  margin-left: 245px;
}

.mal-250 {
  margin-left: 250px;
}

.mal-255 {
  margin-left: 255px;
}

.mal-260 {
  margin-left: 260px;
}

.mal-265 {
  margin-left: 265px;
}

.mal-270 {
  margin-left: 270px;
}

.mal-275 {
  margin-left: 275px;
}

.mal-280 {
  margin-left: 280px;
}

.mal-285 {
  margin-left: 285px;
}

.mal-290 {
  margin-left: 290px;
}

.mal-295 {
  margin-left: 295px;
}

.mal-300 {
  margin-left: 300px;
}

.mal-305 {
  margin-left: 305px;
}

.mal-310 {
  margin-left: 310px;
}

.mal-315 {
  margin-left: 315px;
}

.mal-320 {
  margin-left: 320px;
}

.mal-325 {
  margin-left: 325px;
}

.mal-330 {
  margin-left: 330px;
}

.mal-335 {
  margin-left: 335px;
}

.mal-340 {
  margin-left: 340px;
}

.mal-345 {
  margin-left: 345px;
}

.mal-350 {
  margin-left: 350px;
}

.mal-355 {
  margin-left: 355px;
}

.mal-360 {
  margin-left: 360px;
}

.mal-365 {
  margin-left: 365px;
}

.mal-370 {
  margin-left: 370px;
}

.mal-375 {
  margin-left: 375px;
}

.mal-380 {
  margin-left: 380px;
}

.mal-385 {
  margin-left: 385px;
}

.mal-390 {
  margin-left: 390px;
}

.mal-395 {
  margin-left: 395px;
}

.mal-400 {
  margin-left: 400px;
}

.mal-405 {
  margin-left: 405px;
}

.mal-410 {
  margin-left: 410px;
}

.mal-415 {
  margin-left: 415px;
}

.mal-420 {
  margin-left: 420px;
}

.mal-425 {
  margin-left: 425px;
}

.mal-430 {
  margin-left: 430px;
}

.mal-435 {
  margin-left: 435px;
}

.mal-440 {
  margin-left: 440px;
}

.mal-445 {
  margin-left: 445px;
}

.mal-450 {
  margin-left: 450px;
}

.mal-455 {
  margin-left: 455px;
}

.mal-460 {
  margin-left: 460px;
}

.mal-465 {
  margin-left: 465px;
}

.mal-470 {
  margin-left: 470px;
}

.mal-475 {
  margin-left: 475px;
}

.mal-480 {
  margin-left: 480px;
}

.mal-485 {
  margin-left: 485px;
}

.mal-490 {
  margin-left: 490px;
}

.mal-495 {
  margin-left: 495px;
}

.mal-500 {
  margin-left: 500px;
}

.mal-505 {
  margin-left: 505px;
}

.mal-510 {
  margin-left: 510px;
}

.mal-515 {
  margin-left: 515px;
}

.mal-520 {
  margin-left: 520px;
}

.mal-525 {
  margin-left: 525px;
}

.mal-530 {
  margin-left: 530px;
}

.mal-535 {
  margin-left: 535px;
}

.mal-540 {
  margin-left: 540px;
}

.mal-545 {
  margin-left: 545px;
}

.mal-550 {
  margin-left: 550px;
}

.mal-555 {
  margin-left: 555px;
}

.mal-560 {
  margin-left: 560px;
}

.mal-565 {
  margin-left: 565px;
}

.mal-570 {
  margin-left: 570px;
}

.mal-575 {
  margin-left: 575px;
}

.mal-580 {
  margin-left: 580px;
}

.mal-585 {
  margin-left: 585px;
}

.mal-590 {
  margin-left: 590px;
}

.mal-595 {
  margin-left: 595px;
}

.mal-600 {
  margin-left: 600px;
}

.mal-605 {
  margin-left: 605px;
}

.mal-610 {
  margin-left: 610px;
}

.mal-615 {
  margin-left: 615px;
}

.mal-620 {
  margin-left: 620px;
}

.mal-625 {
  margin-left: 625px;
}

.mal-630 {
  margin-left: 630px;
}

.mal-635 {
  margin-left: 635px;
}

.mal-640 {
  margin-left: 640px;
}

.mal-645 {
  margin-left: 645px;
}

.mal-650 {
  margin-left: 650px;
}

.mal-655 {
  margin-left: 655px;
}

.mal-660 {
  margin-left: 660px;
}

.mal-665 {
  margin-left: 665px;
}

.mal-670 {
  margin-left: 670px;
}

.mal-675 {
  margin-left: 675px;
}

.mal-680 {
  margin-left: 680px;
}

.mal-685 {
  margin-left: 685px;
}

.mal-690 {
  margin-left: 690px;
}

.mal-695 {
  margin-left: 695px;
}

.mal-700 {
  margin-left: 700px;
}

.mal-705 {
  margin-left: 705px;
}

.mal-710 {
  margin-left: 710px;
}

.mal-715 {
  margin-left: 715px;
}

.mal-720 {
  margin-left: 720px;
}

.mal-725 {
  margin-left: 725px;
}

.mal-730 {
  margin-left: 730px;
}

.mal-735 {
  margin-left: 735px;
}

.mal-740 {
  margin-left: 740px;
}

.mal-745 {
  margin-left: 745px;
}

.mal-750 {
  margin-left: 750px;
}

/*-- Margin Right --*/
.mar-5 {
  margin-right: 5px;
}

.mar-10 {
  margin-right: 10px;
}

.mar-15 {
  margin-right: 15px;
}

.mar-20 {
  margin-right: 20px;
}

.mar-25 {
  margin-right: 25px;
}

.mar-30 {
  margin-right: 30px;
}

.mar-35 {
  margin-right: 35px;
}

.mar-40 {
  margin-right: 40px;
}

.mar-45 {
  margin-right: 45px;
}

.mar-50 {
  margin-right: 50px;
}

.mar-55 {
  margin-right: 55px;
}

.mar-60 {
  margin-right: 60px;
}

.mar-65 {
  margin-right: 65px;
}

.mar-70 {
  margin-right: 70px;
}

.mar-75 {
  margin-right: 75px;
}

.mar-80 {
  margin-right: 80px;
}

.mar-85 {
  margin-right: 85px;
}

.mar-90 {
  margin-right: 90px;
}

.mar-95 {
  margin-right: 95px;
}

.mar-100 {
  margin-right: 100px;
}

.mar-105 {
  margin-right: 105px;
}

.mar-110 {
  margin-right: 110px;
}

.mar-115 {
  margin-right: 115px;
}

.mar-120 {
  margin-right: 120px;
}

.mar-125 {
  margin-right: 125px;
}

.mar-130 {
  margin-right: 130px;
}

.mar-135 {
  margin-right: 135px;
}

.mar-140 {
  margin-right: 140px;
}

.mar-145 {
  margin-right: 145px;
}

.mar-150 {
  margin-right: 150px;
}

.mar-155 {
  margin-right: 155px;
}

.mar-160 {
  margin-right: 160px;
}

.mar-165 {
  margin-right: 165px;
}

.mar-170 {
  margin-right: 170px;
}

.mar-175 {
  margin-right: 175px;
}

.mar-180 {
  margin-right: 180px;
}

.mar-185 {
  margin-right: 185px;
}

.mar-190 {
  margin-right: 190px;
}

.mar-195 {
  margin-right: 195px;
}

.mar-200 {
  margin-right: 200px;
}

.mar-205 {
  margin-right: 205px;
}

.mar-210 {
  margin-right: 210px;
}

.mar-215 {
  margin-right: 215px;
}

.mar-220 {
  margin-right: 220px;
}

.mar-225 {
  margin-right: 225px;
}

.mar-230 {
  margin-right: 230px;
}

.mar-235 {
  margin-right: 235px;
}

.mar-240 {
  margin-right: 240px;
}

.mar-245 {
  margin-right: 245px;
}

.mar-250 {
  margin-right: 250px;
}

.mar-255 {
  margin-right: 255px;
}

.mar-260 {
  margin-right: 260px;
}

.mar-265 {
  margin-right: 265px;
}

.mar-270 {
  margin-right: 270px;
}

.mar-275 {
  margin-right: 275px;
}

.mar-280 {
  margin-right: 280px;
}

.mar-285 {
  margin-right: 285px;
}

.mar-290 {
  margin-right: 290px;
}

.mar-295 {
  margin-right: 295px;
}

.mar-300 {
  margin-right: 300px;
}

.mar-305 {
  margin-right: 305px;
}

.mar-310 {
  margin-right: 310px;
}

.mar-315 {
  margin-right: 315px;
}

.mar-320 {
  margin-right: 320px;
}

.mar-325 {
  margin-right: 325px;
}

.mar-330 {
  margin-right: 330px;
}

.mar-335 {
  margin-right: 335px;
}

.mar-340 {
  margin-right: 340px;
}

.mar-345 {
  margin-right: 345px;
}

.mar-350 {
  margin-right: 350px;
}

.mar-355 {
  margin-right: 355px;
}

.mar-360 {
  margin-right: 360px;
}

.mar-365 {
  margin-right: 365px;
}

.mar-370 {
  margin-right: 370px;
}

.mar-375 {
  margin-right: 375px;
}

.mar-380 {
  margin-right: 380px;
}

.mar-385 {
  margin-right: 385px;
}

.mar-390 {
  margin-right: 390px;
}

.mar-395 {
  margin-right: 395px;
}

.mar-400 {
  margin-right: 400px;
}

.mar-405 {
  margin-right: 405px;
}

.mar-410 {
  margin-right: 410px;
}

.mar-415 {
  margin-right: 415px;
}

.mar-420 {
  margin-right: 420px;
}

.mar-425 {
  margin-right: 425px;
}

.mar-430 {
  margin-right: 430px;
}

.mar-435 {
  margin-right: 435px;
}

.mar-440 {
  margin-right: 440px;
}

.mar-445 {
  margin-right: 445px;
}

.mar-450 {
  margin-right: 450px;
}

.mar-455 {
  margin-right: 455px;
}

.mar-460 {
  margin-right: 460px;
}

.mar-465 {
  margin-right: 465px;
}

.mar-470 {
  margin-right: 470px;
}

.mar-475 {
  margin-right: 475px;
}

.mar-480 {
  margin-right: 480px;
}

.mar-485 {
  margin-right: 485px;
}

.mar-490 {
  margin-right: 490px;
}

.mar-495 {
  margin-right: 495px;
}

.mar-500 {
  margin-right: 500px;
}

.mar-505 {
  margin-right: 505px;
}

.mar-510 {
  margin-right: 510px;
}

.mar-515 {
  margin-right: 515px;
}

.mar-520 {
  margin-right: 520px;
}

.mar-525 {
  margin-right: 525px;
}

.mar-530 {
  margin-right: 530px;
}

.mar-535 {
  margin-right: 535px;
}

.mar-540 {
  margin-right: 540px;
}

.mar-545 {
  margin-right: 545px;
}

.mar-550 {
  margin-right: 550px;
}

.mar-555 {
  margin-right: 555px;
}

.mar-560 {
  margin-right: 560px;
}

.mar-565 {
  margin-right: 565px;
}

.mar-570 {
  margin-right: 570px;
}

.mar-575 {
  margin-right: 575px;
}

.mar-580 {
  margin-right: 580px;
}

.mar-585 {
  margin-right: 585px;
}

.mar-590 {
  margin-right: 590px;
}

.mar-595 {
  margin-right: 595px;
}

.mar-600 {
  margin-right: 600px;
}

.mar-605 {
  margin-right: 605px;
}

.mar-610 {
  margin-right: 610px;
}

.mar-615 {
  margin-right: 615px;
}

.mar-620 {
  margin-right: 620px;
}

.mar-625 {
  margin-right: 625px;
}

.mar-630 {
  margin-right: 630px;
}

.mar-635 {
  margin-right: 635px;
}

.mar-640 {
  margin-right: 640px;
}

.mar-645 {
  margin-right: 645px;
}

.mar-650 {
  margin-right: 650px;
}

.mar-655 {
  margin-right: 655px;
}

.mar-660 {
  margin-right: 660px;
}

.mar-665 {
  margin-right: 665px;
}

.mar-670 {
  margin-right: 670px;
}

.mar-675 {
  margin-right: 675px;
}

.mar-680 {
  margin-right: 680px;
}

.mar-685 {
  margin-right: 685px;
}

.mar-690 {
  margin-right: 690px;
}

.mar-695 {
  margin-right: 695px;
}

.mar-700 {
  margin-right: 700px;
}

.mar-705 {
  margin-right: 705px;
}

.mar-710 {
  margin-right: 710px;
}

.mar-715 {
  margin-right: 715px;
}

.mar-720 {
  margin-right: 720px;
}

.mar-725 {
  margin-right: 725px;
}

.mar-730 {
  margin-right: 730px;
}

.mar-735 {
  margin-right: 735px;
}

.mar-740 {
  margin-right: 740px;
}

.mar-745 {
  margin-right: 745px;
}

.mar-750 {
  margin-right: 750px;
}

/*-- Padding Top --*/
.pat-5 {
  padding-top: 5px;
}

.pat-10 {
  padding-top: 10px;
}

.pat-15 {
  padding-top: 15px;
}

.pat-20 {
  padding-top: 20px;
}

.pat-25 {
  padding-top: 25px;
}

.pat-30 {
  padding-top: 30px;
}

.pat-35 {
  padding-top: 35px;
}

.pat-40 {
  padding-top: 40px;
}

.pat-45 {
  padding-top: 45px;
}

.pat-50 {
  padding-top: 50px;
}

.pat-55 {
  padding-top: 55px;
}

.pat-60 {
  padding-top: 60px;
}

.pat-65 {
  padding-top: 65px;
}

.pat-70 {
  padding-top: 70px;
}

.pat-75 {
  padding-top: 75px;
}

.pat-80 {
  padding-top: 80px;
}

.pat-85 {
  padding-top: 85px;
}

.pat-90 {
  padding-top: 90px;
}

.pat-95 {
  padding-top: 95px;
}

.pat-100 {
  padding-top: 100px;
}

.pat-105 {
  padding-top: 105px;
}

.pat-110 {
  padding-top: 110px;
}

.pat-115 {
  padding-top: 115px;
}

.pat-120 {
  padding-top: 120px;
}

.pat-125 {
  padding-top: 125px;
}

.pat-130 {
  padding-top: 130px;
}

.pat-135 {
  padding-top: 135px;
}

.pat-140 {
  padding-top: 140px;
}

.pat-145 {
  padding-top: 145px;
}

.pat-150 {
  padding-top: 150px;
}

.pat-155 {
  padding-top: 155px;
}

.pat-160 {
  padding-top: 160px;
}

.pat-165 {
  padding-top: 165px;
}

.pat-170 {
  padding-top: 170px;
}

.pat-175 {
  padding-top: 175px;
}

.pat-180 {
  padding-top: 180px;
}

.pat-185 {
  padding-top: 185px;
}

.pat-190 {
  padding-top: 190px;
}

.pat-195 {
  padding-top: 195px;
}

.pat-200 {
  padding-top: 200px;
}

.pat-205 {
  padding-top: 205px;
}

.pat-210 {
  padding-top: 210px;
}

.pat-215 {
  padding-top: 215px;
}

.pat-220 {
  padding-top: 220px;
}

.pat-225 {
  padding-top: 225px;
}

.pat-230 {
  padding-top: 230px;
}

.pat-235 {
  padding-top: 235px;
}

.pat-240 {
  padding-top: 240px;
}

.pat-245 {
  padding-top: 245px;
}

.pat-250 {
  padding-top: 250px;
}

.pat-255 {
  padding-top: 255px;
}

.pat-260 {
  padding-top: 260px;
}

.pat-265 {
  padding-top: 265px;
}

.pat-270 {
  padding-top: 270px;
}

.pat-275 {
  padding-top: 275px;
}

.pat-280 {
  padding-top: 280px;
}

.pat-285 {
  padding-top: 285px;
}

.pat-290 {
  padding-top: 290px;
}

.pat-295 {
  padding-top: 295px;
}

.pat-300 {
  padding-top: 300px;
}

.pat-305 {
  padding-top: 305px;
}

.pat-310 {
  padding-top: 310px;
}

.pat-315 {
  padding-top: 315px;
}

.pat-320 {
  padding-top: 320px;
}

.pat-325 {
  padding-top: 325px;
}

.pat-330 {
  padding-top: 330px;
}

.pat-335 {
  padding-top: 335px;
}

.pat-340 {
  padding-top: 340px;
}

.pat-345 {
  padding-top: 345px;
}

.pat-350 {
  padding-top: 350px;
}

.pat-355 {
  padding-top: 355px;
}

.pat-360 {
  padding-top: 360px;
}

.pat-365 {
  padding-top: 365px;
}

.pat-370 {
  padding-top: 370px;
}

.pat-375 {
  padding-top: 375px;
}

.pat-380 {
  padding-top: 380px;
}

.pat-385 {
  padding-top: 385px;
}

.pat-390 {
  padding-top: 390px;
}

.pat-395 {
  padding-top: 395px;
}

.pat-400 {
  padding-top: 400px;
}

.pat-405 {
  padding-top: 405px;
}

.pat-410 {
  padding-top: 410px;
}

.pat-415 {
  padding-top: 415px;
}

.pat-420 {
  padding-top: 420px;
}

.pat-425 {
  padding-top: 425px;
}

.pat-430 {
  padding-top: 430px;
}

.pat-435 {
  padding-top: 435px;
}

.pat-440 {
  padding-top: 440px;
}

.pat-445 {
  padding-top: 445px;
}

.pat-450 {
  padding-top: 450px;
}

.pat-455 {
  padding-top: 455px;
}

.pat-460 {
  padding-top: 460px;
}

.pat-465 {
  padding-top: 465px;
}

.pat-470 {
  padding-top: 470px;
}

.pat-475 {
  padding-top: 475px;
}

.pat-480 {
  padding-top: 480px;
}

.pat-485 {
  padding-top: 485px;
}

.pat-490 {
  padding-top: 490px;
}

.pat-495 {
  padding-top: 495px;
}

.pat-500 {
  padding-top: 500px;
}

.pat-505 {
  padding-top: 505px;
}

.pat-510 {
  padding-top: 510px;
}

.pat-515 {
  padding-top: 515px;
}

.pat-520 {
  padding-top: 520px;
}

.pat-525 {
  padding-top: 525px;
}

.pat-530 {
  padding-top: 530px;
}

.pat-535 {
  padding-top: 535px;
}

.pat-540 {
  padding-top: 540px;
}

.pat-545 {
  padding-top: 545px;
}

.pat-550 {
  padding-top: 550px;
}

.pat-555 {
  padding-top: 555px;
}

.pat-560 {
  padding-top: 560px;
}

.pat-565 {
  padding-top: 565px;
}

.pat-570 {
  padding-top: 570px;
}

.pat-575 {
  padding-top: 575px;
}

.pat-580 {
  padding-top: 580px;
}

.pat-585 {
  padding-top: 585px;
}

.pat-590 {
  padding-top: 590px;
}

.pat-595 {
  padding-top: 595px;
}

.pat-600 {
  padding-top: 600px;
}

.pat-605 {
  padding-top: 605px;
}

.pat-610 {
  padding-top: 610px;
}

.pat-615 {
  padding-top: 615px;
}

.pat-620 {
  padding-top: 620px;
}

.pat-625 {
  padding-top: 625px;
}

.pat-630 {
  padding-top: 630px;
}

.pat-635 {
  padding-top: 635px;
}

.pat-640 {
  padding-top: 640px;
}

.pat-645 {
  padding-top: 645px;
}

.pat-650 {
  padding-top: 650px;
}

.pat-655 {
  padding-top: 655px;
}

.pat-660 {
  padding-top: 660px;
}

.pat-665 {
  padding-top: 665px;
}

.pat-670 {
  padding-top: 670px;
}

.pat-675 {
  padding-top: 675px;
}

.pat-680 {
  padding-top: 680px;
}

.pat-685 {
  padding-top: 685px;
}

.pat-690 {
  padding-top: 690px;
}

.pat-695 {
  padding-top: 695px;
}

.pat-700 {
  padding-top: 700px;
}

.pat-705 {
  padding-top: 705px;
}

.pat-710 {
  padding-top: 710px;
}

.pat-715 {
  padding-top: 715px;
}

.pat-720 {
  padding-top: 720px;
}

.pat-725 {
  padding-top: 725px;
}

.pat-730 {
  padding-top: 730px;
}

.pat-735 {
  padding-top: 735px;
}

.pat-740 {
  padding-top: 740px;
}

.pat-745 {
  padding-top: 745px;
}

.pat-750 {
  padding-top: 750px;
}

@media screen and (max-width: 991px) {
  .pat-100 {
    padding-top: 70px !important;
  }

  .pab-100 {
    padding-bottom: 70px !important;
  }

  .pat-75 {
    padding-top: 45px !important;
  }

  .pab-75 {
    padding-bottom: 45px !important;
  }

  .pat-50 {
    padding-top: 35px !important;
  }

  .pab-50 {
    padding-bottom: 35px !important;
  }
}
/*-- Padding Bottom --*/
.pab-5 {
  padding-bottom: 5px;
}

.pab-10 {
  padding-bottom: 10px;
}

.pab-15 {
  padding-bottom: 15px;
}

.pab-20 {
  padding-bottom: 20px;
}

.pab-25 {
  padding-bottom: 25px;
}

.pab-30 {
  padding-bottom: 30px;
}

.pab-35 {
  padding-bottom: 35px;
}

.pab-40 {
  padding-bottom: 40px;
}

.pab-45 {
  padding-bottom: 45px;
}

.pab-50 {
  padding-bottom: 50px;
}

.pab-55 {
  padding-bottom: 55px;
}

.pab-60 {
  padding-bottom: 60px;
}

.pab-65 {
  padding-bottom: 65px;
}

.pab-70 {
  padding-bottom: 70px;
}

.pab-75 {
  padding-bottom: 75px;
}

.pab-80 {
  padding-bottom: 80px;
}

.pab-85 {
  padding-bottom: 85px;
}

.pab-90 {
  padding-bottom: 90px;
}

.pab-95 {
  padding-bottom: 95px;
}

.pab-100 {
  padding-bottom: 100px;
}

.pab-105 {
  padding-bottom: 105px;
}

.pab-110 {
  padding-bottom: 110px;
}

.pab-115 {
  padding-bottom: 115px;
}

.pab-120 {
  padding-bottom: 120px;
}

.pab-125 {
  padding-bottom: 125px;
}

.pab-130 {
  padding-bottom: 130px;
}

.pab-135 {
  padding-bottom: 135px;
}

.pab-140 {
  padding-bottom: 140px;
}

.pab-145 {
  padding-bottom: 145px;
}

.pab-150 {
  padding-bottom: 150px;
}

.pab-155 {
  padding-bottom: 155px;
}

.pab-160 {
  padding-bottom: 160px;
}

.pab-165 {
  padding-bottom: 165px;
}

.pab-170 {
  padding-bottom: 170px;
}

.pab-175 {
  padding-bottom: 175px;
}

.pab-180 {
  padding-bottom: 180px;
}

.pab-185 {
  padding-bottom: 185px;
}

.pab-190 {
  padding-bottom: 190px;
}

.pab-195 {
  padding-bottom: 195px;
}

.pab-200 {
  padding-bottom: 200px;
}

.pab-205 {
  padding-bottom: 205px;
}

.pab-210 {
  padding-bottom: 210px;
}

.pab-215 {
  padding-bottom: 215px;
}

.pab-220 {
  padding-bottom: 220px;
}

.pab-225 {
  padding-bottom: 225px;
}

.pab-230 {
  padding-bottom: 230px;
}

.pab-235 {
  padding-bottom: 235px;
}

.pab-240 {
  padding-bottom: 240px;
}

.pab-245 {
  padding-bottom: 245px;
}

.pab-250 {
  padding-bottom: 250px;
}

.pab-255 {
  padding-bottom: 255px;
}

.pab-260 {
  padding-bottom: 260px;
}

.pab-265 {
  padding-bottom: 265px;
}

.pab-270 {
  padding-bottom: 270px;
}

.pab-275 {
  padding-bottom: 275px;
}

.pab-280 {
  padding-bottom: 280px;
}

.pab-285 {
  padding-bottom: 285px;
}

.pab-290 {
  padding-bottom: 290px;
}

.pab-295 {
  padding-bottom: 295px;
}

.pab-300 {
  padding-bottom: 300px;
}

.pab-305 {
  padding-bottom: 305px;
}

.pab-310 {
  padding-bottom: 310px;
}

.pab-315 {
  padding-bottom: 315px;
}

.pab-320 {
  padding-bottom: 320px;
}

.pab-325 {
  padding-bottom: 325px;
}

.pab-330 {
  padding-bottom: 330px;
}

.pab-335 {
  padding-bottom: 335px;
}

.pab-340 {
  padding-bottom: 340px;
}

.pab-345 {
  padding-bottom: 345px;
}

.pab-350 {
  padding-bottom: 350px;
}

.pab-355 {
  padding-bottom: 355px;
}

.pab-360 {
  padding-bottom: 360px;
}

.pab-365 {
  padding-bottom: 365px;
}

.pab-370 {
  padding-bottom: 370px;
}

.pab-375 {
  padding-bottom: 375px;
}

.pab-380 {
  padding-bottom: 380px;
}

.pab-385 {
  padding-bottom: 385px;
}

.pab-390 {
  padding-bottom: 390px;
}

.pab-395 {
  padding-bottom: 395px;
}

.pab-400 {
  padding-bottom: 400px;
}

.pab-405 {
  padding-bottom: 405px;
}

.pab-410 {
  padding-bottom: 410px;
}

.pab-415 {
  padding-bottom: 415px;
}

.pab-420 {
  padding-bottom: 420px;
}

.pab-425 {
  padding-bottom: 425px;
}

.pab-430 {
  padding-bottom: 430px;
}

.pab-435 {
  padding-bottom: 435px;
}

.pab-440 {
  padding-bottom: 440px;
}

.pab-445 {
  padding-bottom: 445px;
}

.pab-450 {
  padding-bottom: 450px;
}

.pab-455 {
  padding-bottom: 455px;
}

.pab-460 {
  padding-bottom: 460px;
}

.pab-465 {
  padding-bottom: 465px;
}

.pab-470 {
  padding-bottom: 470px;
}

.pab-475 {
  padding-bottom: 475px;
}

.pab-480 {
  padding-bottom: 480px;
}

.pab-485 {
  padding-bottom: 485px;
}

.pab-490 {
  padding-bottom: 490px;
}

.pab-495 {
  padding-bottom: 495px;
}

.pab-500 {
  padding-bottom: 500px;
}

.pab-505 {
  padding-bottom: 505px;
}

.pab-510 {
  padding-bottom: 510px;
}

.pab-515 {
  padding-bottom: 515px;
}

.pab-520 {
  padding-bottom: 520px;
}

.pab-525 {
  padding-bottom: 525px;
}

.pab-530 {
  padding-bottom: 530px;
}

.pab-535 {
  padding-bottom: 535px;
}

.pab-540 {
  padding-bottom: 540px;
}

.pab-545 {
  padding-bottom: 545px;
}

.pab-550 {
  padding-bottom: 550px;
}

.pab-555 {
  padding-bottom: 555px;
}

.pab-560 {
  padding-bottom: 560px;
}

.pab-565 {
  padding-bottom: 565px;
}

.pab-570 {
  padding-bottom: 570px;
}

.pab-575 {
  padding-bottom: 575px;
}

.pab-580 {
  padding-bottom: 580px;
}

.pab-585 {
  padding-bottom: 585px;
}

.pab-590 {
  padding-bottom: 590px;
}

.pab-595 {
  padding-bottom: 595px;
}

.pab-600 {
  padding-bottom: 600px;
}

.pab-605 {
  padding-bottom: 605px;
}

.pab-610 {
  padding-bottom: 610px;
}

.pab-615 {
  padding-bottom: 615px;
}

.pab-620 {
  padding-bottom: 620px;
}

.pab-625 {
  padding-bottom: 625px;
}

.pab-630 {
  padding-bottom: 630px;
}

.pab-635 {
  padding-bottom: 635px;
}

.pab-640 {
  padding-bottom: 640px;
}

.pab-645 {
  padding-bottom: 645px;
}

.pab-650 {
  padding-bottom: 650px;
}

.pab-655 {
  padding-bottom: 655px;
}

.pab-660 {
  padding-bottom: 660px;
}

.pab-665 {
  padding-bottom: 665px;
}

.pab-670 {
  padding-bottom: 670px;
}

.pab-675 {
  padding-bottom: 675px;
}

.pab-680 {
  padding-bottom: 680px;
}

.pab-685 {
  padding-bottom: 685px;
}

.pab-690 {
  padding-bottom: 690px;
}

.pab-695 {
  padding-bottom: 695px;
}

.pab-700 {
  padding-bottom: 700px;
}

.pab-705 {
  padding-bottom: 705px;
}

.pab-710 {
  padding-bottom: 710px;
}

.pab-715 {
  padding-bottom: 715px;
}

.pab-720 {
  padding-bottom: 720px;
}

.pab-725 {
  padding-bottom: 725px;
}

.pab-730 {
  padding-bottom: 730px;
}

.pab-735 {
  padding-bottom: 735px;
}

.pab-740 {
  padding-bottom: 740px;
}

.pab-745 {
  padding-bottom: 745px;
}

.pab-750 {
  padding-bottom: 750px;
}

/*-- Padding Left --*/
.pal-5 {
  padding-left: 5px;
}

.pal-10 {
  padding-left: 10px;
}

.pal-15 {
  padding-left: 15px;
}

.pal-20 {
  padding-left: 20px;
}

.pal-25 {
  padding-left: 25px;
}

.pal-30 {
  padding-left: 30px;
}

.pal-35 {
  padding-left: 35px;
}

.pal-40 {
  padding-left: 40px;
}

.pal-45 {
  padding-left: 45px;
}

.pal-50 {
  padding-left: 50px;
}

.pal-55 {
  padding-left: 55px;
}

.pal-60 {
  padding-left: 60px;
}

.pal-65 {
  padding-left: 65px;
}

.pal-70 {
  padding-left: 70px;
}

.pal-75 {
  padding-left: 75px;
}

.pal-80 {
  padding-left: 80px;
}

.pal-85 {
  padding-left: 85px;
}

.pal-90 {
  padding-left: 90px;
}

.pal-95 {
  padding-left: 95px;
}

.pal-100 {
  padding-left: 100px;
}

.pal-105 {
  padding-left: 105px;
}

.pal-110 {
  padding-left: 110px;
}

.pal-115 {
  padding-left: 115px;
}

.pal-120 {
  padding-left: 120px;
}

.pal-125 {
  padding-left: 125px;
}

.pal-130 {
  padding-left: 130px;
}

.pal-135 {
  padding-left: 135px;
}

.pal-140 {
  padding-left: 140px;
}

.pal-145 {
  padding-left: 145px;
}

.pal-150 {
  padding-left: 150px;
}

.pal-155 {
  padding-left: 155px;
}

.pal-160 {
  padding-left: 160px;
}

.pal-165 {
  padding-left: 165px;
}

.pal-170 {
  padding-left: 170px;
}

.pal-175 {
  padding-left: 175px;
}

.pal-180 {
  padding-left: 180px;
}

.pal-185 {
  padding-left: 185px;
}

.pal-190 {
  padding-left: 190px;
}

.pal-195 {
  padding-left: 195px;
}

.pal-200 {
  padding-left: 200px;
}

.pal-205 {
  padding-left: 205px;
}

.pal-210 {
  padding-left: 210px;
}

.pal-215 {
  padding-left: 215px;
}

.pal-220 {
  padding-left: 220px;
}

.pal-225 {
  padding-left: 225px;
}

.pal-230 {
  padding-left: 230px;
}

.pal-235 {
  padding-left: 235px;
}

.pal-240 {
  padding-left: 240px;
}

.pal-245 {
  padding-left: 245px;
}

.pal-250 {
  padding-left: 250px;
}

.pal-255 {
  padding-left: 255px;
}

.pal-260 {
  padding-left: 260px;
}

.pal-265 {
  padding-left: 265px;
}

.pal-270 {
  padding-left: 270px;
}

.pal-275 {
  padding-left: 275px;
}

.pal-280 {
  padding-left: 280px;
}

.pal-285 {
  padding-left: 285px;
}

.pal-290 {
  padding-left: 290px;
}

.pal-295 {
  padding-left: 295px;
}

.pal-300 {
  padding-left: 300px;
}

.pal-305 {
  padding-left: 305px;
}

.pal-310 {
  padding-left: 310px;
}

.pal-315 {
  padding-left: 315px;
}

.pal-320 {
  padding-left: 320px;
}

.pal-325 {
  padding-left: 325px;
}

.pal-330 {
  padding-left: 330px;
}

.pal-335 {
  padding-left: 335px;
}

.pal-340 {
  padding-left: 340px;
}

.pal-345 {
  padding-left: 345px;
}

.pal-350 {
  padding-left: 350px;
}

.pal-355 {
  padding-left: 355px;
}

.pal-360 {
  padding-left: 360px;
}

.pal-365 {
  padding-left: 365px;
}

.pal-370 {
  padding-left: 370px;
}

.pal-375 {
  padding-left: 375px;
}

.pal-380 {
  padding-left: 380px;
}

.pal-385 {
  padding-left: 385px;
}

.pal-390 {
  padding-left: 390px;
}

.pal-395 {
  padding-left: 395px;
}

.pal-400 {
  padding-left: 400px;
}

.pal-405 {
  padding-left: 405px;
}

.pal-410 {
  padding-left: 410px;
}

.pal-415 {
  padding-left: 415px;
}

.pal-420 {
  padding-left: 420px;
}

.pal-425 {
  padding-left: 425px;
}

.pal-430 {
  padding-left: 430px;
}

.pal-435 {
  padding-left: 435px;
}

.pal-440 {
  padding-left: 440px;
}

.pal-445 {
  padding-left: 445px;
}

.pal-450 {
  padding-left: 450px;
}

.pal-455 {
  padding-left: 455px;
}

.pal-460 {
  padding-left: 460px;
}

.pal-465 {
  padding-left: 465px;
}

.pal-470 {
  padding-left: 470px;
}

.pal-475 {
  padding-left: 475px;
}

.pal-480 {
  padding-left: 480px;
}

.pal-485 {
  padding-left: 485px;
}

.pal-490 {
  padding-left: 490px;
}

.pal-495 {
  padding-left: 495px;
}

.pal-500 {
  padding-left: 500px;
}

.pal-505 {
  padding-left: 505px;
}

.pal-510 {
  padding-left: 510px;
}

.pal-515 {
  padding-left: 515px;
}

.pal-520 {
  padding-left: 520px;
}

.pal-525 {
  padding-left: 525px;
}

.pal-530 {
  padding-left: 530px;
}

.pal-535 {
  padding-left: 535px;
}

.pal-540 {
  padding-left: 540px;
}

.pal-545 {
  padding-left: 545px;
}

.pal-550 {
  padding-left: 550px;
}

.pal-555 {
  padding-left: 555px;
}

.pal-560 {
  padding-left: 560px;
}

.pal-565 {
  padding-left: 565px;
}

.pal-570 {
  padding-left: 570px;
}

.pal-575 {
  padding-left: 575px;
}

.pal-580 {
  padding-left: 580px;
}

.pal-585 {
  padding-left: 585px;
}

.pal-590 {
  padding-left: 590px;
}

.pal-595 {
  padding-left: 595px;
}

.pal-600 {
  padding-left: 600px;
}

.pal-605 {
  padding-left: 605px;
}

.pal-610 {
  padding-left: 610px;
}

.pal-615 {
  padding-left: 615px;
}

.pal-620 {
  padding-left: 620px;
}

.pal-625 {
  padding-left: 625px;
}

.pal-630 {
  padding-left: 630px;
}

.pal-635 {
  padding-left: 635px;
}

.pal-640 {
  padding-left: 640px;
}

.pal-645 {
  padding-left: 645px;
}

.pal-650 {
  padding-left: 650px;
}

.pal-655 {
  padding-left: 655px;
}

.pal-660 {
  padding-left: 660px;
}

.pal-665 {
  padding-left: 665px;
}

.pal-670 {
  padding-left: 670px;
}

.pal-675 {
  padding-left: 675px;
}

.pal-680 {
  padding-left: 680px;
}

.pal-685 {
  padding-left: 685px;
}

.pal-690 {
  padding-left: 690px;
}

.pal-695 {
  padding-left: 695px;
}

.pal-700 {
  padding-left: 700px;
}

.pal-705 {
  padding-left: 705px;
}

.pal-710 {
  padding-left: 710px;
}

.pal-715 {
  padding-left: 715px;
}

.pal-720 {
  padding-left: 720px;
}

.pal-725 {
  padding-left: 725px;
}

.pal-730 {
  padding-left: 730px;
}

.pal-735 {
  padding-left: 735px;
}

.pal-740 {
  padding-left: 740px;
}

.pal-745 {
  padding-left: 745px;
}

.pal-750 {
  padding-left: 750px;
}

/*-- Padding Right --*/
.par-5 {
  padding-right: 5px;
}

.par-10 {
  padding-right: 10px;
}

.par-15 {
  padding-right: 15px;
}

.par-20 {
  padding-right: 20px;
}

.par-25 {
  padding-right: 25px;
}

.par-30 {
  padding-right: 30px;
}

.par-35 {
  padding-right: 35px;
}

.par-40 {
  padding-right: 40px;
}

.par-45 {
  padding-right: 45px;
}

.par-50 {
  padding-right: 50px;
}

.par-55 {
  padding-right: 55px;
}

.par-60 {
  padding-right: 60px;
}

.par-65 {
  padding-right: 65px;
}

.par-70 {
  padding-right: 70px;
}

.par-75 {
  padding-right: 75px;
}

.par-80 {
  padding-right: 80px;
}

.par-85 {
  padding-right: 85px;
}

.par-90 {
  padding-right: 90px;
}

.par-95 {
  padding-right: 95px;
}

.par-100 {
  padding-right: 100px;
}

.par-105 {
  padding-right: 105px;
}

.par-110 {
  padding-right: 110px;
}

.par-115 {
  padding-right: 115px;
}

.par-120 {
  padding-right: 120px;
}

.par-125 {
  padding-right: 125px;
}

.par-130 {
  padding-right: 130px;
}

.par-135 {
  padding-right: 135px;
}

.par-140 {
  padding-right: 140px;
}

.par-145 {
  padding-right: 145px;
}

.par-150 {
  padding-right: 150px;
}

.par-155 {
  padding-right: 155px;
}

.par-160 {
  padding-right: 160px;
}

.par-165 {
  padding-right: 165px;
}

.par-170 {
  padding-right: 170px;
}

.par-175 {
  padding-right: 175px;
}

.par-180 {
  padding-right: 180px;
}

.par-185 {
  padding-right: 185px;
}

.par-190 {
  padding-right: 190px;
}

.par-195 {
  padding-right: 195px;
}

.par-200 {
  padding-right: 200px;
}

.par-205 {
  padding-right: 205px;
}

.par-210 {
  padding-right: 210px;
}

.par-215 {
  padding-right: 215px;
}

.par-220 {
  padding-right: 220px;
}

.par-225 {
  padding-right: 225px;
}

.par-230 {
  padding-right: 230px;
}

.par-235 {
  padding-right: 235px;
}

.par-240 {
  padding-right: 240px;
}

.par-245 {
  padding-right: 245px;
}

.par-250 {
  padding-right: 250px;
}

.par-255 {
  padding-right: 255px;
}

.par-260 {
  padding-right: 260px;
}

.par-265 {
  padding-right: 265px;
}

.par-270 {
  padding-right: 270px;
}

.par-275 {
  padding-right: 275px;
}

.par-280 {
  padding-right: 280px;
}

.par-285 {
  padding-right: 285px;
}

.par-290 {
  padding-right: 290px;
}

.par-295 {
  padding-right: 295px;
}

.par-300 {
  padding-right: 300px;
}

.par-305 {
  padding-right: 305px;
}

.par-310 {
  padding-right: 310px;
}

.par-315 {
  padding-right: 315px;
}

.par-320 {
  padding-right: 320px;
}

.par-325 {
  padding-right: 325px;
}

.par-330 {
  padding-right: 330px;
}

.par-335 {
  padding-right: 335px;
}

.par-340 {
  padding-right: 340px;
}

.par-345 {
  padding-right: 345px;
}

.par-350 {
  padding-right: 350px;
}

.par-355 {
  padding-right: 355px;
}

.par-360 {
  padding-right: 360px;
}

.par-365 {
  padding-right: 365px;
}

.par-370 {
  padding-right: 370px;
}

.par-375 {
  padding-right: 375px;
}

.par-380 {
  padding-right: 380px;
}

.par-385 {
  padding-right: 385px;
}

.par-390 {
  padding-right: 390px;
}

.par-395 {
  padding-right: 395px;
}

.par-400 {
  padding-right: 400px;
}

.par-405 {
  padding-right: 405px;
}

.par-410 {
  padding-right: 410px;
}

.par-415 {
  padding-right: 415px;
}

.par-420 {
  padding-right: 420px;
}

.par-425 {
  padding-right: 425px;
}

.par-430 {
  padding-right: 430px;
}

.par-435 {
  padding-right: 435px;
}

.par-440 {
  padding-right: 440px;
}

.par-445 {
  padding-right: 445px;
}

.par-450 {
  padding-right: 450px;
}

.par-455 {
  padding-right: 455px;
}

.par-460 {
  padding-right: 460px;
}

.par-465 {
  padding-right: 465px;
}

.par-470 {
  padding-right: 470px;
}

.par-475 {
  padding-right: 475px;
}

.par-480 {
  padding-right: 480px;
}

.par-485 {
  padding-right: 485px;
}

.par-490 {
  padding-right: 490px;
}

.par-495 {
  padding-right: 495px;
}

.par-500 {
  padding-right: 500px;
}

.par-505 {
  padding-right: 505px;
}

.par-510 {
  padding-right: 510px;
}

.par-515 {
  padding-right: 515px;
}

.par-520 {
  padding-right: 520px;
}

.par-525 {
  padding-right: 525px;
}

.par-530 {
  padding-right: 530px;
}

.par-535 {
  padding-right: 535px;
}

.par-540 {
  padding-right: 540px;
}

.par-545 {
  padding-right: 545px;
}

.par-550 {
  padding-right: 550px;
}

.par-555 {
  padding-right: 555px;
}

.par-560 {
  padding-right: 560px;
}

.par-565 {
  padding-right: 565px;
}

.par-570 {
  padding-right: 570px;
}

.par-575 {
  padding-right: 575px;
}

.par-580 {
  padding-right: 580px;
}

.par-585 {
  padding-right: 585px;
}

.par-590 {
  padding-right: 590px;
}

.par-595 {
  padding-right: 595px;
}

.par-600 {
  padding-right: 600px;
}

.par-605 {
  padding-right: 605px;
}

.par-610 {
  padding-right: 610px;
}

.par-615 {
  padding-right: 615px;
}

.par-620 {
  padding-right: 620px;
}

.par-625 {
  padding-right: 625px;
}

.par-630 {
  padding-right: 630px;
}

.par-635 {
  padding-right: 635px;
}

.par-640 {
  padding-right: 640px;
}

.par-645 {
  padding-right: 645px;
}

.par-650 {
  padding-right: 650px;
}

.par-655 {
  padding-right: 655px;
}

.par-660 {
  padding-right: 660px;
}

.par-665 {
  padding-right: 665px;
}

.par-670 {
  padding-right: 670px;
}

.par-675 {
  padding-right: 675px;
}

.par-680 {
  padding-right: 680px;
}

.par-685 {
  padding-right: 685px;
}

.par-690 {
  padding-right: 690px;
}

.par-695 {
  padding-right: 695px;
}

.par-700 {
  padding-right: 700px;
}

.par-705 {
  padding-right: 705px;
}

.par-710 {
  padding-right: 710px;
}

.par-715 {
  padding-right: 715px;
}

.par-720 {
  padding-right: 720px;
}

.par-725 {
  padding-right: 725px;
}

.par-730 {
  padding-right: 730px;
}

.par-735 {
  padding-right: 735px;
}

.par-740 {
  padding-right: 740px;
}

.par-745 {
  padding-right: 745px;
}

.par-750 {
  padding-right: 750px;
}

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both;
}

/*---------------------------------------
    ## Button
---------------------------------------*/
.btn-wrapper {
  display: block;
}
.btn-wrapper .cmn-btn {
  color: var(--paragraph-color);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--verdana-font);
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  line-height: 34px;
  padding: 7px 35px;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
@media only screen and (max-width: 575.98px) {
  .btn-wrapper .cmn-btn {
    padding: 10px 25px;
    font-size: 16px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 375px) {
  .btn-wrapper .cmn-btn {
    padding: 5px 15px;
    font-size: 16px;
  }
}
.btn-wrapper .cmn-btn.btn-bg-1 {
  background: #fff;
  color:var(--btn-txt-color);
  border: 2px solid var(--btn-txt-color);
}
.btn-wrapper .cmn-btn.btn-bg-1:hover {
  background: var(--secondary-color);
  color: var(--btn-txt2-color);
  border: 2px solid var(--secondary-color);
}

.btn-wrapper .cmn-btn.btn-bg-2 {
  background-color: var(--main-color-one);
  color: var(--btn-txt2-color);
}


.btn-wrapper .cmn-btn.btn-border {
  border: 1px solid #f1f1f1;
}
.btn-wrapper .cmn-btn.btn-border:hover {
  background-color: var(--main-color-one);
  color: #130909;
  border-color: var(--main-color-one);
}
.btn-wrapper .cmn-btn.btn-outline-1 {
  padding: 7px 35px;
  border: 2px solid var(--main-color-one);
}
.btn-wrapper .cmn-btn.btn-outline-1.color-one {
  color: var(--main-color-one);
}
.btn-wrapper .cmn-btn.btn-outline-1:hover {
  background: var(--main-color-one);
  color: #fff;
}
@media only screen and (max-width: 575.98px) {
  .btn-wrapper .cmn-btn.btn-outline-1 {
    padding: 8px 23px;
    font-size: 16px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 375px) {
  .btn-wrapper .cmn-btn.btn-outline-1 {
    padding: 4px 13px;
    font-size: 16px;
  }
}
.btn-wrapper .cmn-btn.btn-bg-white {
  background-color: #fff;
  color: var(--heading-color);
  border: 2px solid #fff;
}
.btn-wrapper .cmn-btn.btn-bg-white:hover {
  background-color: var(--main-color-one);
  border-color: #fff;
  color: #fff;
  border-radius: 30px;
}
.btn-wrapper .cmn-btn.btn-medium {
  padding: 7px 20px;
}
@media only screen and (max-width: 375px) {
  .btn-wrapper .cmn-btn.btn-medium {
    padding: 5px 15px;
    font-size: 16px;
  }
}
.btn-wrapper .cmn-btn.btn-small {
  padding: 5px 15px;
}
@media only screen and (max-width: 375px) {
  .btn-wrapper .cmn-btn.btn-small {
    padding: 5px 12px;
    font-size: 16px;
  }
}

.center-text .flex-btn {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.flex-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px 20px;
}

.btn-see-more {
  font-weight: 600;
  color: var(--heading-colors);
  font-size: 16px;
}
.btn-see-more::after {
  content: "\f067";
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 18px;
}

/*---------------------------------------
    ## Back Top
---------------------------------------*/
.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  background-color: var(--main-color-one);
  color: #fff;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  z-index: 99;
  font-size: 25px;
  cursor: pointer;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  display: none;
}
@media only screen and (max-width: 575.98px) {
  .back-to-top {
    height: 40px;
    width: 40px;
    font-size: 22px;
    line-height: 40px;
    bottom: 20px;
    right: 20px;
  }
}
.back-to-top.bg-color-two {
  background: var(--secondary-color);
}
.back-to-top.bg-color-three {
  background: var(--main-color-three);
}
.back-to-top.bg-color-four {
  background: var(--main-color-four);
}
.back-to-top.bg-color-five {
  background: var(--main-color-five);
}

/*-------------------------
    Section Title
-------------------------*/
.section-title.text-left {
  text-align: left;
}
.section-title.white-color {
  color: #fff;
}
.section-title.white-color .title {
  color: #fff;
}
.section-title.center-text {
  text-align: center;
  max-width: 100%;
  margin: auto;
}
.section-title.section-border-bottom {
  border-bottom: 1px solid rgba(221, 221, 221, 0.4);
  padding-bottom: 30px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .section-title.section-border-bottom {
    padding-bottom: 20px;
  }
}
.section-title-shapes {
  position: relative;
  height: 20px;
}
.section-title-shapes::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-image: url(./assets/img/section-shapes1.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  width: auto;
}

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("./assets/img/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
      background-image: url("./assets/img/flags@2x.png"); } }
.section-title-line {
  position: relative;
  height: 20px;
}

.section-title-line::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-image: url(./assets/img/section-shapes2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  width: auto;
}


.section-title-upcoming {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}


.section-title .title {
  font-size: 42px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--heading-color);
  font-family: var(--heading-font);
  position: relative;
  margin: -6px 0 0;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .section-title .title {
    font-size: 45px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .section-title .title {
    font-size: 42px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .section-title .title {
    font-size: 36px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .section-title .title {
    font-size: 32px;
  }
}
@media only screen and (max-width: 575.98px) {
  .section-title .title {
    font-size: 28px;
    line-height: 32px;
  }
  .section-title-upcoming {
    flex-direction: column;
  }
}
@media only screen and (max-width: 375px) {
  .section-title .title {
    font-size: 25px;
    line-height: 28px;
  }

  .section-title-upcoming {
    flex-direction: column;
  }
}
.section-title .section-para {
  font-size: 18px;
  line-height: 28px;
  margin-top: 20px;
}
@media only screen and (max-width: 480px) {
  .section-title .btn-wrapper .view-all {
    font-size: 16px;
  }
}

.section-title-three .title {
  font-size: 42px;
  line-height: 42px;
  font-weight: 600;
  position: relative;
  z-index: 1;
  font-family: var(--heading-font);
  color: var(--heading-color);
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .section-title-three .title {
    font-size: 32px;
    line-height: 36px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .section-title-three .title {
    font-size: 32px;
    line-height: 36px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .section-title-three .title {
    font-size: 32px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 767.98px) {
  .section-title-three .title {
    font-size: 28px;
  }
}
@media only screen and (max-width: 575.98px) {
  .section-title-three .title {
    font-size: 26px;
  }
}
@media only screen and (max-width: 375px) {
  .section-title-three .title {
    font-size: 24px;
    line-height: 32px;
  }
}
.section-title-three .title:hover::before {
  background-color: var(--sun-color-two);
}
.section-title-three .title::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -25px;
  height: 50px;
  width: 50px;
  background-color: var(--sun-color-one);
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .section-title-three .title::before {
    left: -10px;
    top: -10px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .section-title-three .title::before {
    left: -10px;
    /* top: 0; */
  }
}
.section-title-three .title::after {
  content: "";
  position: absolute;
  left: -45px;
  top: -25px;
  height: 30px;
  width: 30px;
  background-image: url(./assets/img/section-shapes4.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .section-title-three .title::after {
    left: -25px;
    top: -25px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .section-title-three .title::after {
    left: -20px;
    /* top: -10px; */
  }
}
.section-title-three .section-para {
  margin-top: 20px;
}

.section-title-btn .view-all {
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 400;
  color: var(--light-color);
  border: 1px solid var(--light-color);
  -webkit-transition: 300ms;
  transition: 300ms;
}
.section-title-btn .view-all:hover {
  background-color: var(--light-color);
  color: #fff;
}

/* Nice Select Style */
.js-select {
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 16px;
  font-weight: 400;
  color: var(--paragraph-color);
  border: 1px solid var(--input-color);
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.01);
          box-shadow: 0 0 10px rgba(255, 255, 255, 0.01);
  -webkit-transition: 300ms;
  transition: 300ms;
  padding: 0 30px 0 15px;
}

.js-select:focus {
  border: 1px solid var(--input-color);
}
.js-select .nice-select-search-box {
  height: 100%;
}
.js-select .nice-select-search-box .nice-select-search {
  vertical-align: top;
}
.js-select:after {
  border-bottom: 2px solid var(--light-color);
  border-right: 2px solid var(--light-color);
  height: 7px;
  margin-top: -2px;
  right: 20px;
  width: 7px;
}
@media only screen and (max-width: 375px) {
  .js-select:after {
    right: 10px;
  }
}
.js-select.open {
  border: 1px solid #ddd;
}
.js-select.open .list {
  width: 100%;
}
.js-select .list {
  width: 100%;
  margin-top: 10px;
}
.js-select .list .selected.focus {
  background-color: rgba(var(--main-color-one-rgb), 0.1);
  color: var(--main-color-one);
}
.js-select .list .option:hover {
  background-color: rgba(var(--main-color-one-rgb), 0.1);
  color: var(--main-color-one);
  font-weight: 500;
}

.js-select.select-style-two {
  display: block;
  color: var(--paragraph-color);
  border: 1px solid var(--input-color);
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
          box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}
.js-select.select-style-two.open::after {
  border-color: transparent transparent var(--paragraph-color);
  border-width: 0px 4px 5px 4px;
}
.js-select.select-style-two:after {
  border-color: var(--paragraph-color) transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: auto;
  right: 11px;
  margin-top: 0px;
  position: absolute;
  top: 50%;
  width: 0;
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
@media only screen and (max-width: 375px) {
  .js-select.select-style-two:after {
    right: 10px;
  }
}

/* Mouse Cursor Css */
.mouse-move {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
}

.mouse-inner {
  margin-left: -3px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  z-index: 10000001;
  background-color: var(--main-color-one);
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.mouse-inner.mouse-hover {
  margin-left: -30px;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  background-color: var(--main-color-one);
  opacity: 0.1;
}

.mouse-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  border: 2px solid var(--main-color-one);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10000000;
  opacity: 0.5;
  -webkit-transition: all 0.08s ease-out;
  transition: all 0.08s ease-out;
}
.mouse-outer.mouse-hover {
  opacity: 0;
}

.mouse-cursor-two .mouse-inner {
  background-color: var(--secondary-color);
}
.mouse-cursor-two .mouse-inner.mouse-hover {
  background-color: var(--secondary-color);
}
.mouse-cursor-two .mouse-outer {
  border-color: var(--secondary-color);
}

/* Slider Custom Css */


.nav-style-one {
  position: relative;
}
.nav-style-one:hover .prev-icon,
.nav-style-one:hover .next-icon {
  visibility: visible;
  opacity: 1;
}
.nav-style-one.nav-color-two .prev-icon,
.nav-style-one.nav-color-two .next-icon {
  background: #fff;
  color:var(--btn-txt-color);
  border: 2px solid var(--btn-txt-color);
}
.nav-style-one.nav-color-two .prev-icon:hover,
.nav-style-one.nav-color-two .next-icon:hover {
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  color:var(--btn-txt2-color);
}
.nav-style-one .prev-icon,
.nav-style-one .next-icon {
  position: absolute;
  left: -70px;
  top: 50%;
  /* IE 9 */
  -webkit-transform: translateY(-50%);
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  color: var(--btn-txt-color);
  background-color: #fff;
  border: 2px solid var(--btn-txt-color);
  
  -webkit-transition: 300ms;
  transition: 300ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 50px;
  width: 50px;
  visibility: visible;
  opacity: 0;
}
.nav-style-one .prev-icon:hover,
.nav-style-one .next-icon:hover {
  background: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  color:var(--btn-txt2-color);
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .nav-style-one .prev-icon,
.nav-style-one .next-icon {
    left: -25px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .nav-style-one .prev-icon,
.nav-style-one .next-icon {
    left: -25px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .nav-style-one .prev-icon,
.nav-style-one .next-icon {
    left: -25px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .nav-style-one .prev-icon,
.nav-style-one .next-icon {
    left: -25px;
  }
}
@media only screen and (max-width: 575.98px) {
  .nav-style-one .prev-icon,
.nav-style-one .next-icon {
    left: 0px;
  }
}
.nav-style-one .next-icon {
  left: auto;
  right: -70px;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .nav-style-one .next-icon {
    right: -25px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .nav-style-one .next-icon {
    right: -25px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .nav-style-one .next-icon {
    right: -25px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .nav-style-one .next-icon {
    right: -25px;
  }
}
@media only screen and (max-width: 575.98px) {
  .nav-style-one .next-icon {
    right: 0px;
  }
}

.append-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px 5px;
}

.append-attraction,
.append-history {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
}

.append-attraction.append-color-two .prev-icon:hover,
.append-attraction.append-color-two .next-icon:hover,
.append-history.append-color-two .prev-icon:hover,
.append-history.append-color-two .next-icon:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: #fff;
}
.append-attraction .prev-icon,
.append-attraction .next-icon,
.append-history .prev-icon,
.append-history .next-icon {
  font-size: 30px;
  cursor: pointer;
  border: 1px solid var(--gray-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 22px;
  z-index: 9;
  color: var(--heading-color);
  background-color: var(--gray-color);
  -webkit-transition: 300ms;
  transition: 300ms;
}
.append-attraction .prev-icon:hover,
.append-attraction .next-icon:hover,
.append-history .prev-icon:hover,
.append-history .next-icon:hover {
  border-color: var(--main-color-one);
  color: #fff;
  background-color: var(--main-color-one);
}
.append-attraction .prev-icon,
.append-history .prev-icon {
  right: 55px;
}
/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}


/* Dot Style */
.dot-style-one.dot-absolute .slick-dots {
  position: absolute;
  bottom: 30px;
  right: 0px;
}
@media only screen and (max-width: 480px) {
  .dot-style-one.dot-absolute .slick-dots {
    bottom: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .dot-style-one.dot-absolute .slick-dots {
    bottom: 10px;
  }
}
.dot-style-one.dot-color-two .slick-dots li {
  background-color: #DDD;
}
.dot-style-one.dot-color-two .slick-dots li.slick-active {
  background: var(--main-color-two);
}
.dot-style-one .slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 30px;
  padding: 10px 0 0;
}
.dot-style-one .slick-dots li {
  text-indent: -9999px;
  height: 10px;
  width: 10px;
  background: #DDD;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.dot-style-one .slick-dots li.slick-active {
  background: var(--main-color-one);
  width: 30px;
  border-radius: 10px;
}
.dot-style-one .slick-dots li button {
  border: 0;
  outline: none;
}

.slider-inner-margin .slick-slide {
  margin: 12px;
}
.slider-inner-margin .slick-list {
  margin: -12px;
}

.slider-inner-margin-10 .slick-slide {
  margin: 5px;
}
.slider-inner-margin-10 .slick-list {
  margin: -5px;
}

.slider-inner-padding .slick-slide {
  padding: 12px;
}
.slider-inner-padding .slick-list {
  padding: -12px;
}

/*---------------------------------------
    11. Faq
---------------------------------------*/
.faq-contents .faq-item {
  padding: 0;
  border: 1px solid var(--input-color);
  border-radius: 10px;
}
.faq-contents .faq-item.open {
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  padding: 0px;
}
.faq-contents .faq-item.open .faq-title {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.faq-contents .faq-item.open .faq-title::after {
  /* IE 9 */
  -webkit-transform: translateY(-50%) rotate(90deg);
  /* Chrome, Safari, Opera */
  transform: translateY(-50%) rotate(90deg);
}
.faq-contents .faq-item.open .faq-panel .faq-para {
  color: var(--light-color);
}
.faq-contents .faq-item.active .faq-panel {
  display: block;
}
.faq-contents .faq-item:not(:first-child) {
  margin-top: 24px;
}
.faq-contents .faq-item .faq-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--heading-color);
  font-family: var(--heading-font);
  padding: 20px;
  background: none;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  -webkit-transition: 300ms;
  transition: 300ms;
  margin-right: 30px;
}
.faq-contents .faq-item .faq-title::after {
  content: "\f105";
  font-family: "Line Awesome Free";
  font-weight: 900;
  position: absolute;
  right: -4px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 300ms;
  transition: 300ms;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .faq-contents .faq-item .faq-title {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .faq-contents .faq-item .faq-title {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .faq-contents .faq-item .faq-title {
    font-size: 22px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 575.98px) {
  .faq-contents .faq-item .faq-title {
    font-size: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .faq-contents .faq-item .faq-title {
    font-size: 18px;
  }
}
.faq-contents .faq-item .faq-panel {
  padding: 20px;
  display: none;
  border-top: 1px solid var(--input-color);
}
.faq-contents .faq-item .faq-panel .faq-para {
  font-size: 16px;
  line-height: 30px;
  color: var(--light-color);
}
@media only screen and (max-width: 767.98px) {
  .faq-contents .faq-item .faq-panel .faq-para {
    font-size: 16px;
  }
}
@media only screen and (max-width: 375px) {
  .faq-contents .faq-item .faq-panel .faq-para {
    font-size: 16px;
  }
}

.faq-question-border {
  border: 1px solid var(--input-color);
  padding: 20px;
}
.faq-question-title {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--input-color);
}

.faq-question-form .single-input:not(:first-child) {
  margin-top: 20px;
}

/* Custom Form */
.single-flex-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 24px;
}
@media only screen and (max-width: 480px) {
  .single-flex-input {
    display: block;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

.custom-form .single-input,
.custom-form .form-group {
  width: 100%;
}
.custom-form .single-input .label-title,
.custom-form .form-group .label-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
  color: var(--heading-color);
}
@media only screen and (max-width: 480px) {
  .custom-form .single-input .label-title,
.custom-form .form-group .label-title {
    font-size: 16px;
  }
}
@media only screen and (max-width: 375px) {
  .custom-form .single-input .label-title,
.custom-form .form-group .label-title {
    font-size: 16px;
  }
}
.custom-form .single-input .input-icon,
.custom-form .form-group .input-icon {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.custom-form .single-input .iti,
.custom-form .form-group .iti {
  width: 100%;
}
.custom-form .single-input .form--control,
.custom-form .single-input .form-control,
.custom-form .form-group .form--control,
.custom-form .form-group .form-control {
  -moz-appearance: textfield;
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding: 0 15px;
  border: 1px solid var(--input-color);
  background-color: unset;
  outline: none;
  color: var(--light-color);
  -webkit-transition: 300ms;
  transition: 300ms;
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}
.custom-form .single-input .form--control.input-padding-left,
.custom-form .single-input .form-control.input-padding-left,
.custom-form .form-group .form--control.input-padding-left,
.custom-form .form-group .form-control.input-padding-left {
  padding-left: 45px;
}
.custom-form .single-input .form--control#phone,
.custom-form .single-input .form-control#phone,
.custom-form .form-group .form--control#phone,
.custom-form .form-group .form-control#phone {
  width: 100%;
  padding-left: 50px;
}
.custom-form .single-input .form--control::-webkit-outer-spin-button, .custom-form .single-input .form--control::-webkit-inner-spin-button,
.custom-form .single-input .form-control::-webkit-outer-spin-button,
.custom-form .single-input .form-control::-webkit-inner-spin-button,
.custom-form .form-group .form--control::-webkit-outer-spin-button,
.custom-form .form-group .form--control::-webkit-inner-spin-button,
.custom-form .form-group .form-control::-webkit-outer-spin-button,
.custom-form .form-group .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;

}
.custom-form .single-input .form--control:focus,
.custom-form .single-input .form-control:focus,
.custom-form .form-group .form--control:focus,
.custom-form .form-group .form-control:focus {
  border-color: rgba(var(--main-color-one-rgb), 0.3);
  -webkit-box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
  box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
}
@media only screen and (max-width: 480px) {
  .custom-form .single-input .form--control,
.custom-form .single-input .form-control,
.custom-form .form-group .form--control,
.custom-form .form-group .form-control {
    font-size: 16px;
  }
}
@media only screen and (max-width: 375px) {
  .custom-form .single-input .form--control,
.custom-form .single-input .form-control,
.custom-form .form-group .form--control,
.custom-form .form-group .form-control {
    font-size: 16px;
  }
}
.custom-form .single-input .form-message,
.custom-form .form-group .form-message {
  height: 100px;
  line-height: 28px;
  padding: 15px;
}
.custom-form .submit-btn {
  margin-top: 25px;
}

.submit-btn {
  background-color: unset;
  color: var(--btn-txt-color);
  border: 2px solid var(--btn-txt-color);
  padding: 12px 20px;
  -webkit-transition: 300ms;
  transition: 300ms;
}
.submit-btn:hover { 
  border: 2px solid var(--secondary-color);
  background-color: var(--secondary-color);
  color: var(--btn-txt2-color);
}

.checkbox-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  gap: 10px;
}
.checkbox-inline .check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 0px;
  margin-top: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.checkbox-inline .check-input::after {
  content: "\f00c";
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 10px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(1.6) rotate(90deg);
  transform: scale(1.6) rotate(90deg);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.checkbox-inline .check-input:checked {
  background: var(--main-color-one);
  border-color: var(--main-color-one);
  background: var(--main-color-one);
}
.checkbox-inline .check-input:checked::after {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1.2) rotate(0deg);
  transform: scale(1.2) rotate(0deg);
}
.checkbox-inline .checkbox-label {
  cursor: pointer;
  text-align: left;
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: var(--heading-color);
  margin: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media only screen and (max-width: 575.98px) {
  .checkbox-inline .checkbox-label {
    font-size: 16px;
  }
}
.checkbox-inline .checkbox-label a {
  color: var(--main-color-one);
  font-weight: 500;
}

/* Breadcrumb area */
.breadcrumb-area {
  position: relative;
  background-color: var(--input-color);
}

.breadcrumb-padding {
  padding: 30px 0;
}

.breadcrumb-border {
  border-top: 2px solid rgba(221, 221, 221, 0.4);
}

.breadcrumb-contents {
  background: #fff;
  padding: 20px 20px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
  border-radius: 8px;
}
.breadcrumb-contents-title {
  font-size: 24px;
  line-height: 32px;
  color: var(--heading-color);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media only screen and (max-width: 575.98px) {
  .breadcrumb-contents-title {
    font-size: 20px;
    line-height: 28px;
  }
}
.breadcrumb-contents-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: -5px;
}
.breadcrumb-contents-list-item {
  font-size: 16px;
  font-weight: 500;
  color: var(--paragraph-color);
  position: relative;
  -webkit-transition: 200ms;
  transition: 200ms;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .breadcrumb-contents-list-item {
    font-size: 16px;
  }
}
.breadcrumb-contents-list-item:hover {
  color: var(--main-color-one);
}
.breadcrumb-contents-list-item:not(:last-child) {
  padding-right: 20px;
}
@media only screen and (max-width: 480px) {
  .breadcrumb-contents-list-item:not(:last-child) {
    padding-right: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .breadcrumb-contents-list-item:not(:last-child) {
    padding-right: 10px;
  }
}
.breadcrumb-contents-list-item:not(:last-child)::after {
  position: absolute;
  right: -8px;
  top: 50%;
  /* IE 9 */
  -webkit-transform: translateY(-50%);
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
  content: "\f105";
  font-family: "Line Awesome Free";
  font-weight: 900;
}
.breadcrumb-contents-list-item:not(:first-child) {
  padding-left: 20px;
}
@media only screen and (max-width: 480px) {
  .breadcrumb-contents-list-item:not(:first-child) {
    padding-left: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .breadcrumb-contents-list-item:not(:first-child) {
    padding-left: 10px;
  }
}
/* Ui Range Slider */
.price-range-slider {
  padding: 0 10px;
}
.price-range-slider .ui-range-slider.noUi-target {
  position: relative;
  border-radius: 3px;
  height: 3px;
}
.price-range-slider .ui-range-slider.noUi-target .noUi-handle {
  top: -11px;
  left: -3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  z-index: 1;
  cursor: pointer;
  background: var(--main-color-one);
}
.price-range-slider .ui-range-slider.noUi-target .noUi-handle.noUi-handle-upper::before {
  background-color: var(--main-color-one);
}
.price-range-slider .ui-range-slider.noUi-target .noUi-handle::before {
  content: "";
  position: absolute;
  background: #fff;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: inline-block;
}
.price-range-slider .ui-range-slider.noUi-target .noUi-base .noUi-origin {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.price-range-slider .ui-range-slider.noUi-target .noUi-base .noUi-connect {
  background: var(--main-color-one);
}
.price-range-slider .ui-range-slider-footer {
  display: table;
  width: 100%;
  padding-top: 20px;
}
.price-range-slider .ui-range-slider-footer .ui-range-values {
  display: inline-block;
  color: var(--heading-color);
}
.price-range-slider .ui-range-slider-footer .ui-range-values .ui-price-title {
  font-size: 18px;
  font-weight: 400;
  margin-right: 10px;
  color: var(--light-color);
}
.price-range-slider .ui-range-slider-footer .ui-range-values .ui-range-value-min,
.price-range-slider .ui-range-slider-footer .ui-range-values .ui-range-value-max {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  color: var(--main-color-one);
}
.noUi-background {
  background: #DDDDDD;
}

[dir=rtl] .price-range-slider .ui-range-slider.noUi-target .noUi-base .noUi-origin {
  right: auto;
  left: 0;
}

[dir=rtl] .price-range-slider .ui-range-slider.noUi-target .noUi-base .noUi-connect {
  background: var(--main-color-one);
}

/* Pagination style */
.pagination-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
}
.pagination-list-item-button {
  display: inline-block;
  font-size: 16px;
  color: var(--paragraph-color);
  border: 1px solid var(--input-color);
  background-color: var(--input-color);
  line-height: 1;
  padding: 9px 15px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.pagination-list-item-button:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.pagination-list-item:hover .pagination-list-item-link {
  background-color: var(--main-color-one);
  color: #fff;
}
.pagination-list-item.active .pagination-list-item-link {
  background-color: var(--main-color-one);
  color: #fff;
}
.pagination-list-item-link {
  display: inline-block;
  font-size: 16px;
  color: var(--paragraph-color);
  border: 1px solid #fff;
  background-color: #fff;
  line-height: 1;
  padding: 9px 15px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/* Global Ratings */
@media only screen and (max-width: 375px) {
  .center-text .rating-wrap {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.rating-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rating-wrap .ratings {
  position: relative;
  z-index: 0;
  width: 80px;
  height: 16px;
  display: inline-block;
}
@media only screen and (max-width: 480px) {
  .rating-wrap .ratings {
    width: 75px;
  }
}
@media only screen and (max-width: 375px) {
  .rating-wrap .ratings {
    width: 70px;
  }
}
.rating-wrap .ratings span {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  line-height: 16px;
}
.rating-wrap .ratings span.hide-rating:after {
  color: var(--light-color);
}
.rating-wrap .ratings span.show-rating:after {
  color: #FABE50;
}
.rating-wrap .ratings span.show-rating {
  width: 88% !important;
}
.rating-wrap .ratings span:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f005" "\f005" "\f005" "\f005" "\f005";
  width: 100%;
  font-family: "Line Awesome Free";
  font-weight: 900;
}
.rating-wrap .total-ratings {
  margin-left: 5px;
}

/* Tabs Design */
.tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.tabs li {
  font-size: 16px;
  font-weight: 400;
  color: var(--light-color);
  line-height: 28px;
  cursor: pointer;
  -webkit-transition: 300ms;
  transition: 300ms;
}

/* Custom Radio Css */
.custom-radio-single {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.custom-radio-single.active label {
  font-weight: 600;
  color: var(--heading-color);
}
.custom-radio-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 12px 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.custom-radio label {
  font-size: 16px;
  color: var(--paragraph-color);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 14px 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.custom-radio input[type=radio] {
  border: 2px solid #DDD;
  background: #fff;
  clear: none;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  outline: 0;
  padding: 0 !important;
  text-align: center;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  -webkit-appearance: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 50%;
  position: relative;
  margin-right: 3px;
}
.custom-radio input[type=radio]:checked {
  border-color: var(--main-color-one);
}
.custom-radio input[type=radio]:checked::before {
  width: calc(100% - 9px);
  height: calc(100% - 9px);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  content: "";
  border-radius: 50%;
  background-color: var(--main-color-one);
  margin: 0px 0px 0;
  padding: 0px;
  line-height: 1;
}

/* Popup Modal */
.popup-fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.6);
          transform: translate(-50%, -50%) scale(0.6);
  z-index: 9992;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.popup-fixed.popup-active {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

.popup-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9991;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.popup-overlay.popup-active {
  visibility: visible;
  opacity: 1;
}

.popup-contents {
  max-width: 500px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  padding: 30px;
  margin: auto;
  border-radius: 10px;
}
.popup-contents-title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: var(--heading-color);
  margin: -4px 0 0;
}
.popup-contents-select {
  display: flow-root;
  margin-top: 25px;
}
.popup-contents-select-label {
  font-size: 16px;
  color: var(--heading-color);
  margin-bottom: 8px;
  font-weight: 500;
}
.popup-contents-btn {
  margin-top: 25px;
}

/*----------------------------------------------
    # Nav bar 
----------------------------------------------*/
.mobile-logo {
  display: none;
}

@media only screen and (max-width: 991px) {
  .mobile-logo {
    display: block;
  }

  .desktop-logo {
    display: none !important;
  }
}
.navbar-padding {
  padding: 20px 0;
}

.navbar-area {
  position: relative;
  z-index: 95;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .navbar-area {
    padding: 0;
  }
}
.navbar-area.nav-absolute {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0px;
  z-index: 99;
}
.navbar-area.navbar-border {
  border-bottom: 1px solid rgba(221, 221, 221, 0.5);
}
.navbar-area.white-nav .nav-container .navbar-collapse .navbar-nav li {
  color: #fff;
}
.navbar-area.white-nav .nav-container .nav-right-content ul li {
  color: #fff;
}
.navbar-area.white-nav .nav-container .nav-right-content ul li .track-icon-list .single-icon .icon {
  color: #fff;
}
.navbar-area.white-nav .nav-container .nav-right-content ul li .track-icon-list .single-icon.hover-color-two:hover .icon:hover {
  color: var(--main-color-two);
}
.navbar-area.white-nav .nav-container .nav-right-content ul li .login-account .accounts {
  color: #fff;
}
.navbar-area.white-nav .nav-container .nav-right-content ul li .login-account .accounts.hover-color-two:hover {
  color: var(--main-color-two);
}
.navbar-area.navbar-two {
  padding: 0;
}
.navbar-area.navbar-two .nav-container {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 20px;
  border-radius: 0 0 20px 20px;
}
.navbar-area.navbar-two .nav-container .navbar-collapse .navbar-nav li a:hover,
.navbar-area.navbar-two .nav-container .navbar-collapse .navbar-nav li a:active
{
  color: var(--secondary-color);
  background: none;
}
.navbar-area.navbar-two .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
  border-bottom: 5px solid var(--secondary-color);
}
.navbar-area.navbar-two .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover {
  background-color: var(--secondary-color);
  color: #fff;
}
.navbar-area .nav-container {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px 15px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .navbar-area .nav-container {
    padding: 20px 0;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-area .nav-container {
    padding: 15px 0;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .navbar-area .nav-container .logo-wrapper {
    height: 15px;
  }
}
.navbar-area .nav-container .logo-wrapper .logo img {
  max-width: 220px;
}


@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-area .nav-container .logo-wrapper .logo img {
    max-width: 160px;
  }
  .logo{
    max-width: 300px;
  }

}
@media (min-width: 300px) and (max-width: 991.98px) {
  .navbar-area .nav-container .logo-wrapper .logo img {
    max-width: 170px;
  }
  .logo{
    max-width: 100%;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav {
  display: block;
  width: 100%;
  text-align: center;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav.nav-margin-top {
  margin-top: 15px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav.desktop-center {
  text-align: center;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li {
  display: inline-block;
  font-size: 16px;
  font-family: var(--verdana-font);
  text-transform: capitalize;
  color: var(--heading-color);
  font-weight: 400;
  line-height: 32px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
  margin-left: 0px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    font-size: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    font-size: 16px;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li a {
  padding: 10px 30px 10px 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    padding: 7px 10px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    padding: 5px 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    padding: 5px 10px;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li a:hover {
  color: var(--main-color-one);
  background: none;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.current-menu-item {
  background: none;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children {
  padding-right: 0;
  position: relative;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children > a {
  padding: 10px 30px 10px 0;
  position: relative;
  z-index: 0;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children > a {
    padding: 10px 10px;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children > a::after {
  content: "\f107";
  font-family: "Line Awesome Free";
  font-weight: 900;
  /* IE 9 */
  -webkit-transform: translateY(-50%);
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  top: 1px;
  left: 4px;
  position: relative;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
  position: absolute;
  text-align: left;
  min-width: 220px;
  margin: 0;
  padding: 0;
  list-style: none;
  left: 0;
  top: 100%;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  z-index: 992;
  border-bottom: 5px solid var(--main-color-one);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu.column-count-two {
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 50px;
  -webkit-column-rule-style: solid;
     -moz-column-rule-style: solid;
          column-rule-style: solid;
  -webkit-column-rule-color: rgba(221, 221, 221, 0.3);
     -moz-column-rule-color: rgba(221, 221, 221, 0.3);
          column-rule-color: rgba(221, 221, 221, 0.3);
  -webkit-column-rule-width: 2px;
     -moz-column-rule-width: 2px;
          column-rule-width: 2px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu.column-count-two {
    -webkit-column-count: unset;
       -moz-column-count: unset;
            column-count: unset;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu.column-count-two li {
  margin-bottom: -1px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu.column-count-two li {
    margin-bottom: 0;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
  display: block;
  margin-left: 0;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: -1px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
    margin-bottom: 0;
  }
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:last-child {
  margin-bottom: -1px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li + li {
  border-top: 1px solid #e2e2e2;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
  display: block;
  padding: 10px 30px;
  background-color: #fff;
  white-space: nowrap;
  color: var(--heading-color);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children {
  position: relative;
  z-index: 0;
  padding-right: 0px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children > .sub-menu {
  left: 100%;
  top: 0px;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children > .sub-menu .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover > .sub-menu li:hover:before {
  color: #fff;
}
.navbar-area.white-nav .navbar-right-btn a {
  color: #fff;
}
.navbar-area.navbar-two .navbar-right-btn a:hover {
  color: var(--secondary-color);
}
.navbar-area.navbar-two .btn-wrapper .cmn-btn.btn-bg-1 {
  background-color: var(--secondary-color);
  color:var(--btn-txt2-color);
  border-color: var(--secondary-color);
}
.navbar-area.navbar-two .btn-wrapper .cmn-btn.btn-bg-1:hover {
  background-color: unset;
  color: var(--btn-txt-color);
  border-color: var(--btn-txt-color);
}

/* Navbar right Content */
.click-nav-right-icon {
  display: none;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .click-nav-right-icon {
    position: absolute;
    right: 50px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 30px;
    color: rgba(0, 0, 0, 0.7);
    display: block;
  }
  .click-nav-right-icon:hover {
    color: var(--heading-color);
  }
  .click-nav-right-icon.white-color {
    color: #fff;
  }
  .click-nav-right-icon.white-color:hover {
    color: #fff;
  }
}

@media (min-width: 300px) and (max-width: 991.98px) {
  .show-nav-content {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 300ms;
    transition: 300ms;
    margin-top: -50px;
    margin-left: auto;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .show-nav-content.show {
    visibility: visible;
    opacity: 1;
    margin-top: 30px;
  }
}

.single-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.navbar-right-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px 20px;
}

.navbar-right-btn a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: var(--heading-color);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.navbar-right-btn a:hover {
  color: var(--main-color-one);
  text-decoration: underline;
}

.navbar-author {
  position: relative;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.navbar-author:hover .navbar-author-wrapper {
  visibility: visible;
  opacity: 1;
  top: 100%;
  -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
          transform: matrix(1, 0, 0, 1, 0, 0);
}
.navbar-author-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
  cursor: pointer;
}
.navbar-author-thumb {
  max-width: 100px;
}
.navbar-author-thumb img {
  border-radius: 50%;
}
.navbar-author-name {
  position: relative;
}
.navbar-author-name-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--heading-color);
}
.navbar-author-wrapper {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 9998;
  max-width: 200px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 0 10px #f3f3f3;
  box-shadow: 0 0 10px #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transform: matrix(1, 0, -1, 1, 0, 0);
          transform: matrix(1, 0, -1, 1, 0, 0);
}
.navbar-author-wrapper-list-item {
  display: block;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
  color: var(--heading-color);
  border-bottom: 1px solid #f3f3f3;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.navbar-author-wrapper-list-item:hover {
  background-color: var(--main-color-one);
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .navbar-area.white-nav .nav-container .navbar-collapse .navbar-nav {
    background-color: var(--heading-color);
    margin-top:50px;
  }
  .navbar-area.white-nav .nav-container .navbar-collapse .navbar-nav li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-area.white-nav .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
    background-color: #202020 !important;
  }
  .navbar-area.white-nav .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
    border-color: rgba(255, 255, 255, 0.05) !important;
  }
  .navbar-area.white-nav .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
    background-color: transparent;
    color: #fff;
  }
  .navbar-area.white-nav .navbar-toggler {
    border: 1px solid rgba(255, 255, 255, 0.7);
  }
  .navbar-area.white-nav .navbar-toggler.active .navbar-toggler-icon::before, .navbar-area.white-nav .navbar-toggler.active .navbar-toggler-icon::after {
    background: rgba(255, 255, 255, 0.7);
  }
  .navbar-area.white-nav .navbar-toggler .navbar-toggler-icon {
    background: rgba(255, 255, 255, 0.7);
  }
  .navbar-area.white-nav .navbar-toggler .navbar-toggler-icon::before, .navbar-area.white-nav .navbar-toggler .navbar-toggler-icon::after {
    background: rgba(255, 255, 255, 0.7);
  }
  .navbar-area.white-nav .click-nav-right-icon {
    color: rgba(255, 255, 255, 0.7);
  }
  .navbar-area .navbar-toggler {
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-box-shadow: none;
            box-shadow: none;
    /* IE 9 */
    -webkit-transform: translateY(-50%);
    /* Chrome, Safari, Opera */
    transform: translateY(-50%);
    border: 1px solid rgba(0, 0, 0, 0.7);
    outline: none;
  }
  .navbar-area .navbar-toggler.active .navbar-toggler-icon {
    background: none;
  }
  .navbar-area .navbar-toggler.active .navbar-toggler-icon::before, .navbar-area .navbar-toggler.active .navbar-toggler-icon::after {
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .navbar-area .navbar-toggler.active .navbar-toggler-icon::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .navbar-area .navbar-toggler .navbar-toggler-icon {
    background: rgba(0, 0, 0, 0.7);
    display: inline-block;
    width: 25px;
    height: 2px;
    margin: 10px -4px 10px;
    position: relative;
  }
  .navbar-area .navbar-toggler .navbar-toggler-icon::before, .navbar-area .navbar-toggler .navbar-toggler-icon::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 25px;
    background: rgba(0, 0, 0, 0.7);
    top: -7px;
    left: 0;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
  .navbar-area .navbar-toggler .navbar-toggler-icon::after {
    top: auto;
    bottom: -7px;
  }
  .navbar-area .nav-container {
    position: relative;
    z-index: 0;
    min-height: 50px;
    padding: 20px 20px 30px !important;
  }
  .navbar-area .nav-container .responsive-mobile-menu {
    display: block;
    position: relative;
    width: 100%;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav {
    display: block;
    margin-top: 30px;
    background: var(--gray-color);
    -webkit-box-shadow: 0 0 30px rgba(221, 221, 221, 0.3);
    box-shadow: 0 0 30px rgba(221, 221, 221, 0.3);
    border-radius: 10px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    display: block;
    text-align: left;
    line-height: 30px;
    padding: 5px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    padding: 15px 20px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li:last-child {
    border-bottom: none;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
    margin-left: 0;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
    top: 25px;
    right: 20px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover {
    padding-bottom: 0;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover > .sub-menu {
    visibility: visible;
    height: auto;
    opacity: 1;
    background-color: transparent;
    border-bottom: none;
    padding-top: 10px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children > a {
    padding: 15px 20px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
    position: initial;
    display: block;
    width: 100%;
    border-top: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: 0;
    padding-bottom: 0;
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    max-height: 250px;
    overflow-y: scroll;
    -webkit-transition: all 500ms linear;
    transition: all 500ms linear;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .sub-menu .menu-item-has-children:before {
    content: "\f107";
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
    padding: 0;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li.menu-item-has-children:hover:before {
    color: #fff;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li + li {
    border-top: none;
  }
}
@media only screen and (max-width: 575px) {
  .navbar-area .nav-container .logo-wrapper .logo img {
    max-width: 160px;
  }
  .logo{
    max-width:100%;
  }
}
/*--------------------------------------------------------------
	04. Footer Style
--------------------------------------------------------------*/
.footer-area {
  position: relative;
  z-index: 1;
}
.footer-area-one {
  padding-top: 150px;
}

.footer-padding {
  padding: 25px 0;
}

.footer-border-round {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.footer-border-round::before {
  content: "";
  position: absolute;
  top: 200px;
  height: 100%;
  width: 130%;
  background-color: transparent;
  border-top: 2px solid rgba(221, 221, 221, 0.1);
  z-index: -1;
  border-radius: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .footer-border-round::before {
    width: 150%;
  }
}
@media only screen and (max-width: 575.98px) {
  .footer-border-round::before {
    width: 180%;
  }
}
@media only screen and (max-width: 480px) {
  .footer-border-round::before {
    width: 220%;
  }
}
@media only screen and (max-width: 375px) {
  .footer-border-round::before {
    width: 240%;
  }
}
.footer-border-round.white-color {
  border-color: #DDD;
}

.footer-contents-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px 10px;
}
@media only screen and (max-width: 767.98px) {
  .footer-contents-flex {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.footer-contents-logo {
  max-width: 600px;
  margin: auto;
}
.footer-contents-logo-img {
  max-width: 200px;
  display: inline-block;
}
.footer-contents-logo-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin: 7px 0 0;
}

.footer-widget-title {
  font-size: 28px;
  line-height: 32px;
}
@media only screen and (max-width: 767.98px) {
  .footer-widget-title {
    font-size: 24px;
    line-height: 28px;
  }
}
.footer-widget-nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 12px 30px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .footer-widget-nav-list {
    gap: 12px 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .footer-widget-nav-list {
    gap: 12px 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer-widget-nav-list {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 7px 20px;
  }
}
@media only screen and (max-width: 575.98px) {
  .footer-widget-nav-list {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 7px 15px;
  }
}
.footer-widget-nav-list-link {
  font-size: 16px;
  display: inline-block;
  font-weight: 500;
  color: var(--light-color);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.footer-widget-nav-list-link:hover {
  color: var(--main-color-one);
  text-decoration: underline;
}
.footer-widget-social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 12px 20px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .footer-widget-social-list {
    gap: 12px 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .footer-widget-social-list {
    gap: 10px;
  }
}
.footer-widget-social-list-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 35px;
  width: 35px;
  font-size: 18px;
  border-radius: 5px;
  background: #fff;
  color: var(--heading-color);
  -webkit-transition: 300ms;
  transition: 300ms;
}
@media only screen and (max-width: 575.98px) {
  .footer-widget-social-list-link {
    font-size: 16px;
    height: 35px;
    width: 35px;
  }
}
@media only screen and (max-width: 375px) {
  .footer-widget-social-list-link {
    height: 30px;
    width: 30px;
  }
}
.footer-widget-social-list-link:hover {
  color: var(--btn-txt-color);
  background: var(--main-color-one);
}
.footer-widget-form-single {
  position: relative;
  z-index: 2;
}
.footer-widget-form-control {
  height: 55px;
  width: 100%;
  background-color: var(--copyright-bg-1);
  border: 1px solid var(--copyright-bg-1);
  border-radius: 30px;
  padding: 0 20px;
  color: var(--light-color);
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.001);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.001);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.footer-widget-form-control:focus {
  border: 1px solid rgba(var(--main-color-one-rgb), 0.4);
}
.footer-widget-form button {
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 0;
  background-color: var(--main-color-one);
  color: #fff;
  padding: 0px 30px;
  border: 0;
  outline: none;
  border-radius: 30px;
  height: calc(100% - 10px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.footer-widget-form button:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

.footer-navbar {
  padding: 40px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-wrapper-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 48px 0px;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding: 100px 0;
}
@media only screen and (max-width: 767.98px) {
  .footer-wrapper-flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer-wrapper-flex {
    padding: 70px 0;
  }
}
.footer-wrapper-single:not(:last-child) {
  padding-right: 100px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .footer-wrapper-single:not(:last-child) {
    padding-right: 50px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .footer-wrapper-single:not(:last-child) {
    padding-right: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer-wrapper-single:not(:last-child) {
    padding-right: 0px;
  }
}
.footer-wrapper-single:not(:first-child) {
  padding-left: 100px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .footer-wrapper-single:not(:first-child) {
    padding-left: 50px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .footer-wrapper-single:not(:first-child) {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer-wrapper-single:not(:first-child) {
    border-left: unset;
    padding-left: 0;
  }
}

.copyright-area.copyright-border {
  border-top: 1px solid rgba(221, 221, 221, 0.2);
}
.copyright-area.copyright-border.white-color {
  border-color: rgba(221, 221, 221, 0.7);
}

.copyright-contents-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
}
@media only screen and (max-width: 767.98px) {
  .copyright-contents-flex {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.copyright-contents-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 15px 50px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.copyright-contents-single:hover .copyright-contents-single-icon {
  color: var(--main-color-one);
}
.copyright-contents-single-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
}
.copyright-contents-single-icon {
  font-size: 40px;
  color: var(--light-color);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.copyright-contents-single-details-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  display: block;
  color: var(--light-color);
}
.copyright-contents-single-details-link {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  margin-top: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.copyright-contents-single-details-link:hover {
  color: var(--main-color-one);
}
.copyright-contents-main {
  font-size: 16px;
  color: var(--light-color);
  text-align: center;
}
@media only screen and (max-width: 767.98px) {
  .copyright-contents-main {
    text-align: center;
    margin-top: 10px;
  }
}
.copyright-contents-main a {
  color: var(--main-color-one);
  font-weight: 500;
}

.footer-area-two .footer-widget-contents-para {
  color: rgba(255, 255, 255, 0.8);
}
.footer-area-two .footer-widget-form-control {
  background-color: #fff;
  border: 1px solid #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.001);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.001);
  padding-right: 130px;
}
.footer-area-two .footer-widget-form-control:focus {
  border: 2px solid var(--secondary-color);
}
.footer-area-two .footer-widget-form button {
  background-color: var(--secondary-color);
  color:var(--btn-txt2-color);
  border: 2px solid var(--secondary-color);
}
.footer-area-two .footer-widget-form button:hover {
  border: 2px solid var(--btn-txt-color);
  background-color: #fff;
  color:var(--btn-txt-color);
}
.footer-area-two .footer-widget-para {
  color: rgba(255, 255, 255, 0.8);
}
.footer-area-two .footer-widget-nav-list-link {
  color: rgba(255, 255, 255, 0.8);
}
.footer-area-two .footer-widget-nav-list-link:hover {
  color: var(--secondary-color);
  text-decoration: none;
}
.footer-area-two .footer-widget-social-list-link:hover {
  background-color: var(--secondary-color);
  color:var(--btn-txt2-color);
}
.footer-area-two .copyright-contents-single:hover .copyright-contents-single-icon {
  color: var(--btn-txt2-color);
  background-color: var(--secondary-color);
}
.footer-area-two .copyright-contents-single-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 50px;
  width: 50px;
  font-size: 30px;
  background-color: #fff;
  color: var(--secondary-color);
  border-radius: 50%;
}
.footer-area-two .copyright-contents-single-details-title {
  color: rgba(255, 255, 255, 0.8);
}
.footer-area-two .copyright-contents-single-details-link:hover {
  color: var(--secondary-color);
}
.footer-area-two .copyright-contents-main {
  color: rgba(255, 255, 255, 0.8);
}
.footer-area-two .copyright-contents-main a {
  color: var(--secondary-color);
}

/* Banner  */
.banner-area {
  position: relative;
  z-index: 1;
  padding: 0;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-area {
    padding-top: 100px !important;
  }
}
.banner-area-one {
  padding-top: 50px;
}

.banner-single {
  position: relative;
  z-index: 2;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .banner-single {
    padding-right: 50px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .banner-single {
    padding-right: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-single {
    padding-right: 30px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-single {
    padding-right: 0px;
  }
}
.banner-single-one.percent-padding {
  padding-left: 33%;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .banner-single-one.percent-padding {
    padding-left: 13%;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .banner-single-one.percent-padding {
    padding-left: 10%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-single-one.percent-padding {
    padding-left: 5%;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-single-one.percent-padding {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 575.98px) {
  .banner-single-one.percent-padding {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .banner-single-one.percent-padding {
    padding-left: 10px;
  }
}
.banner-single-content {
  padding: 100px 0;
  position: relative;
  z-index: 5;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-single-content {
    padding: 30px 0 0px 0px;
  }
}
.banner-single-content.text-white .banner-single-content-title {
  color: #fff;
}
.banner-single-content.text-white .banner-single-content-para {
  color: #fff;
}
.banner-single-content.text-white .banner-single-content-reviews-para {
  color: #fff;
}
.banner-single-content.text-white .banner-single-content-reviews-para span {
  color: #fff;
}
.banner-single-content-reviews {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.banner-single-content-reviews-icon {
  color: var(--secondary-color);
}
.banner-single-content-reviews-para {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  color: var(--paragraph-color);
}
.banner-single-content-reviews-para span {
  display: inline-block;
}
.banner-single-content-title {
  font-size: 64px;
  line-height: 74px;
  margin: -12px 0 0;
  position: relative;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .banner-single-content-title {
    font-size: 64px;
    line-height: 70px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .banner-single-content-title {
    font-size: 48px;
    line-height: 65px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-single-content-title {
    font-size: 32px;
    line-height: 55px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-single-content-title {
    font-size: 32px;
    line-height: 1.2;
  }
}
@media only screen and (max-width: 575.98px) {
  .banner-single-content-title {
    font-size: 26px;
  }
}
@media only screen and (max-width: 375px) {
  .banner-single-content-title {
    font-size: 26px;
  }
}
.banner-single-content-title:hover {
  color: var(--main-color-one);
}
.banner-single-content-title-shape {
  position: relative;
  z-index: 1;
}
.banner-single-content-title-shape::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(./assets/img/section-shapes2.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: -1;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-single-content-title-shape::before {
    top: 30px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-single-content-title-shape::before {
    top: 25px;
  }
}
@media only screen and (max-width: 480px) {
  .banner-single-content-title-shape::before {
    top: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .banner-single-content-title-shape::before {
    top: 15px;
  }
}
.banner-single-content-title-shape.star-shape {
  position: relative;
  z-index: 2;
}
.banner-single-content-title-shape.star-shape::before {
  background-image: url(./assets/img/banner/star-shape.svg);
  top: 20px;
  left: auto;
  right: -40%;
}
.banner-single-content-para {
  font-size: 16px;
  color: var(--light-color);
  font-weight: 400;
  line-height: 28px;
}
@media only screen and (max-width: 480px) {
  .banner-single-content-para {
    font-size: 16px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 375px) {
  .banner-single-content-para {
    font-size: 16px;
  }
}

.banner-right-bg {
  height: 670px;
  width: 47%;
  text-align: right;
  margin-left: auto;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-right-bg {
    width: 100%;
    height: 500px;
  }
}
@media only screen and (max-width: 767.98px) {
  .banner-right-bg {
    height: 400px;
  }
}
@media only screen and (max-width: 575.98px) {
  .banner-right-bg {
    height: 300px;
  }
}
@media only screen and (max-width: 480px) {
  .banner-right-bg {
    height: 260px;
  }
}
@media only screen and (max-width: 375px) {
  .banner-right-bg {
    height: 220px;
  }
}

.banner-right-new {
  height: 670px;
  width: 180%;
}

/* Banner Two Css */
.banner-area-two {
  padding-top: 170px;
}

.banner-shapes img {
  position: absolute;
}
.banner-shapes img:nth-child(1) {
  left: 10px;
  top: 0;
}
.banner-shapes img:nth-child(2) {
  right: 10px;
  top: 0;
}

.banner-bg {
  background-image: url('./assets/img/banner/banner-building-2.webp');
  background-size: cover;
  background-position: center;
  position: relative;
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.3);
}


.bg-banner-hotel {
  background-image: url('./assets/img/hotel/banner.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.banner-area-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 25px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-area-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.banner-area-flex .banner-single-content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.banner-thumb-wrap {
  position: relative;
  z-index: 2;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .banner-area-thumb {
    max-width: 500px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-area-thumb {
    max-width: 400px;
  }
}

.banner-thumb-shape img {
  position: absolute;
}
.banner-thumb-shape img:nth-child(1) {
  right: 0;
  top: 0;
}
.banner-thumb-shape img:nth-child(2) {
  right: -100px;
  bottom: 130px;
}
.banner-thumb-shape img:nth-child(3) {
  left: -50px;
  top: 50%;
  /* IE 9 */
  -webkit-transform: translateY(-50%);
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
}

/* Banner Three Css */
.banner-area-three {
  padding-top: 140px;
}
.banner-area-three .banner-area-flex {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 50px;
}
.banner-area-three .banner-single-content {
  text-align: center;
  padding: 0;
  max-width: 850px;
  margin: auto;
}

/* Custom scroll bar */
.dfddfdd {
  max-height: 400px;
  overflow-y: auto;
}

.dfddfdd {
  overflow-y: scroll;
  scrollbar-color: #0A4C95 transparent;
  scrollbar-width: 5px;
  -ms-overflow-style: none;
  -moz-appearance: none !important;
  scrollbar-width: thin;
}

.dfddfdd::-moz-scrollbar {
  width: 5px;
  border: none;
}

.dfddfdd::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border: none;
}

.dfddfdd::-webkit-scrollbar-track-piece {
  background-color: #C2D2E4;
}

.dfddfdd::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: #0A4C95;
}

/* Location area Css */
.location-area {
  margin-top: -61px;
  z-index: 9;
  position: relative;
}

.banner-location {
  -webkit-box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
  box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
  padding: 20px;
}
.banner-location-one {
  width: 1140px;
  position: relative;
  z-index: 990;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-location-one {
    width: 900px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-location-one {
    width: 100%;
  }

  .location-area {
    margin-top: 0px;
  }
}
.banner-location-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  gap: 10px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-location-flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-location-flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.banner-location-single {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner-location-single {
    -ms-flex-preferred-size: 28%;
        flex-basis: 28%;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .banner-location-single {
    -ms-flex-preferred-size: 28%;
        flex-basis: 28%;
  }
}
@media only screen and (max-width: 767.98px) {
  .banner-location-single {
    -ms-flex-preferred-size: 45%;
        flex-basis: 45%;
  }
}
@media only screen and (max-width: 575.98px) {
  .banner-location-single {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}
@media only screen and (max-width: 575.98px) {
  .banner-location-single:nth-last-of-type(1), .banner-location-single:nth-last-of-type(2) {
    -ms-flex-preferred-size: 45%;
        flex-basis: 45%;
  }
}
.banner-location-single-search-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 50px;
  width: 50px;
  font-size: 28px;
  background-color: var(--main-color-one);
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}
.banner-location-single-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
}
.banner-location-single-icon {
  font-size: 28px;
}
.banner-location-single-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.banner-location-single-contents-dropdown {
  font-family: var(--heading-font);
  font-weight: 500;
}
.banner-location-single-contents-dropdown .js-select {
  font-weight: 500;
}
.banner-location-single-contents-subtitle {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin: -4px 0 0;
  margin-bottom: 10px;
  font-family: var(--heading-font);
}

/* Search Suggestions */
.search-suggestions-wrapper {
  position: relative;
  z-index: 98;
}
.search-suggestions-wrapper .search-click-icon {
  cursor: pointer;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 55px;
  width: 55px;
  color: var(--btn-txt-color);
    border: 2px solid var(--btn-txt-color);
  font-weight: 500;
  -webkit-transition: 300ms;
  transition: 300ms;
  border-radius: 5px;



}
.search-suggestions-wrapper .search-click-icon:hover {
  color: var(--btn-txt2-color);
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
}

.search-show {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 400ms;
  transition: 400ms;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .search-show {
    right: 0px;
    max-width: 500px;
  }
}
@media only screen and (max-width: 767.98px) {
  .search-show {
    width: 500px;
  }
}
@media only screen and (max-width: 575.98px) {
  .search-show {
    max-width: 450px;
  }
}
@media only screen and (max-width: 480px) {
  .search-show {
    max-width: 340px;
  }
}
@media only screen and (max-width: 375px) {
  .search-show {
    max-width: 270px;
  }
}
.search-show.open {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.search-show-inner {
  position: relative;
}
.search-show-form {
  position: relative;
  z-index: 1;
}
.search-show-form .suggestions-icon-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0%;
  top: 0;
  font-size: 24px;
  background-color: rgba(221, 221, 221, 0.3);
  height: 100%;
  padding: 0 15px;
  -webkit-transition: 300ms;
  transition: 300ms;
}
.search-show-form .suggestions-icon-close:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.search-show-form .form--control {
  padding: 0 15px;
  padding-left: 50px;
  padding-right: 70px;
  font-size: 16px;
  height: 55px;
  width: 700px;
  color: var(--extra-light-color);
  border: 1px solid rgba(221, 221, 221, 0.5);
  -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.01);
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.01);
  -webkit-transition: 300ms;
  transition: 300ms;
}
.search-show-form .form--control:focus {
  -webkit-box-shadow: 0 0 30px rgba(var(--main-color-one-rgb), 0.1);
  box-shadow: 0 0 30px rgba(var(--main-color-one-rgb), 0.1);
  border-color: rgba(var(--main-color-one-rgb), 0.3);
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .search-show-form .form--control {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
@media only screen and (max-width: 767.98px) {
  .search-show-form .form--control {
    width: 100%;
  }
}
.search-show-form button {
  position: absolute;
  top: 0;
  left: 15px;
  font-size: 20px;
  background: none;
  border: 0;
  outline: none;
  color: var(--light-color);
  bottom: 0;
}

.search-product {
  position: absolute;
  top: 100%;
  right: 0;
  width: 700px;
  background: #fff;
  padding: 30px 20px;
  -webkit-box-shadow: 0 0 10px rgba(221, 221, 221, 0.4);
          box-shadow: 0 0 10px rgba(221, 221, 221, 0.4);
  visibility: hidden;
  opacity: 0;
  height: 550px;
  overflow-y: hidden;
}
@media (min-width: 1600px) and (max-width: 1849.98px) {
  .search-product {
    height: 475px;
  }
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .search-product {
    height: 475px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .search-product {
    height: 475px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product {
    height: 475px;
    padding: 30px 10px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .search-product {
    height: 485px;
  }
}
@media only screen and (max-width: 767.98px) {
  .search-product {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .search-product {
    padding: 30px 10px;
  }
}
.search-product.show {
  visibility: visible;
  opacity: 1;
}
.search-product .nicescroll-rails .nicescroll-cursors {
  background-color: var(--main-color-two) !important;
}
.search-product-title {
  font-size: 18px;
  width: 100%;
  background-color: rgba(221, 221, 221, 0.2);
  padding: 5px 10px;
}
.search-product-list {
  height: calc(100vh - 210px);
  overflow-y: scroll;
  padding: 0 10px;
}
.search-product-list-item:not(:last-child) {
  margin-bottom: 20px;
}
.search-product-list-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}
@media only screen and (max-width: 480px) {
  .search-product-list-link {
    display: block;
  }
}
.search-product-list-image {
  border: 1px solid rgba(221, 221, 221, 0.5);
  padding: 5px;
  height: 100px;
  width: 100px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product-list-image {
    width: 80px;
    height: 100px;
    padding: 10px 0px;
  }
}
.search-product-list-info {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media only screen and (max-width: 480px) {
  .search-product-list-info-top {
    margin-top: 10px;
  }
}
.search-product-list-info-title {
  font-size: 18px;
  line-height: 28px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product-list-info-title {
    font-size: 17px;
  }
}
.search-product-list-info-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 20px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product-list-info-price {
    gap: 7px;
  }
}
.search-product-list-info-price-through {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
.search-product-list-info-price-through .flash-price {
  font-size: 18px;
  font-weight: 600;
  color: var(--heading-color);
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product-list-info-price-through .flash-price {
    font-size: 16px;
  }
}
.search-product-list-info-price-through .old-price {
  font-size: 16px;
  text-decoration: line-through;
}
.search-product-list-info-price .main-price {
  font-size: 16px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product-list-info-price .main-price {
    font-size: 17px;
  }
}
.search-product-list-info .stock-out {
  font-size: 16px;
  background: #ff0000;
  color: #fff;
  padding: 5px 10px;
  display: inline-block;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-product-list-info .stock-out {
    font-size: 16px;
    padding: 5px 5px;
  }
}

.search-suggestion-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 92;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 500ms;
  transition: 500ms;
}
.search-suggestion-overlay.show {
  visibility: visible;
  opacity: 1;
}

/* Provide css */
.single-why {
  padding-top: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-why {
    padding: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-why {
    padding: 20px;
  }
}

.single-why-border {
  border: 2px solid var(--gray-color);
}
.single-why-icon img {
  max-width: 100px;
  width: 100px;
}
.single-why-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 15px;
}

.single-why-contents{
  height: 35px;
}
.single-why-contents-title {
  font-size: 20px;
  line-height: 1.3;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-why-contents-title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 575.98px) {
  .single-why-contents-title {
    font-size: 26px;
  }
}
@media only screen and (max-width: 480px) {
  .single-why-contents-title {
    font-size: 24px;
  }
}
.single-why-contents-para {
  font-size: 16px;
  line-height: 10px;
  color: var(--btn-txt-color);
}

/* Why Two Css */
.single-why-two {
  background-color: var(--secondary-color);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 1px #0000004a;
  position: relative;
  z-index: 1;
  
}
.single-why-two::before {
  content: "";
  position: absolute;
  top: 50.2%;
  left: 50%;
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Chrome, Safari, Opera */
  transform: translate(-50%, -50%);
  height: 0%;
  width: 0%;
  z-index: -1;
  background-color: var(--main-color-one);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius:4px;
}
.single-why-two-contents-title{
  color:var(--btn-txt-color) !important;
}
.single-why-two:hover {
  background-color: #fff;
  border-color: var(--btn-txt-color);
  color:var(--btn-txt-color);
}
.single-why-two:hover::before {
  background-color: #fff;
  visibility: visible;
  opacity: 1;
  height: 100%;
  width: 100%;
}
.single-why-two:hover .single-why-two-icon {
  background-color: #fff;
  color: var(--main-color-one);
}
.single-why-two:hover .single-why-two-contents-title {
  color: var(--btn-txt-color);
}
.single-why-two:hover .single-why-two-contents-para {
  color: #fff;
}
.single-why-two-border {
  border: 0px solid var(--gray-color);
}
.single-why-two-flex {
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: center;
    gap: 15px;
}
@media only screen and (max-width: 375px) {
  .single-why-two-flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

.single-why-two-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: auto;
  width: 75px;
  font-size: 24px;
  background-color: rgba(var(--main-color-one-rgb), 0.2);
  color: var(--main-color-one);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.single-why-two-contents {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.single-why-two-contents-title {
  font-size: 16px;
  line-height: 28px;
  margin: -4px 0 0;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.single-why-two-contents-title:hover {
  color: var(--main-color-one);
}


.single-why-two-contents-para {
  font-size: 16px;
  line-height: 24px;
  color: var(--paragraph-color);
}



/* Attraction css */
.single-attraction-border {
  border: 2px solid rgba(221, 221, 221, 0.7);
}
.single-attraction:hover .single-attraction-thumb img {
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Chrome, Safari, Opera */
  transform: scale(1.1);
}
.single-attraction-thumb {
  overflow: hidden;
}
.single-attraction-thumb img {
  border-radius: 20px 20px 0 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-attraction-thumb img {
    width: 100%;
  }
}
.single-attraction-contents {
  border: 1px solid rgba(221, 221, 221, 0.7);
  padding: 20px;
  border-radius: 0 0 20px 20px;
}
.single-attraction-contents-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: -4px 0 0;
}
.single-attraction-contents-para {
  font-size: 16px;
  line-height: 24px;
  color: var(--paragraph-color);
  margin-top: 12px;
}

.single-attraction-two {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin:1rem
}
.single-attraction-two:hover .single-attraction-two-contents {
  visibility: visible;
  opacity: 1;
  z-index: 9;
  bottom: 0;
}
.single-attraction-two-thumb img {
  border-radius: 20px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-attraction-two-thumb img {
    width: 100%;
  }
}
.single-attraction-two-contents {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding: 25px 10px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-color: var(--secondary-color) transparent;
  -ms-overflow-style: none;
  -moz-appearance: none !important;
  scrollbar-width: thin;
}
.single-attraction-two-contents::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
  border: none;
  border-radius: 10px;
}
.single-attraction-two-contents::-webkit-scrollbar-track {
  background-color: #C2D2E4;
  border-radius: 10px;
}
.single-attraction-two-contents::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 10px;
}
.single-attraction-two-contents-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: -4px 0 0;
  color: #fff;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.single-why:hover {
  border-color: var(--btn-txt-color);
}
.single-attraction-two-contents-para {
  font-size: 16px;
  line-height: 24px;
  color: var(--paragraph-color);
  margin-top: 12px;
  color: rgba(255, 255, 255, 0.8);
}

/* Guest Css */


.justify-content-center {
  /* justify-content: center !important; */
  align-content: center;
}

.guest-wrapper {
  position: relative;
  z-index: 2;
}
.guest-wrapper-shapes img {
  position: absolute;
  top: 0%;
  left: 0;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .guest-wrapper-images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 20px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .custom-tab{
    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;
  }
  .single-guest-two-flex {
    display: flex !important;
    flex-direction: column !important;
    text-align: center;
}
.single-guest-two-flex2 {
  display: flex !important;
  flex-direction: column !important;
  text-align: center;
  gap: 15px !important;
}

.single-guest-two-flex {
  display: flex !important;
  flex-direction: column-reverse !important;
  text-align: center;
  gap: 10px !important;
}
}
@media only screen and (max-width: 480px) {
  .guest-wrapper-images {
    gap: 10px;
  }

  .single-guest-two-flex {
    display: flex !important;
    flex-direction: column-reverse !important;
}
.single-guest-two-flex2 {
  display: flex !important;
  flex-direction: column-reverse !important;
}

}
.guest-wrapper-images-single {
  position: absolute;
  opacity: 0.5;
  cursor: pointer;
  border-radius: 50%;
  max-width: 100px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.guest-wrapper-images-single.active {
  opacity: 1;
  -webkit-box-shadow: 0 0 30px rgba(var(--main-color-one-rgb), 0.3);
  box-shadow: 0 0 30px rgba(var(--main-color-one-rgb), 0.3);
  border: 4px solid #fff;
}
.guest-wrapper-images-single:nth-child(1) {
  left: 0;
  top: 0;
}
.guest-wrapper-images-single:nth-child(2) {
  right: 0;
  top: 0;
  max-width: 85px;
}
.guest-wrapper-images-single:nth-child(3) {
  left: 150px;
  top: 45%;
  max-width: 75px;
}
.guest-wrapper-images-single:nth-child(4) {
  right: 150px;
  top: 45%;
  max-width: 75px;
}
.guest-wrapper-images-single:nth-child(5) {
  left: 0;
  bottom: 0;
  max-width: 65px;
}
.guest-wrapper-images-single:nth-child(6) {
  right: 0;
  bottom: 0;
  max-width: 65px;
}
.guest-wrapper-images-single img {
  border-radius: 50%;
}

@media (min-width: 300px) and (max-width: 991.98px) {
  .guest-wrapper-images-single {
    position: unset;
  }
  .guest-wrapper-images-single
  {
    max-width: 65px !important;
  }
  
}

.tab-item {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-100%) scale(0);
          transform: translateY(-100%) scale(0);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 0;
}

.tab-area .tab-item.active {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0%) scale(1);
          transform: translateY(0%) scale(1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 100%;
}

@-webkit-keyframes slideAnimation {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideAnimation {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.single-guest.center-text .single-guest-thumb {
  margin: auto;
}
.single-guest-thumb {
  max-width: 100px;
}
.single-guest-thumb img {
  border-radius: 50%;
}

.single-guest-three {
  padding: 0px;
  margin:1rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.single-guest-two {
  padding: 10px;
  margin:1rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.single-guest-one {
  padding: 20px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.single-guest-two-border {
  border: 2px solid var(--gray-color);
  color:var(--btn-txt-color)
}

.single-guest-two-borderbox {
  border: 2px solid var(--gray-color);
  box-shadow: 0px 0px 10px 5px #00000066;
}

.single-guest-two:hover {
  border-color: var(--btn-txt-color);
  color:var(--btn-txt-color);
}

.single-guest-three:hover {

  color: var(--btn-txt2-color);
  background-color: var(--secondary-color);
}
.single-guest-two-contents-country-name2 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.single-guest-two-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 5px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
          flex-direction: row;
}
.single-guest-two-flex2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 60px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
          flex-direction: row;
}
.single-guest-two-thumb {
  max-width: 150px;
}

.single-guest-two-thumb2 img {
  border-radius: 4px 0 0 4px;
}

.single-guest-two-thumb2 {
  max-width: 86px;
}

.single-guest-two-contents-title {
  font-size: 18px;
  font-weight: 500;
  color:var(--btn-txt-color);
}

.single-guest-two-contents-country {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 5px;
  margin-top: 7px;
}
.single-guest-two-contents-country-name {
  font-size: 16px;
  line-height: 24px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.single-guest-two-para {
  font-size: 16px;
  line-height: 24px;
  color: var(--paragraph-color);
}

@media (min-width: 300px) and (max-width: 991.98px) {

  .single-guest-two-thumb2 img {
    border-radius: 0 !important;
  }

  .single-guest-three {
    padding: 10px !important;
  }
}

/* Shots Gallery Css */

.masonry-grid{
  position: relative; 
  height: 668px;
}
.grid-item:nth-child(1){
  position: absolute;
  left: 0%; 
  top: 0px;
}
.grid-item:nth-child(2){
  position: absolute;
  left: 16.5909%; 
  top: 0px;
}
.grid-item:nth-child(3){
  position: absolute;
  left: 49.9242%; 
  top: 0px;
}
.grid-item:nth-child(4){
  position: absolute;
  left: 83.2576%; 
  top: 0px;
}
.grid-item:nth-child(5){
  position: absolute;
  left: 16.5909%; 
  top: 217px;
}
.grid-item:nth-child(6){
  position: absolute;
  left: 0%; 
  top: 217px;
}
.grid-item:nth-child(7){
  position: absolute;
  left: 49.9242%; 
  top: 431px;
}
.grid-item:nth-child(8){
  position: absolute;
  left: 83.2576%; 
  top: 257px;
}

.grid-item:nth-child(1) .single-shots {
  height: 190px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {


  .grid-item:nth-child(5){
    position: absolute;
    left: 16.5909%; 
    top: 188px;
  }
  .grid-item:nth-child(6){
    position: absolute;
    left: 0%; 
    top: 185px;
  }
  .grid-item:nth-child(7){
    position: absolute;
    left: 49.9242%; 
    top: 370px;
  }
  .grid-item:nth-child(8){
    position: absolute;
    left: 83.2576%; 
    top: 221px;
  }

  .grid-item:nth-child(1) .single-shots {
    height: 160px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid-item:nth-child(1) .single-shots {
    height: 135px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .grid-item:nth-child(1) .single-shots {
    height: 210px;
  }
  .grid-item .single-shots.bg-image {
    background-size: cover !important;
}
.masonry-grid{
  height: auto !important;
}

}
@media only screen and (max-width: 767.98px) {
  .grid-item:nth-child(1) .single-shots {
    height: 235px;
  }
}
.grid-item:nth-child(2) .single-shots {
  height: 190px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .grid-item:nth-child(2) .single-shots {
    height: 163px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid-item:nth-child(2) .single-shots {
    height: 135px;
  }

  .grid-item:nth-child(5) {
    position: absolute;
    left: 16.5909%;
    top: 160px;
  }

  .grid-item:nth-child(6) {
    position: absolute;
    left: 0%;
    top: 160px;
  }

  .grid-item:nth-child(7) {
    position: absolute;
    left: 49.9242%;
    top: 310px;
  }

.grid-item:nth-child(8) {
  position: absolute;
  left: 83.2576%;
  top: 184px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .grid-item:nth-child(1){
    position: relative;
    left: 0%; 
    top: 0px;
  }
  .grid-item:nth-child(2){
    position: relative;
    left: 0%; 
    top: 0px;
  }
  .grid-item:nth-child(3){
    position: relative;
    left: 0%; 
    top: 0px;
  }
  .grid-item:nth-child(4){
    position: relative;
    left: 0%; 
    top: 0px;
  }

  .grid-item:nth-child(5) {
    position: relative;
    left: 0%;
    top: 0px;
  }

  .grid-item:nth-child(6) {
    position: relative;
    left: 0%;
    top: 0px;
  }

  .grid-item:nth-child(7) {
    position: relative;
    left: 0%;
    top: 0px;
  }

.grid-item:nth-child(8) {
  position: relative;
  left: 0%;
  top: 0px;
  }

  .grid-item:nth-child(2) .single-shots {
    height: 210px;
  }
}
@media only screen and (max-width: 767.98px) {
  .grid-item:nth-child(2) .single-shots {
    height: 235px;
  }
}
.grid-item:nth-child(3) .single-shots {
  height: 404px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .grid-item:nth-child(3) .single-shots {
    height: 345px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid-item:nth-child(3) .single-shots {
    height: 286px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .grid-item:nth-child(3) .single-shots {
    height: 210px;
  }
}
@media only screen and (max-width: 767.98px) {
  .grid-item:nth-child(3) .single-shots {
    height: 237px;
  }
}
.grid-item:nth-child(4) .single-shots {
  height: 230px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .grid-item:nth-child(4) .single-shots {
    height: 195px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid-item:nth-child(4) .single-shots {
    height: 170px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .grid-item:nth-child(4) .single-shots {
    height: 210px;
  }
}
@media only screen and (max-width: 767.98px) {
  .grid-item:nth-child(4) .single-shots {
    height: 235px;
  }
}
.grid-item:nth-child(5) .single-shots {
  height: 424px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .grid-item:nth-child(5) .single-shots {
    height: 360px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid-item:nth-child(5) .single-shots {
    height: 300px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .grid-item:nth-child(5) .single-shots {
    height: 210px;
  }
}
@media only screen and (max-width: 767.98px) {
  .grid-item:nth-child(5) .single-shots {
    height: 235px;
  }
}
.grid-item:nth-child(6) .single-shots {
  height: 424px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .grid-item:nth-child(6) .single-shots {
    height: 355px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid-item:nth-child(6) .single-shots {
    height: 295px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .grid-item:nth-child(6) .single-shots {
    height: 210px;
  }
}
@media only screen and (max-width: 767.98px) {
  .grid-item:nth-child(6) .single-shots {
    height: 235px;
  }
}
.grid-item:nth-child(7) .single-shots {
  height: 210px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .grid-item:nth-child(7) .single-shots {
    height: 180px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid-item:nth-child(7) .single-shots {
    height: 150px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .grid-item:nth-child(7) .single-shots {
    height: 210px;
  }
}
@media only screen and (max-width: 767.98px) {
  .grid-item:nth-child(7) .single-shots {
    height: 235px;
  }
}
.grid-item:nth-child(8) .single-shots {
  height: 384px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .grid-item:nth-child(8) .single-shots {
    height: 325px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid-item:nth-child(8) .single-shots {
    height: 286px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .grid-item:nth-child(8) .single-shots {
    height: 210px;
  }
}
@media only screen and (max-width: 767.98px) {
  .grid-item:nth-child(8) .single-shots {
    height: 235px;
  }
}
.grid-item .single-shots {
  height: 190px;
  border-radius: 10px;
}
.grid-item .single-shots.bg-image {
  background-size: contain;
}
@media only screen and (max-width: 575.98px) {
  .grid-item:nth-child(1) .single-shots {
    height: 400px;
  }
  .grid-item:nth-child(2) .single-shots {
    height: 400px;
  }
  .grid-item:nth-child(3) .single-shots {
    height: 400px;
  }
  .grid-item:nth-child(4) .single-shots {
    height: 400px;
  }
  .grid-item:nth-child(5) .single-shots {
    height: 400px;
  }
  .grid-item:nth-child(6) .single-shots {
    height: 400px;
  }
  .grid-item:nth-child(7) .single-shots {
    height: 400px;
  }
  .grid-item:nth-child(8) .single-shots {
    height: 400px;
  }
  
}
.grid-item .single-shots img {
  border-radius: 10px;
}

.single-shots {
  position: relative;
  z-index: 2;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
}
.single-shots::before {
  content: "";
  position: absolute;
  top: -100%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 0;
}
.single-shots:hover::before {
  visibility: visible;
  opacity: 1;
  top: 0;
}
.single-shots:hover .single-shots-icon {
  visibility: visible;
  opacity: 1;
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%) rotateX(180deg);
  /* Chrome, Safari, Opera */
  transform: translate(-50%, -50%) rotateX(180deg);
}
.single-shots:hover .single-shots-contents {
  visibility: visible;
  opacity: 1;
  z-index: 9;
  bottom: 0;
}
.single-shots-icon {
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: var(--main-color-one);
  position: absolute;
  top: 50%;
  left: 50%;
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Chrome, Safari, Opera */
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 91;
}
.single-shots-icon:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.single-shots-contents {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding: 25px 10px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-color: var(--main-color-one) transparent;
  -ms-overflow-style: none;
  -moz-appearance: none !important;
  scrollbar-width: thin;
}
.single-shots-contents::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
  border: none;
  border-radius: 10px;
}
.single-shots-contents::-webkit-scrollbar-track {
  background-color: #C2D2E4;
  border-radius: 10px;
}
.single-shots-contents::-webkit-scrollbar-thumb {
  background-color: var(--main-color-one);
  border-radius: 10px;
}
.single-shots-contents-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: -4px 0 0;
  color: #fff;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.single-shots-contents-para {
  font-size: 16px;
  line-height: 24px;
  color: var(--paragraph-color);
  margin-top: 12px;
  color: rgba(255, 255, 255, 0.8);
}

/* Brand Logo Css */
.single-brand-thumb img {
  margin: auto;
}

/* Newsletter Css */
.newsletter-area {
  margin-bottom: -150px;
}

.newsletter-area .container.container-fluid {
  width: 100%;
  max-width: 100%;
  padding: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.newsletter-area .container.container-fluid .newsletter-wrapper {
  border-radius: 0;
}

.newsletter-area .container {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.newsletter-wrapper {
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.newsletter-wrapper-padding {
  padding: 50px;
}
@media only screen and (max-width: 480px) {
  .newsletter-wrapper-padding {
    padding: 30px;
  }
}
@media only screen and (max-width: 375px) {
  .newsletter-wrapper-padding {
    padding: 30px 20px;
  }
}
.newsletter-wrapper-shapes img {
  position: absolute;
  z-index: -1;
}
.newsletter-wrapper-shapes img:nth-child(1) {
  left: 0;
  top: 0;
}
.newsletter-wrapper-shapes img:nth-child(2) {
  right: 0;
  top: 0;
}

.newsletter-bg {
  background-color: var(--main-color-one);
}

.newsletter-contents {
  max-width: 600px;
  margin: auto;
}
.newsletter-contents-title {
  font-size: 36px;
  line-height: 42px;
  color: #fff;
  font-weight: 500;
  margin: -7px 0 0;
}
@media only screen and (max-width: 767.98px) {
  .newsletter-contents-title {
    font-size: 32px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 575.98px) {
  .newsletter-contents-title {
    font-size: 28px;
  }
}
@media only screen and (max-width: 480px) {
  .newsletter-contents-title {
    font-size: 26px;
  }
}
@media only screen and (max-width: 375px) {
  .newsletter-contents-title {
    font-size: 24px;
  }
}
.newsletter-contents-para {
  color: #fff;
}
.newsletter-contents-form .single-input {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.newsletter-contents-form .single-input .form--control {
  height: 55px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background-color: #fff;
  color: var(--light-color);
  outline: none;
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 20px;
  width: 100%;
  padding-right: 100px;
}
.newsletter-contents-form .single-input .form--control::-webkit-input-placeholder {
  color: var(--light-color);
}
.newsletter-contents-form .single-input .form--control::-moz-placeholder {
  color: var(--light-color);
}
.newsletter-contents-form .single-input .form--control:-ms-input-placeholder {
  color: var(--light-color);
}
.newsletter-contents-form .single-input .form--control::-ms-input-placeholder {
  color: var(--light-color);
}
.newsletter-contents-form .single-input .form--control::placeholder {
  color: var(--light-color);
}
.newsletter-contents-form .single-input .form--control:focus {
  -webkit-box-shadow: 0 0 10px rgba(var(--secondary-color-rgb), 0.1);
  box-shadow: 0 0 10px rgba(var(--secondary-color-rgb), 0.1);
  border: rgba(var(--secondary-color-rgb), 0.3);
}
.newsletter-contents-form .single-input button {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  border: 0;
  outline: none;
  padding: 5px 20px;
  background-color: var(--secondary-color);
  color: #fff;
}

/* Sign In Sign Up */
.login-padding {
  padding: 30px;
}
@media only screen and (max-width: 480px) {
  .login-padding {
    padding: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .login-padding {
    padding: 20px 10px;
  }
}

.single-title {
  font-size: 28px;
  line-height: 36px;
  margin: -6px 0 0;
}

.single-para {
  font-size: 16px;
  line-height: 24px;
  margin: 5px 0 0;
  color: var(--paragraph-color);
}

.login-shadow {
  -webkit-box-shadow: 0 0 10px var(--input-color);
  box-shadow: 0 0 10px var(--input-color);
}

.login-wrapper-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 25px 50px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .login-wrapper-flex {
    gap: 25px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .login-wrapper-flex {
    gap: 25px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.login-wrapper-thumb {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .login-wrapper-thumb {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
  }
}
.login-wrapper-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  max-width: 550px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .login-wrapper-contents {
    max-width: 480px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .login-wrapper-contents {
    max-width: 480px;
  }
}
.input-flex-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media only screen and (max-width: 480px) {
  .input-flex-item {
    display: block;
  }
}
.input-flex-item .single-input {
  width: 100%;
}
.input-flex-item .single-input:not(:first-child) {
  margin-left: 24px;
}
@media only screen and (max-width: 480px) {
  .input-flex-item .single-input:not(:first-child) {
    margin-left: 0;
  }
}
.input-flex-item .single-input .form--control {
  border: 1px solid rgba(221, 221, 221, 0.4);
}

.single-input {
  display: inline-block;
  width: 100%;
}
.single-input .form--control {
  border: 1px solid rgba(221, 221, 221, 0.4);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 0 0 10px transparent;
          box-shadow: 0 0 10px transparent;
}
.single-input .form--control:focus {
  border-color: rgba(var(--main-color-one-rgb), 0.3);
  -webkit-box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
          box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
}
.single-input .form--message {
  padding: 15px 0 0 15px;
}

.label-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--heading-color);
}
@media only screen and (max-width: 480px) {
  .label-title {
    font-size: 16px;
  }
}

.single-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px;
}

.or-contents {
  text-align: center;
  position: relative;
  z-index: 2;
}
.or-contents::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  background: rgba(221, 221, 221, 0.4);
  width: 100%;
  height: 1px;
  z-index: -1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.or-contents-para {
  background: #fff;
  padding: 10px 30px;
  color: var(--light-color);
}

.login-others-single:not(:last-child) {
  margin-bottom: 20px;
}
.login-others-single-btn {
  padding: 13px 10px;
  display: inline-block;
  border: 1px solid rgba(221, 221, 221, 0.4);
  color: var(--heading-color);
  font-weight: 500;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.login-others-single-btn:hover {
  border-color: var(--main-color-one);
  color: var(--heading-color);
  background-color: rgba(var(--main-color-one-rgb), 0.1);
}
.login-others-single-btn img {
  margin-right: 5px;
}

/* Toggle Password hide Show */
.single-input {
  position: relative;
}

.toggle-password {
  position: absolute;
  bottom: 13px;
  right: 20px;
  cursor: pointer;
}

.hide-icon {
  display: none;
}

.toggle-password.show-pass .hide-icon {
  display: block;
}

.toggle-password.show-pass .show-icon {
  display: none;
}

/* History Css */
.history-wrapper-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.single-history-year {
  position: relative;
  z-index: 2;
}
.single-history-year::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 15px;
  border-top: 2px dotted var(--light-color);
  z-index: -1;
}
.single-history-year-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  margin: -4px 0 20px;
  background-color: #fff;
  padding: 0 10px;
}
.single-history-thumb img {
  margin: auto;
}
.single-history-contents {
  margin-top: 15px;
}
.single-history-contents-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.single-history-contents-title:hover {
  color: var(--main-color-one);
}
.single-history-contents-para {
  font-size: 16px;
  line-height: 24px;
  color: var(--paragraph-color);
  margin-top: 5px;
}

/* Team Css */
.single-team-thumb {
  position: relative;
}
.single-team {
  padding: 1rem 2rem;
}
.single-team-thumb img {
  height: 100%;
  width: 100%;
  mask-image: url(./assets/img/single-page/team-mask.png);
  -webkit-mask-image: url(./assets/img/single-page/team-mask.png);
  mask-mode: alpha;
  -webkit-mask-mode: alpha;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center top;
  mask-position: center top;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
.single-team-contents {
  margin-top: 20px;
}
.single-team-contents-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
/* Theme One Blog */
.single-blog {
  border-radius: 10px;
  overflow: hidden;
}
.single-blog:hover .single-blog-thumbs img {
  /* IE 9 */
  -webkit-transform: scaleY(1.1);
  /* Chrome, Safari, Opera */
  transform: scale(1.1);
}
.single-blog.blog-two .single-blog-thumbs-date {
  background-color: var(--secondary-color);
}
.single-blog.blog-two .single-blog-contents-tags-item i {
  color: var(--secondary-color);
}
.single-blog.blog-two .single-blog-contents-tags-item a:hover {
  color: var(--secondary-color);
}
.single-blog.blog-two .single-blog-contents-tags-item:not(:first-child)::before {
  background-color: var(--secondary-color);
}
.single-blog-thumbs {
  position: relative;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: 300ms;
  transition: 300ms;
}
.single-blog-thumbs img {
  -webkit-transition: 300ms;
  transition: 300ms;
}
.single-blog-thumbs-date {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  background-color: var(--secondary-color);
  color: var(--btn-txt2-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 5px 15px;
  border-radius: 5px;
}
.single-blog-thumbs-date a {
  display: block;
  text-align: center;
}
.single-blog-thumbs-date .date {
  border-bottom: 1px solid var(--btn-txt2-color);
  display: block;
}
.single-blog-contents-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  transition: 0ms;
  transition: 0ms;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.single-blog-contents-title2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  transition: 0ms;
  transition: 0ms;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.single-blog-contents-title:hover{
  color:var(--secondary-color);
}

.single-blog-contents-title2:hover{
  color:var(--secondary-color);
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-blog-contents-title {
    font-size: 22px;
    line-height: 28px;
  }

  .single-blog-contents-title2 {
    font-size: 20px;
    line-height: 26px;
  }
}

.single-blog-contents-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.single-blog-contents-tags-item {
  font-size: 16px;
  color: var(--light-color);
  position: relative;
}
.single-blog-contents-tags-item i {
  color: var(--main-color-one);
}
.single-blog-contents-tags-item a:hover {
  color: var(--main-color-one);
}
.single-blog-contents-tags-item:not(:last-child) {
  padding-right: 20px;
}
.single-blog-contents-tags-item:not(:first-child) {
  padding-left: 20px;
}
.single-blog-contents-tags-item:not(:first-child)::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: var(--main-color-one);
  border-radius: 50%;
  top: 50%;
  left: -5px;
  /* IE 9 */
  -webkit-transform: translateY(-50%);
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
}

.flex-blog-height {
  width: 580px;
  height: 420px;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .flex-blog-height {
    width: 400px;
    height: 300px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .flex-blog-height {
    width: 320px;
    height: 280px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .flex-blog-height {
    width: 250px;
    height: 290px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .flex-blog-height {
    width: 250px;
    height: 290px;
  }
}
@media only screen and (max-width: 375px) {
  .flex-blog-height {
    width: 100%;
  }
}

.flex-blog-height-two {
  width: 400px;
  height: 350px;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .flex-blog-height-two {
    width: 220px;
    height: 320px;
  }
}
@media only screen and (max-width: 480px) {
  .flex-blog-height-two {
    width: 100%;
  }
}

.single-flex-blog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-flex-blog {
    gap: 15px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-flex-blog {
    gap: 15px;
  }
}
@media only screen and (max-width: 575.98px) {
  .single-flex-blog {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    display: block !important;
  }
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-flex-blog.style-02 {
    gap: 20px;
  }
}
.single-flex-blog.style-02 .single-flex-blog-contents-title {
  font-size: 26px;
  line-height: 36px;
}
@media only screen and (max-width: 480px) {
  .single-flex-blog.style-02 .single-flex-blog-contents-title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  .single-flex-blog.style-02 {
    display: block;
  }
}
@media only screen and (max-width: 767.98px) {
  .single-flex-blog.style-02 .single-flex-blog-thumbs {
    margin-bottom: 20px;
    margin: 0 0 30px;
  }
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-flex-blog.style-02 .single-flex-blog-contents-para {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
}
@media only screen and (max-width: 575.98px) {
  .single-flex-blog-thumbs {
    margin-bottom: 20px;
    margin: 0 0 30px;
  }
}
.single-flex-blog-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.single-flex-blog-contents-dates {
  font-size: 16px;
  color: var(--extra-light-color);
  line-height: 28px;
}
.single-flex-blog-contents-title {
  font-size: 36px;
  line-height: 42px;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-flex-blog-contents-title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-flex-blog-contents-title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-flex-blog-contents-title {
    font-size: 26px;
    line-height: 32px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-flex-blog-contents-title {
    font-size: 26px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  .single-flex-blog-contents-title {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
}
@media only screen and (max-width: 480px) {
  .single-flex-blog-contents-title {
    font-size: 24px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-flex-blog-contents-para {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-flex-blog-contents-para {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-flex-blog-contents-para {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
}

/* Blog Details area */
.single-blog-details-thumb img {
  width: 100%;
}
.single-blog-details-content {
  padding: 30px 0 55px;
  border-bottom: 2px solid rgba(221, 221, 221, 0.5);
}
.single-blog-details-content-title {
  font-size: 36px;
  line-height: 42px;
  font-weight: 400;
  margin: -6px 0 0;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-blog-details-content-title {
    font-size: 32px;
    line-height: 36px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-blog-details-content-title {
    font-size: 32px;
    line-height: 36px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-blog-details-content-title {
    font-size: 32px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 575.98px) {
  .single-blog-details-content-title {
    font-size: 28px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 480px) {
  .single-blog-details-content-title {
    font-size: 26px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 375px) {
  .single-blog-details-content-title {
    font-size: 24px;
  }
}
.single-blog-details-content-para {
  font-size: 16px;
  line-height: 28px;
  color: var(--light-color);
}
.single-blog-details-content-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.single-blog-details-content-tags-item {
  font-size: 16px;
  color: var(--extra-light-color);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.single-blog-details-content-tags-item:not(:last-child) {
  padding-right: 20px;
}
.single-blog-details-content-tags-item:not(:first-child) {
  padding-left: 20px;
}
.single-blog-details-content-tags-item:not(:first-child)::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  left: -5px;
  top: 50%;
  /* IE 9 */
  -webkit-transform: translateY(-50%);
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: var(--main-color-one);
  border-radius: 50%;
}
.single-blog-details-content-tags-item a {
  position: relative;
  font-weight: 500;
  color: var(--light-color);
  -webkit-transition: 200ms;
  transition: 200ms;
}
.single-blog-details-content-tags-item a:hover {
  color: var(--main-color-one);
}
.single-blog-details-content-tags-item a:not(:last-child)::after {
  content: ",";
  margin: 0 5px;
}

blockquote {
  padding: 50px;
  position: relative;
  border-left: 5px solid var(--main-color-one);
}
@media (min-width: 300px) and (max-width: 991.98px) {
  blockquote {
    padding: 20px;
  }
}
@media only screen and (max-width: 375px) {
  blockquote {
    padding: 15px;
  }
}
blockquote .blockquote-contents-title {
  font-size: 36px;
  line-height: 50px;
  font-weight: 400;
  margin: -10px 0 0;
  color: var(--heading-color);
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  blockquote .blockquote-contents-title {
    font-size: 32px;
    line-height: 45px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  blockquote .blockquote-contents-title {
    font-size: 28px;
    line-height: 42px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  blockquote .blockquote-contents-title {
    font-size: 26px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 767.98px) {
  blockquote .blockquote-contents-title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 575.98px) {
  blockquote .blockquote-contents-title {
    font-size: 22px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 480px) {
  blockquote .blockquote-contents-title {
    font-size: 18px;
  }
}
@media only screen and (max-width: 375px) {
  blockquote .blockquote-contents-title {
    font-size: 16px;
    line-height: 28px;
  }
}

/* Blog Details Tag */
.blog-details-share-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .blog-details-share-content {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.blog-details-share-content.right-align {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .blog-details-share-content.right-align {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.blog-details-share-content-title {
  font-size: 24px;
  line-height: 28px;
  color: var(--heading-color);
  font-weight: 400;
  margin-top: 2px;
}
.blog-details-share-content .blog-details-share-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
.blog-details-share-content .blog-details-share-social-list-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 3px;
  height: 40px;
  width: 40px;
  font-size: 20px;
  background-color: #f2f2f2;
  color: var(--heading-color);
  -webkit-transition: 200ms;
  transition: 200ms;
}
.blog-details-share-content .blog-details-share-social-list-icon:hover {
  background-color: var(--secondary-color);
  color: var(--btn-txt2-color);
}
.blog-details-share-content .blog-details-tag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
.blog-details-share-content .blog-details-tag-list-item {
  font-size: 16px;
  border: 1px solid rgba(221, 221, 221, 0.5);
  padding: 10px 20px;
  color: var(--heading-color);
  border-radius: 3px;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.blog-details-share-content .blog-details-tag-list-item:hover {
  background-color: var(--main-color-one);
  color: #fff;
}

/* Blog Details Comment area */
/* Comment Output */
.details-section-title {
  font-size: 36px;
  font-weight: 500;
  line-height: 50px;
  margin: -10px 0 0;
  color: var(--heading-color);
  position: relative;
  padding-bottom: 15px;
}
.details-section-title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 70px;
  background-color: var(--main-color-one);
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .details-section-title {
    font-size: 32px;
    line-height: 42px;
  }
}
@media only screen and (max-width: 480px) {
  .details-section-title {
    font-size: 28px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 375px) {
  .details-section-title {
    font-size: 26px;
  }
}

.details-comment-content .single-commetns {
  width: 100%;
  margin-top: 30px;
}
.details-comment-content .single-commetns .comment-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: var(--heading-color);
  display: block;
  margin-bottom: 15px;
}
.details-comment-content .single-commetns .form--control {
  height: 60px;
  border: 1px solid rgba(221, 221, 221, 0.4);
  outline: none;
  -webkit-box-shadow: 0 0 10px #fff;
  box-shadow: 0 0 10px #fff;
  font-size: 16px;
  line-height: 22px;
  color: var(--light-color);
  padding: 0 20px;
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.details-comment-content .single-commetns .form--control:focus {
  -webkit-box-shadow: 0 0 18px rgba(var(--main-color-one-rgb), 0.1);
  box-shadow: 0 0 18px rgba(var(--main-color-one-rgb), 0.1);
  border-color: rgba(var(--main-color-one-rgb), 0.3);
}
.details-comment-content .single-commetns .form--message {
  padding: 20px 20px;
  height: 160px;
}

.comments-flex-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 30px;
}
@media only screen and (max-width: 575.98px) {
  .comments-flex-item {
    display: block;
  }
}

.comment-list .comment-list {
  margin-left: 120px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .comment-list .comment-list {
    margin-left: 70px;
  }
}
@media only screen and (max-width: 767.98px) {
  .comment-list .comment-list {
    margin-left: 50px;
  }
}
@media only screen and (max-width: 375px) {
  .comment-list .comment-list {
    margin-left: 30px;
  }
}
.comment-list .blog-details-flex-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 40px 0;
  gap: 30px;
  border-bottom: 2px solid rgba(221, 221, 221, 0.5);
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .comment-list .blog-details-flex-content {
    gap: 20px;
  }
}
@media only screen and (max-width: 575.98px) {
  .comment-list .blog-details-flex-content {
    gap: 10px;
    padding: 30px 0 20px;
  }
}
@media only screen and (max-width: 480px) {
  .comment-list .blog-details-flex-content {
    display: block;
  }
}
.comment-list .blog-details-flex-content .blog-details-thumb {
  height: 80px;
  min-width: 80px;
  border-radius: 10px;
  display: block;
}
@media only screen and (max-width: 480px) {
  .comment-list .blog-details-flex-content .blog-details-thumb {
    width: 80px;
    margin-bottom: 15px;
  }
}
.comment-list .blog-details-flex-content .blog-details-thumb img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.comment-list .blog-details-flex-content .blog-details-content-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.comment-list .blog-details-flex-content .blog-details-content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}
@media only screen and (max-width: 375px) {
  .comment-list .blog-details-flex-content .blog-details-content-title {
    font-size: 18px;
  }
}
.comment-list .blog-details-flex-content .blog-details-content-para {
  font-size: 18px;
  font-weight: 400;
  color: var(--light-color);
  margin-top: 10px;
  line-height: 30px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .comment-list .blog-details-flex-content .blog-details-content-para {
    font-size: 16px;
  }
}
@media only screen and (max-width: 575.98px) {
  .comment-list .blog-details-flex-content .blog-details-content-para {
    font-size: 16px;
  }
}
@media only screen and (max-width: 375px) {
  .comment-list .blog-details-flex-content .blog-details-content-para {
    font-size: 16px;
  }
}
.comment-list .blog-details-flex-content .blog-details-content-date {
  color: var(--extra-light-color);
  margin-top: 5px;
  display: block;
}
.comment-list .blog-details-flex-content .blog-details-content .btn-replay {
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  background-color: var(--secondary-color);
  color: var(--btn-txt2-color);
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid var(--secondary-color);
  -webkit-transition: 300ms;
  transition: 300ms;
}
.comment-list .blog-details-flex-content .blog-details-content .btn-replay:hover {
  color: var(--btn-txt-color);
  background-color: #fff;
  border: 1px solid var(--btn-txt-color);
}

/* Recent Post */
.blog-details-side-item:not(:first-child) {
  margin-top: 50px;
}
.blog-details-side-inner {
  margin-top: 25px;
}
.blog-details-side-title.open .title::after {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.blog-details-side-title .title {
  cursor: pointer;
  position: relative;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  background: rgba(var(--main-color-one-rgb), 0.05);
  color: var(--heading-color);
  padding: 12px 20px;
  border-radius: 7px;
}
.blog-details-side-title .title.open::after {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.blog-details-side-title .title::after {
  content: "\f106";
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 16px;
  position: absolute;
  right: 20px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.recent-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}
.recent-list .item-search {
  position: relative;
  margin-bottom: 20px;
}
.recent-list .item-search .form--control {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border: 1px solid var(--input-color);
  background-color: unset;
  outline: none;
  color: var(--light-color);
  -webkit-transition: 300ms;
  transition: 300ms;
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  padding-right: 40px;
}
.recent-list .item-search .form--control:focus {
  border-color: rgba(var(--main-color-one-rgb), 0.3);
  -webkit-box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
  box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
}
@media only screen and (max-width: 480px) {
  .recent-list .item-search .form--control {
    font-size: 16px;
  }
}
@media only screen and (max-width: 375px) {
  .recent-list .item-search .form--control {
    font-size: 16px;
  }
}
.recent-list .item-search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  /* IE 9 */
  -webkit-transform: translateY(-50%);
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .recent-list-item {
    gap: 15px;
  }
}
.recent-list-item:not(:last-child) {
  margin-bottom: 20px;
}
.recent-list-thumb {
  height: auto;
  max-width: 120px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .recent-list-thumb {
    max-width: 110px;
  }
}
.recent-list-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.recent-list-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.tag-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
}
.tag-list-item.active .tag-list-link {
  background-color: var(--main-color-one);
  color: #fff;
  border-color: var(--main-color-one);
}
.tag-list-link {
  border: 1px solid #eeeeee;
  font-size: 16px;
  color: var(--light-color);
  padding: 7px 20px;
  display: inline-block;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  white-space: nowrap;
  border-radius: 5px;
}
.tag-list-link:hover {
  background-color: var(--main-color-one);
  color: #fff;
  border-color: var(--main-color-one);
}

/* Contact Css */
.contact-thumb img {
  border-radius: 20px;
}

.contact-map iframe {
  width: 100%;
  height: 400px;
  border-radius: 20px;
}

.contact-padding {
  padding: 30px;
}

.contact-wrapper {
  -webkit-box-shadow: 0 0 10px var(--input-color);
  box-shadow: 0 0 10px var(--input-color);
}

/* Shop Area */
.shop-area {
  overflow: hidden;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .shop-area .global-card.book-filter-padding {
    padding: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .shop-area .global-card.book-filter-padding {
    padding: 10px;
  }
}

.shop-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 40px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .shop-right {
    gap: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  .shop-right {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-top: 20px;
  }
}

.shop-contents-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .shop-contents-wrapper {
    display: block;
  }
}
.shop-contents-wrapper .shop-sidebar-content {
  width: 330px;
  margin-right: 30px;
  position: sticky;
  top: 0;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .shop-contents-wrapper .shop-sidebar-content {
    width: 300px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .shop-contents-wrapper .shop-sidebar-content {
    width: 250px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .shop-contents-wrapper .shop-sidebar-content {
    width: 375px;
    position: unset;
  }
}
.shop-contents-wrapper .shop-grid-contents {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .shop-contents-wrapper .shop-grid-contents {
    max-height: 1410px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .shop-contents-wrapper .shop-grid-contents {
    max-height: 1410px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.single-shop-left {
  padding: 25px 25px;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-shop-left {
    padding: 22px 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-shop-left {
    padding: 15px 20px 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-shop-left {
    padding: 15px 20px 20px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-shop-left {
    padding: 15px 20px 20px;
  }
}
.single-shop-left-title.open .title::after {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.single-shop-left-title .title {
  cursor: pointer;
  position: relative;
  font-size: 20px;
  line-height: 36px;
  margin: -4px 0 0;
}
.single-shop-left-title .title::after {
  content: "\f106";
  content: "\f106";
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 16px;
  position: absolute;
  right: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
.single-shop-left-list .item-search {
  position: relative;
  margin: 15px 0;
}
.single-shop-left-list .item-search .form--control {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border: 1px solid var(--input-color);
  background-color: unset;
  outline: none;
  color: var(--light-color);
  -webkit-transition: 300ms;
  transition: 300ms;
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  padding-right: 40px;
}
.single-shop-left-list .item-search .form--control:focus {
  border-color: rgba(var(--main-color-one-rgb), 0.3);
  -webkit-box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
  box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
}
@media only screen and (max-width: 480px) {
  .single-shop-left-list .item-search .form--control {
    font-size: 16px;
  }
}
@media only screen and (max-width: 375px) {
  .single-shop-left-list .item-search .form--control {
    font-size: 16px;
  }
}
.single-shop-left-list .item-search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  /* IE 9 */
  -webkit-transform: translateY(-50%);
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}
.single-shop-left-list .more-amenities {
  font-size: 16px;
  font-weight: 500;
  color: var(--main-color-one);
  text-decoration: underline;
  margin-top: 15px;
  font-family: var(--heading-font);
}
.single-shop-left-list .item {
  font-size: 16px;
  line-height: 30px;
  position: relative;
  text-align: left;
  z-index: 2;
  padding-left: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-shop-left-list .item::before {
    top: 9px;
  }
}
.single-shop-left-list .item a {
  display: block;
  padding: 7px 0;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-shop-left-list .item a {
    padding: 5px 0;
  }
}
.single-shop-left-list .item.show > .submenu {
  display: block;
}

.single-shop-left-list .item.active > a {
  color: var(--light-color);
  font-weight: 500;
}
.single-shop-left-list .item:hover > a {
  color: var(--main-color-one);
}
.single-shop-left-list .item:hover::before {
  border-color: var(--main-color-one);
}
.single-shop-left-list .item.menu-item-has-children {
  padding-left: 0;
}
.single-shop-left-list .item.menu-item-has-children:hover::after {
  color: var(--main-color-one);
}
.single-shop-left-list .item.menu-item-has-children::before {
  display: none !important;
  border: 0;
}
.single-shop-left-list .item.menu-item-has-children > .submenu {
  padding-left: 20px;
}
.single-shop-left-list .item .submenu {
  display: none;
}
.single-shop-left-list .item .submenu .list {
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
}
.single-shop-left-list .item .submenu .list::before {
  top: 6px;
  height: 18px;
  width: 18px;
  font-size: 12px;
}
.single-shop-left-list .item .submenu .list a {
  padding: 5px 0;
}
.single-shop-left-list .item.menu-item-has-children::after {
  content: "\f067";
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
  z-index: -1;
}
.single-shop-left-list .item.menu-item-has-children.open::after {
  content: "\f068";
}
.single-shop-left-list .item:last-child a {
  padding-bottom: 0;
}
.single-shop-left-filter-list {
  padding: 0;
}
.single-shop-left-filter-list .item {
  position: relative;
  z-index: 2;
  display: block;
  padding-left: 30px;
  margin-top: 10px;
  line-height: 30px;
}
.single-shop-left-filter-list .item.active::before {
  font-family: "Line Awesome Free";
  font-weight: 900;
  content: "\f00c";
  background: var(--main-color-one);
  border-color: var(--main-color-one);
}
.single-shop-left-filter-list .item a {
  font-size: 16px;
  line-height: 26px;
  color: var(--secondary-color);
}
.single-shop-left-filter-list .item::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  border: 1px solid rgba(221, 221, 221, 0.4);
  left: 0;
  top: 4px;
  background: none;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.shop-icon {
  display: none;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .shop-icon {
    display: block;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .shop-icon-sidebar {
    font-size: 24px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    width: 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: var(--main-color-one);
    -webkit-box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
    box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
    z-index: 95;
    color: #fff;
    margin-bottom: 20px;
  }
}

@media (min-width: 300px) and (max-width: 991.98px) {
  .shop-close-content {
    z-index: 1;
    position: absolute;
    left: -100%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    z-index: 99;
    background: #fff;
    max-width: 375px;
    display: block;
    padding: 20px;
    transition: all 0.5s;
  }
  .shop-close-content.active {
    visibility: visible;
    opacity: 1;
    left: 0;
  }
}
@media only screen and (min-width: 300px) and (max-width: 991.98px) and (max-width: 480px) {
  .shop-close-content {
    max-width: 320px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 991.98px) and (max-width: 375px) {
  .shop-close-content {
    max-width: 270px;
  }
}
.shop-close-content-icon {
  display: none;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .shop-close-content-icon {
    display: block;
    position: absolute;
    right: -40px;
    top: 0;
    font-size: 24px;
    color: #fff;
    background: var(--main-color-one);
    -webkit-box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
    box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    width: 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
}
@media only screen and (min-width: 300px) and (max-width: 991.98px) and (max-width: 375px) {
  .shop-close-content-icon {
    height: 28px;
    width: 28px;
    font-size: 20px;
    right: -28px;
  }
}

@media (min-width: 300px) and (max-width: 991.98px) {
  .responsive-overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 100%;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .responsive-overlay.active {
    visibility: visible;
    opacity: 1;
    z-index: 99;
    left: 0;
  }
}

/* Hotel List View Css */
.grid-list-contents-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 15px;
}
.grid-list-contents-para {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--paragraph-color);
}

.grid-list-view {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
.grid-list-view-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0;
}
.grid-list-view-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 50px;
  width: 50px;
  font-size: 24px !important;
  background-color: #fff;
  color: var(--light-color);
}
.grid-list-view-icons.active {
  background-color: rgba(var(--main-color-one-rgb), 0.2);
  color: var(--main-color-one);
  border-color: var(--main-color-one);
}

.hotel-view {
  overflow: hidden;
}
.hotel-view:hover .hotel-view-list-thumb::before, .hotel-view:hover .hotel-view-list-thumb::after {
  visibility: visible;
  opacity: 1;
  left: 100%;
}
.hotel-view:hover .hotel-view-list-thumb::after {
  left: auto;
  right: 100%;
}
.hotel-view-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}
@media only screen and (max-width: 767.98px) {
  .hotel-view-flex {
    display: -ms-grid;
    display: grid;
  }
}
.hotel-view-list-thumb {
  height: 326px;
  width: 300px;
  background-size: contain;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
  overflow: hidden;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .hotel-view-list-thumb {
    background-size: cover;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .hotel-view-list-thumb {
    width: 250px;
    height: 435px;
    background-size: cover;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .hotel-view-list-thumb {
    width: 250px;
    height: 360px;
    background-size: cover;
  }
}
@media only screen and (max-width: 767.98px) {
  .hotel-view-list-thumb {
    background-size: contain;
    margin: 0px auto 0;
    width: 100%;
    height: 561px;
  }
}
@media only screen and (max-width: 575.98px) {
  .hotel-view-list-thumb {
    height: 497px;
  }
}
@media only screen and (max-width: 480px) {
  .hotel-view-list-thumb {
    min-height: 250px;
    height: 382px;
  }
}
@media only screen and (max-width: 375px) {
  .hotel-view-list-thumb {
    min-height: 250px;
    height: 322px;
  }
}
.hotel-view-list-thumb:hover {
  /* IE 9 */
  -webkit-transform: scale(1.2);
  /* Chrome, Safari, Opera */
  transform: scale(1.2);
}
.hotel-view-list-thumb::before, .hotel-view-list-thumb::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 50px;
  left: -100%;
  top: 0;
  background-color: rgba(255, 255, 255, 0.1);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}
.hotel-view-list-thumb::after {
  left: auto;
  right: -100%;
}
.hotel-view-grid-thumb {
  height: 215px;
  width: 100%;
  background-size: contain;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

@media (min-width: 1400px) and (max-width: 1599.98px) {
  .hotel-view-grid-thumb {
    height: 213px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .hotel-view-grid-thumb {
    height: 178px;
    background-size: cover;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .hotel-view-grid-thumb {
    height: 148px;
    background-size: cover;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .hotel-view-grid-thumb {
    height: 157px;
  }
}
@media only screen and (max-width: 767.98px) {
  .hotel-view-grid-thumb {
    height: 240px;
  }
}
@media only screen and (max-width: 575.98px) {
  .hotel-view-grid-thumb {
    height: 257px;
    background-size: cover;
  }
}
@media only screen and (max-width: 480px) {
  .hotel-view-grid-thumb {
    height: 200px;
    background-size: cover;
  }
}
@media only screen and (max-width: 375px) {
  .hotel-view-grid-thumb {
    height: 170px;
    background-size: cover;
  }
}
.hotel-view-thumb {
  overflow: hidden;
}
.hotel-view-thumb:hover img {
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Chrome, Safari, Opera */
  transform: scale(1.1);
}
.hotel-view-thumb img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.hotel-view-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.hotel-view-contents-header {
  padding: 15px 20px 20px;  
}
.hotel-view-contents-review {
  background-color: rgba(var(--secondary-color-rgb), 0.1);
  padding: 5px 15px;
  border-radius: 5px;
  color: var(--secondary-color);
}
.hotel-view-contents-title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  margin-top: 10px;
}
.hotel-view-contents-location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 5px;
  margin-top: 10px;
}
.hotel-view-contents-location-icon {
  font-size: 20px;
  display: inline-block;
}
.hotel-view-contents-location-para {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.hotel-view-contents-middle {
  padding: 10px 20px;
  border-top: 1px solid var(--input-color);
  border-bottom: 1px solid var(--input-color);
}
.hotel-view-contents-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px 24px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .hotel-view-contents-flex {
    gap: 5px 15px;
  }
}
@media only screen and (max-width: 480px) {
  .hotel-view-contents-flex {
    gap: 5px 15px;
  }
}
.hotel-view-contents-icon {
  font-size: 20px;
  color: var(--light-color);
}
.hotel-view-contents-icon-title {
  font-size: 16px;
  line-height: 28px;
}
.hotel-view-contents-icon-more {
  color: var(--main-color-one);
  color: var(--main-color-one);
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.hotel-view-contents-icon-more:hover {
  color: var(--main-color-one);
}
.hotel-view-contents-bottom {
  padding: 15px 20px 15px;
}
.hotel-view-contents-bottom-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
}
.hotel-view-contents-bottom-title {
  color: var(--main-color-one);
  font-size: 24px;
  font-weight: 600;
}
.hotel-view-contents-bottom-title sub {
  font-size: 20px;
  font-weight: 500;
}
.hotel-view-contents-bottom-para {
  font-size: 16px;
  margin-top: 10px;
}

/* Hotel Details Css */
.details-contents {
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.details-contents-main-thumb {
  height: 356px;
  background-size: contain;
  border-radius: 10px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .details-contents-main-thumb {
    height: 305px;
    background-size: cover;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .details-contents-main-thumb {
    height: 230px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .details-contents-main-thumb {
    height: 286px;
    background-size: cover;
  }
}
@media only screen and (max-width: 575.98px) {
  .details-contents-main-thumb {
    height: 210px;
  }
}
@media only screen and (max-width: 480px) {
  .details-contents-main-thumb {
    height: 140px;
  }
}
@media only screen and (max-width: 375px) {
  .details-contents-main-thumb {
    height: 110px;
  }
}
.details-contents-header {
  padding: 20px 20px 0;
}
.details-contents-header-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 24px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 24px;
}
.details-contents-header-thumb {
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding: 0.5rem;
  color: var(--heading-color);
}
.details-contents-header-thumb:hover img {
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Chrome, Safari, Opera */
  transform: scale(1.1);
}
.details-contents-header-thumb:hover .more-photos {
  visibility: visible;
  opacity: 1;
  bottom: 10px;
  right: 10px;
}
.details-contents-header-thumb img {
  border-radius: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.details-contents-header-thumb .more-photos {
  position: absolute;
  bottom: -100px;
  right: -100px;
  background-color: var(--main-color-one);
  color: #fff;
  padding: 7px 20px;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* details tab */
.details-contents-tab .tab-content-item {
  padding: 20px;
}

.details-tab-border {
  border-bottom: 1px solid var(--input-color);
}
.details-tab li {
  position: relative;
  margin-left: 20px;
  padding-bottom: 15px;
  font-weight: 600;
  color: var(--paragraph-color);
}
.details-tab li::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: var(--main-color-one);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}
.details-tab li.active::before {
  width: 100%;
  visibility: visible;
  opacity: 1;
}
.details-tab li + li {
  margin-left: 0;
}
.details-tab li:not(:last-child) {
  margin-right: 20px;
}

.review-tab-contents-author-name {
  font-size: 20px;
  font-weight: 600;
}
.review-tab-contents-author-para {
  font-size: 16px;
  line-height: 24px;
}
.review-tab-contents-single:not(:first-child) {
  padding-top: 20px;
}
.review-tab-contents-single:not(:last-child) {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--input-color);
}

/* hotel Details Sidebar Css */
.hotel-details-widget {
  border-radius: 10px;
  overflow: hidden;
}
.hotel-details-widget-padding {
  padding: 20px;
}
.hotel-details-widget:not(:last-child) {
  margin-bottom: 25px;
}
.hotel-details-widget .hotel-view-grid-thumb {
  height: auto;
}

.details-sidebar-dropdown {
  display: flow-root;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--input-color);
}
.details-sidebar-dropdown-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.details-sidebar-dropdown-single:not(:first-child) {
  padding-left: 25px;
  border-left: 1px solid var(--input-color);
}
.details-sidebar-dropdown-single:not(:last-child) {
  padding-right: 25px;
}
.details-sidebar-dropdown-title {
  font-size: 16px;
  color: var(--paragraph-color);
  margin-bottom: 10px;
}
.details-sidebar-dropdown .js-select {
  border: 0;
  padding: 0;
  height: auto;
  line-height: 1;
  font-size: 16px;
  font-weight: 600;
  color: var(--heading-font);
  color: var(--heading-color);
}
.details-sidebar-dropdown .js-select::after {
  right: 0;
  margin-top: -2px;
}
.details-sidebar-quantity-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px 10px;
}
.details-sidebar-quantity-item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media only screen and (max-width: 480px) {
  .details-sidebar-quantity-item {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    -ms-flex-preferred-size: 48%;
        flex-basis: 48%;
  }
}
.details-sidebar-quantity-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.details-sidebar-quantity-field {
  border: 1px solid var(--input-color);
  padding: 10px 10px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.details-sidebar-quantity-field .quantity-input {
  border: 0;
  outline: none;
  background-color: unset;
  text-align: center;
  max-width: 40px;
  width: 100%;
  -moz-appearance: textfield;
}
.details-sidebar-quantity-field .quantity-input::-webkit-inner-spin-button, .details-sidebar-quantity-field .quantity-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.details-sidebar-quantity-field .minus,
.details-sidebar-quantity-field .plus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid var(--input-color);
  height: 25px;
  width: 25px;
  border-radius: 3px;
  font-size: 16px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
.details-sidebar-quantity-field .minus:hover,
.details-sidebar-quantity-field .plus:hover {
  color: var(--main-color-one);
  border-color: var(--main-color-one);
}
.details-sidebar-offer {
  background-color: #42C2FF;
  padding: 50px 30px;
  position: relative;
  z-index: 2;
}
.details-sidebar-offer-shapes {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: -1;
}

/* Checkout */
.summery-title {
  font-size: 36px;
  line-height: 43px;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .summery-title {
    font-size: 32px;
  }
}
@media only screen and (max-width: 575.98px) {
  .summery-title {
    font-size: 30px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 375px) {
  .summery-title {
    font-size: 28px;
  }
}

.checkout-title i {
  color: var(--main-color-one);
}

.checkout-single {
  padding: 20px;
}
.checkout-single:not(:last-child) {
  margin-bottom: 24px;
}
.checkout-single .checkout-title {
  font-size: 24px;
  font-weight: 600;
}

.click-open-form:hover {
  color: var(--main-color-one);
  font-weight: 500;
}

.checkout-form-open {
  max-width: 500px;
  background: #fff;
  height: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
}
.checkout-form-open.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  margin-top: 30px;
}

.checkout-signup-form-wrapper {
  max-width: 700px;
  height: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.checkout-signup-form-wrapper.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  margin-top: 30px;
  background: #fff;
}

.checkout-address-form-wrapper {
  max-width: 700px;
  height: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.checkout-address-form-wrapper.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  margin-top: 30px;
  background: #fff;
}

.create-accounts {
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  gap: 10px;
  color: var(--heading-color) !important;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .create-accounts {
    font-size: 18px;
  }
}
@media only screen and (max-width: 575.98px) {
  .create-accounts {
    font-size: 16px;
  }
}
.create-accounts:hover {
  color: var(--heading-color);
}
.create-accounts::before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  width: 20px;
  border: 1px solid #DDD;
  content: "";
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.create-accounts.active::before {
  content: "\f00c";
  font-family: "Line Awesome Free";
  font-weight: 900;
  background: var(--main-color-one);
  color: #fff;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
  border-color: var(--main-color-one);
}

.checkout-form .input-flex-item .single-input .form--message {
  height: 120px;
}
.checkout-form .checkbox-inlines .checkbox-label {
  color: var(--heading-color);
  font-size: 18px;
}

.checkout-order-summery {
  padding: 40px;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .checkout-order-summery {
    padding: 40px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .checkout-order-summery {
    padding: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .checkout-order-summery {
    padding: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .checkout-order-summery {
    padding: 30px;
  }
}
@media only screen and (max-width: 375px) {
  .checkout-order-summery {
    padding: 20px 10px;
  }
}

.checkout-cart-wrapper {
  max-height: 240px;
  overflow-y: auto;
}

.single-checkout-cart-items .single-check-carts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.single-checkout-cart-items .single-check-carts .check-cart-flex-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.single-checkout-cart-items .single-check-carts .check-cart-flex-contents .checkout-cart-thumb {
  height: 100px;
  width: 100px;
}
.single-checkout-cart-items .single-check-carts .check-cart-flex-contents .checkout-cart-thumb img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.single-checkout-cart-items .single-check-carts .check-cart-flex-contents .checkout-cart-img-contents {
  text-align: left;
  margin-left: 10px;
}
.single-checkout-cart-items .single-check-carts .check-cart-flex-contents .checkout-cart-img-contents .product-items {
  margin-top: 10px;
}
.single-checkout-cart-items .single-check-carts .checkout-cart-price {
  font-size: 18px;
}

.coupon-form {
  position: relative;
}
.coupon-form .single-input .form--control {
  height: 60px;
  background: #fff;
  border: 1px solid rgba(221, 221, 221, 0.4);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.coupon-form .single-input .form--control:focus {
  border-color: rgba(var(--main-color-one-rgb), 0.3);
  -webkit-box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
          box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
}
.coupon-form .apply-coupon {
  position: absolute;
  right: 5px;
  height: 50px;
  border: 0;
  outline: none;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: var(--main-color-one);
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.coupon-form .apply-coupon:hover {
  background: none;
  color: var(--main-color-one);
  border-color: var(--main-color-one);
}

.btn-coupon {
  outline: none;
  border: 1px solid #DDD;
  background: #DDD;
  padding: 15px;
  cursor: pointer;
  font-size: 18px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-coupon.btn-bg-heading {
  background: var(--heading-color);
  color: #fff;
  border: 2px solid transparent;
}
.btn-coupon.btn-bg-heading:hover {
  background: none;
  color: var(--heading-color);
  border-color: var(--heading-color);
}

.lock-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 10px;
}
.lock-contents-icon {
  font-size: 24px;
}
.lock-contents-icon img {
  max-width: 25px;
}
.lock-contents-para {
  font-size: 16px;
  line-height: 32px;
}

.guaranty-cancellation {
  background-color: rgba(var(--success-color-rgb), 0.05);
  padding: 20px;
  border: 1px solid var(--success-color);
}
@media only screen and (max-width: 375px) {
  .guaranty-cancellation {
    padding: 10px;
  }
}
.guaranty-cancellation-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin: -4px 0 0;
}
@media only screen and (max-width: 375px) {
  .guaranty-cancellation-title {
    font-size: 18px;
  }
}
.guaranty-cancellation-para {
  font-size: 16px;
  color: var(--paragraph-color);
  margin-top: 10px;
}
@media only screen and (max-width: 375px) {
  .guaranty-cancellation-para {
    font-size: 16px;
  }
}

/* Checkout Sidebar Css */
.checkout-widget-padding {
  padding: 20px;
}
.checkout-widget:not(:last-child) {
  margin-bottom: 24px;
}

.checkout-sidebar-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin: -4px 0 0;
}

.checkout-border-top {
  border-top: 1px solid var(--input-color);
}

.checkout-flex-list .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: var(--paragraph-color);
  font-size: 16px;
}
.checkout-flex-list .list:not(:first-child) {
  padding-top: 15px;
}
.checkout-flex-list .list:not(:last-child) {
  padding-bottom: 15px;
}
.checkout-flex-list .list .strong {
  font-weight: 600;
  color: var(--heading-color);
}
.checkout-flex-list .list .color-one {
  color: var(--main-color-one);
}

.checkout-car {
  position: relative;
}
.checkout-car-contents-title {
  font-size: 22px;
  font-weight: 600;
}
.checkout-car-contents-para {
  font-size: 16px;
  line-height: 28px;
  color: var(--paragraph-color);
}
.checkout-car-shape {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 0;
  width: 100%;
}
.checkout-car-shape img {
  width: 100%;
}

/* Confirmation Css */
.confirmation-contents {
  max-width: 650px;
  margin: auto;
  padding: 40px 30px;
  background-color: #fff;
}
.confirmation-contents-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 70px;
  width: 70px;
  background-color: var(--main-color-one);
  color: #fff;
  border-radius: 50%;
  font-size: 40px;
  margin: 0 auto 25px;
}
.confirmation-contents-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin: -4px 0 0;
}
.confirmation-contents-para {
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
}
.confirmation-contents-para a {
  color: var(--main-color-one);
  font-weight: 500;
}

/* Dashboard area */
.dashboard-list .list.active > a .badge-notification {
  background-color: #fff;
  color: var(--main-color-one);
}

.badge-notification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 10px;
  height: 22px;
  width: 22px;
  background: var(--main-color-one);
  color: #fff;
  border-radius: 3px;
}

@media only screen and (max-width: 575.98px) {
  .showing-contents {
    text-align: center;
  }
}

.dashboard-padding {
  padding: 50px;
}
@media (min-width: 1600px) and (max-width: 1849.98px) {
  .dashboard-padding {
    padding: 40px;
  }
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .dashboard-padding {
    padding: 30px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .dashboard-padding {
    padding: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .dashboard-padding {
    padding: 20px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .dashboard-padding {
    padding: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .dashboard-padding {
    padding: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .dashboard-padding {
    padding: 20px 10px;
  }
}

.dashboard-contents-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 35px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .dashboard-contents-wrapper {
    gap: 24px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .dashboard-contents-wrapper {
    gap: 24px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .dashboard-contents-wrapper {
    display: block;
  }
}

.dashboard-left-content {
  background: #fff;
  text-align: center;
  height: 100%;
  padding: 0;
  border-radius: 20px;
  display: block;
  padding: 40px 20px;
  width: 335px;
}
@media (min-width: 1600px) and (max-width: 1849.98px) {
  .dashboard-left-content {
    height: calc(100vh - 80px);
  }
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .dashboard-left-content {
    height: calc(100vh - 60px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .dashboard-left-content {
    height: calc(100vh - 40px);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .dashboard-left-content {
    height: calc(100vh - 40px);
    padding: 30px 10px;
    width: 300px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .dashboard-left-content {
    height: 100vh;
  }
}
@media only screen and (max-width: 375px) {
  .dashboard-left-content {
    max-width: 280px;
  }
}

.dashboard-list .list.active > a {
  background: var(--main-color-one);
  font-weight: 500;
  color: #fff;
}
.dashboard-list .list.has-children.open.show .submenu {
  display: block;
}
.dashboard-list .list.has-children.open > a::after {
  content: "\f068";
}
.dashboard-list .list.has-children > a {
  position: relative;
}
.dashboard-list .list.has-children > a::after {
  content: "\f067";
  content: "\f067";
  font-family: "Line Awesome Free";
  font-weight: 900;
  position: absolute;
  right: 15px;
  font-size: 18px;
}
.dashboard-list .list.show > .submenu {
  display: block;
}
.dashboard-list .list a {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--heading-color);
  font-family: var(--rubik-font);
  gap: 15px;
  padding: 14px 15px;
  cursor: pointer;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.dashboard-list .list > .submenu {
  padding-left: 40px;
  display: none;
}
.dashboard-list .list > .submenu .list.has-children > a {
  font-size: 16px;
}
.dashboard-list .list > .submenu .list.selected a {
  color: var(--main-color-one);
}
.dashboard-list .list > .submenu .list.selected a::before {
  background: var(--main-color-one);
}
.dashboard-list .list > .submenu .list a {
  font-size: 16px;
  line-height: 22px;
  padding: 3px 0 3px 15px;
  position: relative;
  font-weight: 400;
}
.dashboard-list .list > .submenu .list a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 6px;
  width: 6px;
  background: #999;
  border-radius: 50%;
}

.dashboard-right-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

/* Dashboard Promo */

.dashboard-promo {
  margin-bottom: 25px;
}

.single-order {
  background: var(--main-color-one);
  padding: 35px 30px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.single-order.style-padding {
  padding: 29px 25px;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-order {
    padding: 20px 15px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-order {
    padding: 20px 20px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-order {
    padding: 25px 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .single-order {
    padding: 20px 15px;
  }
}
.single-order-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 20px;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-order-flex {
    gap: 10px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-order-flex {
    gap: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-order-flex {
    gap: 10px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-order-flex {
    gap: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .single-order-flex {
    gap: 10px;
  }
}
.single-order-icon {
  font-size: 120px;
  color: #fff;
  position: absolute;
  bottom: -30px;
  right: -30px;
  color: #fff;
  opacity: 0.2;
}
.single-order-contents-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 42px;
  color: #fff;
  margin-top: 20px;
  word-break: break-all;
}
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .single-order-contents-title {
    font-size: 34px;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-order-contents-title {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  .single-order-contents-title {
    font-size: 28px;
  }
}
.single-order-contents-subtitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #fff;
  font-family: var(--heading-font);
}
@media only screen and (max-width: 767.98px) {
  .single-order-contents-subtitle {
    font-size: 16px;
  }
}

/* Dashboard Reservation */
.base-padding {
  padding: 20px;
}

.dash-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 20px;
  background-color: #f3f3f3;
  color: var(--heading-color);
  border-radius: 7px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.dash-btn:hover {
  background-color: var(--main-color-one);
  color: #fff;
}
.dash-btn.btn-bg-1 {
  background-color: var(--main-color-one);
  color: #fff;
}
.dash-btn.btn-bg-1:hover {
  background-color: rgba(var(--main-color-one-rgb), 0.1);
  color: var(--main-color-one);
}
.dash-btn.btn-pending {
  background-color: #FF8D29;
  color: #fff;
}
.dash-btn.btn-pending:hover {
  background-color: var(--main-color-one);
}
.dash-btn.btn-cancelled {
  background-color: #FC4F4F;
  color: #fff;
}
.dash-btn.btn-cancelled:hover {
  background-color: var(--main-color-one);
}
.dash-btn.btn-accepted {
  background-color: var(--main-color-one);
  color: #fff;
}
.dash-btn.btn-accepted:hover {
  background-color: rgba(var(--main-color-one-rgb), 0.1);
  color: var(--main-color-one);
}
.dash-btn.btn-completed {
  background-color: #36AE7C;
  color: #fff;
}
.dash-btn.btn-completed:hover {
  background-color: var(--main-color-one);
}

.popup-contents-select .form--message {
  border-radius: 10px;
}

.single-reservation {
  border-radius: 20px;
  position: relative;
}
.single-reservation:not(:first-child) {
  margin-top: 25px;
}
.single-reservation-expandIcon {
  position: absolute;
  right: 20px;
  top: 35px;
  cursor: pointer;
  background: var(--input-color);
  color: var(--heading-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.single-reservation-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin: -4px 0 0;
}
.single-reservation.open .single-reservation-expandIcon {
  background-color: rgba(var(--main-color-one-rgb), 0.1);
  color: var(--main-color-one);
  /* IE 9 */
  -webkit-transform: rotate(180deg);
  /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}
.single-reservation.show .single-reservation-inner {
  display: block;
}
.single-reservation-inner {
  margin-top: 30px;
  border-top: 2px solid #f3f3f3;
  padding-top: 30px;
  display: none;
}
.single-reservation-inner.show {
  display: block;
}
.single-reservation-head {
  padding-right: 40px;
  -webkit-transition: 5s;
  transition: 5s;
}
.single-reservation-item:not(:first-child) {
  padding-top: 30px;
  border-top: 2px solid #f3f3f3;
}
.single-reservation-item:not(:last-child) {
  padding-bottom: 30px;
}
.single-reservation-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px 10px;
}
.single-reservation-content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.single-reservation-content-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
.single-reservation-content-id {
  color: var(--main-color-one);
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}
.single-reservation-content-price {
  font-size: 24px;
  font-weight: 600;
  color: var(--main-color-one);
  margin-top: 10px;
}
.single-reservation-name-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 20px 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.single-reservation-name-title {
  font-size: 24px;
  line-height: 28px;
  margin: -4px 0 0;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
@media only screen and (max-width: 480px) {
  .single-reservation-name-title {
    font-size: 20px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 375px) {
  .single-reservation-name-title {
    font-size: 18px;
  }
}
.single-reservation-name-para {
  font-size: 16px;
  line-height: 26px;
  margin-top: 10px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
@media only screen and (max-width: 480px) {
  .single-reservation-name-para {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 375px) {
  .single-reservation-name-para {
    font-size: 16px;
  }
}
.single-reservation-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 24px;
    flex-direction: column;
}
.single-reservation-details-item {
  display: inline-block;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

@media only screen and (max-width: 480px) {
  .single-reservation-details-item:not(:first-child) {
    padding: 0;
    border: none;
  }
}
.single-reservation-details-item:not(:last-child) {
  padding-right: 20px;
}
@media only screen and (max-width: 480px) {
  .single-reservation-details-item:not(:last-child) {
    padding-right: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .single-reservation-details-item {
    width: 50%;
  }
  .single-reservation-details-item:nth-child(2n+3) {
    padding-left: 0;
    border-left: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single-reservation-details-item {
    width: 50%;
  }
  .single-reservation-details-item:nth-child(2n+3) {
    padding-left: 0;
    border-left: 0;
  }
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .single-reservation-details-item {
    width: 50%;
  }
  .single-reservation-details-item:nth-child(2n+3) {
    padding-left: 0;
    border-left: 0;
  }
}
@media only screen and (max-width: 480px) {
  .single-reservation-details-item {
    width: 100%;
  }
}
.single-reservation-details-subtitle {
  font-size: 16px;
  line-height: 24px;
  color: var(--paragraph-color);
  margin-bottom: 10px;
}
.single-reservation-details-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--heading-color);
  line-height: 24px;
}
.single-reservation-logoPrice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.single-reservation-logoPrice-thumb {
  max-width: 50px;
}
.single-reservation-logoPrice-code {
  font-size: 16px;
  font-weight: 700;
  color: var(--heading-color);
}
.single-reservation-request-list-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  gap: 20px;
  background-color: #fff;
  padding-bottom: 50px;
}
@media only screen and (max-width: 375px) {
  .single-reservation-request-list-item {
    gap: 10px;
  }
}
.single-reservation-request-list-item.success::before {
  background-color: #65C18C;
}
.single-reservation-request-list-item::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  top: 35px;
  left: 17px;
  background: #f3f3f3;
  z-index: 0;
}
.single-reservation-request-list-item:last-child {
  padding-bottom: 0;
}
.single-reservation-request-list-item:last-child::before {
  display: none;
}
.single-reservation-request-list-item.success .single-reservation-request-list-item-number {
  border-color: #65C18C;
  color: #fff;
}
.single-reservation-request-list-item.success .single-reservation-request-list-item-number::before {
  background-color: #65C18C;
  content: "\f00c";
  font-family: "Line Awesome Free";
  font-weight: 900;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
}
.single-reservation-request-list-item.current .single-reservation-request-list-item-number {
  border-color: var(--main-color-one);
  color: #fff;
}
.single-reservation-request-list-item.current .single-reservation-request-list-item-number::before {
  background-color: var(--main-color-one);
}
.single-reservation-request-list-item-number {
  display: inline-block;
  position: relative;
  z-index: 2;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  color: #222;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #f3f3f3;
  background-color: unset;
}
.single-reservation-request-list-item-number::before {
  content: "";
  position: absolute;
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  top: 5px;
  left: 5px;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
}
.single-reservation-request-list-item-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.single-reservation-author-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 20px;
}
@media only screen and (max-width: 375px) {
  .single-reservation-author-flex {
    display: -ms-grid;
    display: grid;
  }
}
.single-reservation-author-active {
  font-size: 16px;
  font-weight: 500;
  color: var(--paragraph-color);
}
.single-reservation-author-contents {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.single-reservation-author-contents-subtitle {
  font-size: 16px;
  color: var(--main-color-one);
  margin-bottom: 10px;
}
.single-reservation-author-contents-title {
  font-size: 20px;
  font-weight: 600;
}
.single-reservation-author-thumb {
  max-width: 60px;
}
.single-reservation-author-thumb img {
  border-radius: 50%;
}
.single-reservation-author-footer {
  margin-top: 30px;
}
.single-reservation-author-footer-bottom {
  margin-top: 30px;
}
.single-reservation-author-para {
  font-size: 16px;
  line-height: 26px;
}
.single-reservation-author-para:not(:first-child) {
  padding-top: 25px;
}
.single-reservation-author-luggages {
  margin-bottom: 25px;
}
.single-reservation-author-luggages-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.single-reservation-author-luggages-thumb {
  max-width: 80px;
}
.single-reservation-tags-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.single-reservation-tags-item {
  padding: 10px 20px;
  display: inline-block;
  font-size: 16px;
  background-color: rgba(84, 22, 144, 0.1);
  color: #541690;
  line-height: 20px;
  white-space: nowrap;
  border-radius: 5px;
  font-weight: 500;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.single-reservation-tags-item:hover {
  background-color: #541690;
  color: #fff;
}
.single-reservation-tags-item.lost {
  background-color: rgba(235, 83, 83, 0.1);
  color: #EB5353;
}
.single-reservation-tags-item.lost:hover {
  background-color: #EB5353;
  color: #fff;
}
.single-reservation-tags-item.hotel {
  background-color: rgba(101, 193, 140, 0.1);
  color: #65C18C;
}
.single-reservation-tags-item.hotel:hover {
  background-color: #65C18C;
  color: #fff;
}
.single-reservation-attachment-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}
.single-reservation-attachment-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  gap: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.single-reservation-attachment-list-item:hover {
  color: var(--main-color-one);
}
.single-reservation-attachment-list-item-icon {
  font-size: 18px;
  color: var(--main-color-one);
}
.single-reservation-attachment-list-item-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--paragraph-color);
}
.single-reservation-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px 0px;
}
.single-reservation-contact-item {
  max-width: 48%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.single-reservation-contact-item:not(:first-child) {
  padding-left: 30px;
  border-left: 2px solid #f3f3f3;
}
@media only screen and (max-width: 480px) {
  .single-reservation-contact-item:not(:first-child) {
    padding: 0;
    border: none;
  }
}
.single-reservation-contact-item:not(:last-child) {
  padding-right: 30px;
}
@media only screen and (max-width: 480px) {
  .single-reservation-contact-item:not(:last-child) {
    padding-right: 0;
  }
}
.single-reservation-contact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.single-reservation-contact-list:hover {
  color: var(--main-color-one);
}

/* Dashboard Checkbox */
.dashboard-checkbox-wrapper {
  margin-top: 30px;
}

.dashboard-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  gap: 10px;
}
.dashboard-checkbox.check-success .check-input {
  border-radius: 50%;
}
.dashboard-checkbox.check-success .check-input:checked {
  background: #65C18C;
  border-color: #65C18C;
}
.dashboard-checkbox.check-success .check-input:checked + .checkbox-label {
  color: #65C18C;
}
.dashboard-checkbox .check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  line-height: 18px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 0px;
  margin-top: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.dashboard-checkbox .check-input::after {
  content: "\f00c";
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 10px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(1.6) rotate(90deg);
  transform: scale(1.6) rotate(90deg);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.dashboard-checkbox .check-input:checked {
  background: var(--main-color-one);
  border-color: var(--main-color-one);
}
.dashboard-checkbox .check-input:checked::after {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1.2) rotate(0deg);
  transform: scale(1.2) rotate(0deg);
}
.dashboard-checkbox .check-input:checked + .checkbox-label {
  color: var(--main-color-one);
}
.dashboard-checkbox .checkbox-label {
  cursor: pointer;
  text-align: left;
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: var(--heading-color);
  margin: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media only screen and (max-width: 575.98px) {
  .dashboard-checkbox .checkbox-label {
    font-size: 16px;
  }
}
.dashboard-checkbox .checkbox-label a {
  color: var(--main-color-one);
  font-weight: 500;
}

/* Dashboard Close */
@media (min-width: 300px) and (max-width: 991.98px) {
  .dashboard-left-content {
    z-index: 1;
    position: absolute;
    left: -100%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    z-index: 995;
    background: #fff;
    text-align: center;
    border-radius: 10px;
    display: block;
    height: 100vh;
    transition: all 0.5s;
  }
  .dashboard-left-content.active {
    visibility: visible;
    opacity: 1;
    left: 0;
  }
}
@media only screen and (min-width: 300px) and (max-width: 991.98px) and (max-width: 480px) {
  .dashboard-left-content {
    width: 280px;
  }
}
.dashboard-left-content .close-bars {
  position: absolute;
  right: 0px;
  top: 0;
  font-size: 24px;
  color: #fff;
  background: var(--main-color-one);
  -webkit-box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
  box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  width: 35px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .dashboard-left-content .close-bars {
    visibility: visible;
    opacity: 1;
  }
}

.dashboard-icon {
  display: none;
}
@media (min-width: 300px) and (max-width: 991.98px) {
  .dashboard-icon {
    display: block;
  }
}
.dashboard-icon .sidebar-icon {
  font-size: 28px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 45px;
  width: 45px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--customer-profile);
  -webkit-box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
  box-shadow: 0 0 10px rgba(221, 221, 221, 0.5);
  z-index: 95;
  border-radius: 5px;
  color: #fff;
  background-color: var(--main-color-one);
}

@media (min-width: 300px) and (max-width: 991.98px) {
  .body-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 990;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .body-overlay.active {
    visibility: visible;
    opacity: 1;
  }
}

.dashboard-flex-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 24px;
}
@media only screen and (max-width: 480px) {
  .dashboard-flex-input {
    display: block;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767.98px) {
  .dashboard-flex-input.flex-input-3 {
    display: block;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.dashboard-flex-input .dashboard-input {
  width: 100%;
}
.dashboard-flex-input .dashboard-input .form--control {
  width: 100%;
}

.dashboard-form .dashboard-input,
.dashboard-form .form-group {
  width: 100%;
  position: relative;
}
.dashboard-form .dashboard-input .label-title,
.dashboard-form .form-group .label-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
  color: var(--heading-color);
}
@media only screen and (max-width: 480px) {
  .dashboard-form .dashboard-input .label-title,
.dashboard-form .form-group .label-title {
    font-size: 16px;
  }
}
@media only screen and (max-width: 375px) {
  .dashboard-form .dashboard-input .label-title,
.dashboard-form .form-group .label-title {
    font-size: 16px;
  }
}
.dashboard-form .dashboard-input .input-icon,
.dashboard-form .form-group .input-icon {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.dashboard-form .dashboard-input .iti,
.dashboard-form .form-group .iti {
  width: 100%;
}
.dashboard-form .dashboard-input .form--control,
.dashboard-form .dashboard-input .form-control,
.dashboard-form .form-group .form--control,
.dashboard-form .form-group .form-control {
  -moz-appearance: textfield;
  border-radius: 7px;
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding: 0 15px;
  border: 1px solid var(--input-color);
  background-color: unset;
  outline: none;
  color: var(--light-color);
  -webkit-transition: 300ms;
  transition: 300ms;
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}
.dashboard-form .dashboard-input .form--control.input-padding-left,
.dashboard-form .dashboard-input .form-control.input-padding-left,
.dashboard-form .form-group .form--control.input-padding-left,
.dashboard-form .form-group .form-control.input-padding-left {
  padding-left: 45px;
}
.dashboard-form .dashboard-input .form--control#phone,
.dashboard-form .dashboard-input .form-control#phone,
.dashboard-form .form-group .form--control#phone,
.dashboard-form .form-group .form-control#phone {
  width: 100%;
  padding-left: 50px;
}
.dashboard-form .dashboard-input .form--control::-webkit-outer-spin-button, .dashboard-form .dashboard-input .form--control::-webkit-inner-spin-button,
.dashboard-form .dashboard-input .form-control::-webkit-outer-spin-button,
.dashboard-form .dashboard-input .form-control::-webkit-inner-spin-button,
.dashboard-form .form-group .form--control::-webkit-outer-spin-button,
.dashboard-form .form-group .form--control::-webkit-inner-spin-button,
.dashboard-form .form-group .form-control::-webkit-outer-spin-button,
.dashboard-form .form-group .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}
.dashboard-form .dashboard-input .form--control:focus,
.dashboard-form .dashboard-input .form-control:focus,
.dashboard-form .form-group .form--control:focus,
.dashboard-form .form-group .form-control:focus {
  border-color: rgba(var(--main-color-one-rgb), 0.3);
  -webkit-box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
  box-shadow: 0 0 10px rgba(var(--main-color-one-rgb), 0.1);
}
@media only screen and (max-width: 480px) {
  .dashboard-form .dashboard-input .form--control,
.dashboard-form .dashboard-input .form-control,
.dashboard-form .form-group .form--control,
.dashboard-form .form-group .form-control {
    font-size: 16px;
  }
}
@media only screen and (max-width: 375px) {
  .dashboard-form .dashboard-input .form--control,
.dashboard-form .dashboard-input .form-control,
.dashboard-form .form-group .form--control,
.dashboard-form .form-group .form-control {
    font-size: 16px;
  }
}
.dashboard-form .dashboard-input .form-message,
.dashboard-form .form-group .form-message {
  height: 100px;
  line-height: 28px;
  padding: 15px;
}

/* Toggle Password hide show */
.toggle-password {
  position: absolute;
  bottom: 15px;
  right: 20px;
  cursor: pointer;
}

.toggle-password .eye-icon::before {
  content: "\f070";
  font-family: "Line Awesome Free";
  font-weight: 900;
}

.toggle-password.show-pass .eye-icon::before {
  content: "\f06e";
  font-family: "Line Awesome Free";
  font-weight: 900;
}

/* Error Css */
.error-wrapper-thumb img {
  max-width: 700px;
  margin-inline: auto;
}

.error-contents-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  margin: -4px 0 0;
}